import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'

import CommunitySidebar from '~/pages/community/CommunitySidebar'
import { CommunityProvider } from '~/contexts/CommunityContext'
import { SizeBreakpoint } from '~/common/hooks/useWindowSize'
import { MediaUploadProvider } from '~/contexts/MediaUploadContext'
import ColumnLayout from '~/common/ColumnLayout'
import ToastComponent from '~/common/ToastComponent'
import { useSearchParams } from 'react-router-dom'
import {
  AddMemberDocument,
  CommunitySortEnum,
  GetCommunityDocument,
  GetMyCommunitiesDocument,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { Spinner } from 'react-bootstrap'
import { MembershipAction } from '~/common/JoinCommunityButton'
import { DraftingCommentPostProvider } from '~/contexts/DraftingCommentPostContext'
import { NewActivityProvider } from '~/contexts/NewActivityContext'
import { useMutation, useQuery } from '@apollo/client'
import { PromptProvider } from '~/contexts/PromptContext'

const CommunityLayout = () => {
  const navigate = useNavigate()
  const { communityId } = useParams<{ communityId: string | undefined }>()
  const [toastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState(false)
  const { authUserId, actingSysAdmin } = useAuth()
  const [didAccessSuccessfully, setDidAccessSuccessfully] = useState(false)

  const { data: userCommunitiesData, loading: userCommunitiesLoading } = useQuery(GetMyCommunitiesDocument, {
    variables: { communityFilter: {}, communitySort: [CommunitySortEnum.NameAsc] },
    notifyOnNetworkStatusChange: true,
  })

  const { data: communityData, loading: communityLoading } = useQuery(GetCommunityDocument, {
    variables: { id: communityId ?? '' },
    skip: !communityId,
  })

  const communityIds = useMemo(() => {
    return userCommunitiesData?.currentUser?.communities?.edges?.map(e => e?.node?.communityId) || []
  }, [userCommunitiesData])

  const isPrivate = communityData?.community?.isPrivate ?? true
  const hasAccessToCommunity = useMemo(() => {
    return actingSysAdmin || !isPrivate || communityIds?.includes(communityId)
  }, [actingSysAdmin, isPrivate, communityIds, communityId])

  const [searchParams, setSearchParams] = useSearchParams()
  const [addMemberMutation] = useMutation(AddMemberDocument)

  const addMember = useCallback(async () => {
    const response = await addMemberMutation({
      variables: {
        userId: authUserId ?? '',
        communityId: communityId ?? '',
      },
    })
    if (response.data?.addMember?.ok) {
      setToastMessage('You have successfully joined this community')
      setShowToast(true)
    } else {
      setToastMessage('Failed to join community. Please try again')
      setShowToast(true)
    }
    setSearchParams({})
  }, [addMemberMutation, setSearchParams, setToastMessage, authUserId, communityId])

  const handleNoPrivateAccess = useCallback(() => {
    navigate('/?show_private_toast=1')
  }, [navigate])

  const handleChangedMembershipStatus = (action: MembershipAction) => {
    if (isPrivate && action === MembershipAction.leave) {
      navigate('/')
    }
  }

  useEffect(() => {
    if (!userCommunitiesLoading && !communityLoading) {
      if (!hasAccessToCommunity && !didAccessSuccessfully) {
        handleNoPrivateAccess()
      } else {
        setDidAccessSuccessfully(true)
      }
    }
  }, [
    userCommunitiesLoading,
    communityLoading,
    hasAccessToCommunity,
    handleNoPrivateAccess,
    setDidAccessSuccessfully,
    didAccessSuccessfully,
  ])

  useEffect(() => {
    if (
      !userCommunitiesLoading &&
      searchParams.get('join') === '1' &&
      communityId &&
      !communityIds?.includes(communityId) &&
      authUserId
    ) {
      addMember().then()
    } else if (communityIds.includes(communityId) && searchParams.get('join') === '1') {
      setSearchParams({})
    }
  }, [searchParams, addMember, communityId, communityIds, userCommunitiesLoading, setSearchParams, authUserId])

  if (searchParams.get('join') === '1') {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  }

  return (
    <CommunityProvider communityId={communityId}>
      <NewActivityProvider>
        <ColumnLayout threshold={SizeBreakpoint.md} currentPage={'comm'} additionalColumnClass={'community-layout'}>
          <CommunitySidebar onChangedMembershipStatus={handleChangedMembershipStatus} />
          <PromptProvider>
            <DraftingCommentPostProvider>
              <MediaUploadProvider>
                <Outlet />
              </MediaUploadProvider>
            </DraftingCommentPostProvider>
          </PromptProvider>
        </ColumnLayout>
      </NewActivityProvider>
      <ToastComponent onClose={() => setShowToast(false)} show={showToast && !userCommunitiesLoading}>
        {toastMessage ?? ''}
      </ToastComponent>
    </CommunityProvider>
  )
}

export default CommunityLayout
