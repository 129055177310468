import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AddCompanyDocument,
  CanRemoveDomainDocument,
  CommunityType,
  EditCommunityDocument,
  GetAllCommunityRepostsDocument,
  GetDomainValidityDocument,
  Maybe,
  SetHidePostsDocument,
} from '~/api/generated/graphql'
import { useNavigate } from 'react-router'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import PlainTextInput from '~/common/PlainTextInput'
import FormError from '~/common/FormError'
import { upperCaseFirst } from 'change-case-all'
import TextListSelector from '~/common/TextListSelector'
import CommunityPhotoEdit from '~/pages/community/CommunityPhotoEdit'
import { CommunitySettingsDataModel } from '~/types'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { elementClicked } from '~/common/EventLogger'
import { useAuth } from '~/auth/Auth'
import { trimURL } from '~/utils'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '~/contexts/PromptContext'

type Props = {
  community?: CommunitySettingsDataModel
  isCompany: boolean
  isLeader?: boolean
  profileVisible: boolean
  creatingCompany: boolean
}

const MAX_ALIAS_LENGTH = 100

const CommunitySettingsEdit = ({ community, isCompany, isLeader, profileVisible, creatingCompany }: Props) => {
  const navigate = useNavigate()
  const [update] = useMutation(EditCommunityDocument)
  const [addCustomer] = useMutation(AddCompanyDocument)
  const [hidePosts] = useMutation(SetHidePostsDocument)
  const [getDomainValidity] = useLazyQuery(GetDomainValidityDocument)
  const [canRemoveDomain] = useLazyQuery(CanRemoveDomainDocument)

  const { isVeevan, actingSysAdmin } = useAuth()

  const { isCondensed } = useWindowSize()
  const condensedClass = isCondensed ? ' condensed' : ''
  const [pendingCustomerName, setPendingCustomerName] = useState<string>(community?.company?.name ?? '')
  const [pendingName, setPendingName] = useState<string>(community?.name ?? '')
  const [pendingDescription, setPendingDescription] = useState<Maybe<string>>(community?.description ?? '')
  const [pendingAliasList, setPendingAliasList] = useState<string[]>(community?.aliases ?? [])
  const [pendingDomainList, setPendingDomainList] = useState<string[] | undefined>(community?.company?.domains ?? [])
  const [duplicateNameError, setDuplicateNameError] = useState(false)
  const [duplicateCustomerNameError, setDuplicateCustomerNameError] = useState(false)
  const [uploadId, setUploadId] = useState<string>()
  const [uploading, setUploading] = useState(false)
  const [removePhoto, setRemovePhoto] = useState<boolean>()
  const [pendingPhoto, setPendingPhoto] = useState<string>(community?.photo ?? '')
  const [pendingType, setPendingType] = useState<Maybe<CommunityType>>(
    community?.type ? community?.type : creatingCompany ? CommunityType.Homepage : CommunityType.Public
  )
  const [showTypeModal, setShowTypeModal] = useState(false)
  const [showDomainRemovalModal, setShowDomainRemovalModal] = useState(false)
  const [domainError, setDomainError] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const [customerNameError, setCustomerNameError] = useState<boolean>(false)
  const [communityNameError, setCommunityNameError] = useState<boolean>(false)
  const [emptyDomainError, setEmptyDomainError] = useState<boolean>(false)

  const topCancelBtnRef = useRef<HTMLButtonElement>(null)
  const bottomCancelBtnRef = useRef<HTMLButtonElement>(null)

  const [pendingDomain, setPendingDomain] = useState<string>('')
  const [pendingAlias, setPendingAlias] = useState<string>('')

  const aliasListModified = useMemo(() => {
    return creatingCompany
      ? pendingAliasList?.length > 0
      : community?.aliases?.length !== pendingAliasList?.length ||
          !pendingAliasList?.every(item => community?.aliases?.includes(item))
  }, [pendingAliasList, community?.aliases, creatingCompany])

  const domainListModified = useMemo(() => {
    if (!pendingDomainList || !isCompany) {
      return false
    }

    return creatingCompany
      ? pendingDomainList.length > 0
      : community?.company?.domains?.length !== pendingDomainList.length ||
          !community?.company?.domains
            .filter((item, idx) => idx < pendingDomainList.length)
            .every((item, idx) => item === pendingDomainList[idx])
  }, [pendingDomainList, community?.company?.domains, creatingCompany, isCompany])

  const noChanges = creatingCompany
    ? !pendingCustomerName &&
      !pendingName &&
      !pendingDescription &&
      !uploadId &&
      !aliasListModified &&
      !domainListModified
    : community?.name === pendingName &&
      (community?.description === pendingDescription || (!community?.description && !pendingDescription)) &&
      (community?.company?.name === pendingCustomerName || (!community?.company?.name && !pendingCustomerName)) &&
      (!removePhoto || (!community?.photo && !uploadId)) &&
      !uploadId &&
      !aliasListModified &&
      !domainListModified &&
      pendingType === community?.type

  const { block, unblock } = usePrompt()
  const allowNavigation = useCallback(() => unblock('cse'), [unblock])

  useEffect(() => {
    if (noChanges) {
      allowNavigation()
    } else {
      block({ key: 'cse', priority: 1, message: 'You have unsaved changes. Are you sure you want to leave?' })
    }
  }, [noChanges, block, unblock, allowNavigation])

  const { data: repostData } = useQuery(GetAllCommunityRepostsDocument, {
    variables: { communityId: community?.communityId ?? '' },
    skip: !community?.communityId,
  })
  const repostIds =
    repostData?.community?.posts?.edges.flatMap(
      post => post?.node?.reposts?.flatMap(repost => repost?.postId ?? null) ?? []
    ) ?? []

  const returnHome = useCallback(() => {
    navigate(`../${isCompany ? 'home' : 'about'}`)
  }, [navigate, isCompany])

  const handleCancel = () => {
    topCancelBtnRef.current?.blur()
    bottomCancelBtnRef.current?.blur()
    allowNavigation()
    // Allow current thread updates to complete so that update of state is reflected in the prompt context before
    // trying to navigate. Otherwise, the blocker will not have updated yet and the navigation will be blocked
    // despite the message being empty.
    setTimeout(() => {
      creatingCompany ? navigate(-1) : returnHome()
    })
  }

  const handleSave = async (e: SyntheticEvent) => {
    if (community?.type !== pendingType && !creatingCompany) {
      setShowTypeModal(true)
    } else {
      if (pendingCustomerName == '') {
        setCustomerNameError(true)
      }
      if (pendingName == '') {
        setCommunityNameError(true)
      }
      if (pendingDomainList?.length == 0 && !pendingDomain) {
        setEmptyDomainError(true)
      }

      if (
        pendingName &&
        (!isCompany ||
          (!creatingCompany &&
            pendingCustomerName &&
            ((pendingDomainList && pendingDomainList?.length > 0) || pendingDomain)))
      ) {
        allowNavigation()
        elementClicked(e, 'click-community-settings-save', { community_id: community?.communityId })
        await doSave()
      } else if (
        creatingCompany &&
        pendingCustomerName &&
        pendingName &&
        ((pendingDomainList && pendingDomainList?.length > 0) || pendingDomain)
      ) {
        allowNavigation()
        elementClicked(e, 'click-customer-save', { name: pendingCustomerName.trim() })
        await doAddCustomerSave()
      }
    }
  }

  const doAddCustomerSave = async () => {
    const resp = await addCustomer({
      variables: {
        name: pendingCustomerName.trim(),
        homepageName: pendingName.trim(),
        homepageDescription: pendingDescription?.trim(),
        aliases: pendingAliasList.concat(pendingAlias ? [pendingAlias.trim()] : []),
        domains: pendingDomainList?.concat(pendingDomain ? [trimURL(pendingDomain.trim())] : []) ?? null,
        uploadId: uploadId,
      },
    })
    if (resp.data?.addCompany?.ok) {
      navigate(`/companies/${resp.data?.addCompany?.company?.companyId}/members`)
    } else {
      switch (resp.data?.addCompany?.error?.code) {
        case 'invalidDomain':
          setDomainError(`It looks like ${pendingDomain} is not a valid domain`)
          break
        case 'domainHasUsers':
          setDomainError(
            'There are customer users currently associated with a domain you attempted to remove. You cannot remove a domain that has users. If you need to move the domain to another customer, or if this domain should be removed anyway, please contact veevaconnect@veeva.com'
          )
          break
        case 'publicDomain':
        case 'partnerDomain':
        case 'blockedSubdomain':
        case 'competitorDomain':
          setDomainError('One of the domains you attempted to add is is not allowed')
          break
        case 'domainAlreadyExists':
          setDomainError('One of the domains you attempted to add is already in use')
          break
        case 'duplicateName':
          setDuplicateNameError(true)
          break
        case 'duplicate':
          setDuplicateCustomerNameError(true)
          break
        default:
          setError(`Error adding customer. Please try again later.`)
          break
      }
    }
  }

  const doSave = async () => {
    if (community?.communityId) {
      const resp = await update({
        variables: {
          communityId: community?.communityId,
          name: pendingName.trim(),
          description: pendingDescription?.trim(),
          uploadId,
          deleteMedia: removePhoto,
          communityType: isCompany || !actingSysAdmin ? undefined : pendingType,
          companyName: pendingCustomerName?.trim(),
          aliases: pendingAliasList.concat(pendingAlias ? [pendingAlias.trim()] : []),
          domains: pendingDomainList?.concat(pendingDomain ? [trimURL(pendingDomain.trim())] : []),
        },
      })

      if (resp.data?.editCommunity?.ok) {
        if (pendingType === CommunityType.Private && (repostIds?.length ?? 0 > 0)) {
          await hidePosts({ variables: { ids: repostIds, value: true } })
        }
        returnHome()
      } else {
        switch (resp.data?.editCommunity?.error?.code) {
          case 'invalidDomain':
            setDomainError(`It looks like ${pendingDomain} is not a valid domain`)
            break
          case 'domainHasUsers':
            setDomainError(
              'There are customer users currently associated with a domain you attempted to remove. You cannot remove a domain that has users. If you need to move the domain to another customer, or if this domain should be removed anyway, please contact veevaconnect@veeva.com'
            )
            break
          case 'publicDomain':
          case 'partnerDomain':
          case 'blockedSubdomain':
          case 'competitorDomain':
            setDomainError('One of the domains you attempted to add is is not allowed')
            break
          case 'domainAlreadyExists':
            setDomainError('One of the domains you attempted to add is already in use')
            break
          case 'duplicateName':
            setDuplicateNameError(true)
            break
          case 'duplicate':
            setDuplicateCustomerNameError(true)
            break
          default:
            setError(`Error updating ${isCompany ? 'company homepage' : 'community'}. Please try again later.`)
            break
        }
      }
    }
  }

  const handleRemovePhoto = (remove: boolean) => {
    setRemovePhoto(remove)
    setPendingPhoto('')
  }

  const validateDomain = async (
    domain: string,
    handleResult: (domain: string, error: boolean, errorMessage?: string) => void
  ) => {
    const resp = await getDomainValidity({ variables: { domain, companyId: community?.company?.companyId ?? '' } })
    const respData = resp.data?.validateDomain

    if (!respData?.domain) {
      handleResult('', true, 'An unknown error occurred')
    } else if (respData.ok) {
      handleResult(respData.domain, false)
      return true
    } else {
      let error = 'An unknown occurred'
      switch (respData.error?.code) {
        case 'publicDomain':
          error = 'Public domains are not allowed'
          break
        case 'partnerDomain':
          error = 'Partner domains are not allowed'
          break
        case 'blockedSubdomain':
          error = 'This is an unauthorized subdomain'
          break
        case 'competitorDomain':
          error = 'Competitor domains are not allowed'
          break
        case 'invalidDomain':
          error = `It looks like ${respData.domain} is not a valid domain`
          break
        case 'domainAlreadyExists':
          error = `${respData.domain} belongs to another customer${
            respData.owner ? ': ' + respData.owner : ''
          }. If this looks wrong, please contact veevaconnect@veeva.com.`
          break
      }

      handleResult(respData.domain, true, error)
    }
    return false
  }

  const domainHasNoUsers = async (domain: string) => {
    if (!community?.company?.companyId) {
      return false
    }

    const resp = await canRemoveDomain({ variables: { domain, companyId: community.company.companyId } })
    const respData = resp.data?.canRemoveDomain

    if (!respData?.domain) {
      return false
    } else if (respData.ok) {
      return true
    } else {
      setShowDomainRemovalModal(true)
      return false
    }
  }

  const handleTypeCancel = () => {
    setShowTypeModal(false)
  }

  const handleTypeConfirm = async (e: SyntheticEvent) => {
    setShowTypeModal(false)
    allowNavigation()
    elementClicked(e, 'click-community-type-change-confirm', { community_id: community?.communityId })
    await doSave()
  }

  const customerNameLength = pendingCustomerName?.length ?? 0
  const nameLength = pendingName?.length ?? 0
  const descriptionLength = pendingDescription?.length ?? 0
  const aliasLength = pendingAlias?.length ?? 0

  const maxDescriptionLength = 150
  const descriptionSeverity =
    descriptionLength >= maxDescriptionLength ? ' red' : descriptionLength > 100 ? ' yellow' : ' gone'

  const maxNameLength = 133
  const nameSeverity = nameLength > maxNameLength ? ' red' : nameLength > 100 ? ' yellow' : ' gone'

  const maxCustomerNameLength = 128
  const customerNameSeverity =
    customerNameLength > maxCustomerNameLength ? ' red' : customerNameLength > 100 ? ' yellow' : ' gone'

  const inputExceeded =
    nameLength > maxNameLength ||
    descriptionLength > maxDescriptionLength ||
    customerNameLength > maxCustomerNameLength ||
    aliasLength > MAX_ALIAS_LENGTH

  return (
    <div className={'settings-container'}>
      <div className="top-items">
        <h3 className="comm-title">
          {creatingCompany ? 'Add New Customer' : `${isCompany ? community?.company?.name : community?.name} Settings`}
        </h3>
        {(isLeader || (isCompany && isVeevan)) && (
          <span className={'button-options'}>
            <Button variant="light" onClick={handleCancel} ref={topCancelBtnRef}>
              Cancel
            </Button>
            <Button variant="primary" disabled={!profileVisible || inputExceeded || uploading} onClick={handleSave}>
              {creatingCompany ? 'Add Customer' : 'Save'}
            </Button>
          </span>
        )}
      </div>
      <div className="community-settings">
        <div className="left-col">
          {isCompany && (
            <div className="authoring-area">
              <div className="authoring-form">
                <h3 className="section-title">Customer Name*</h3>
                <span>This name will appear after the customer users' names -- for example, Susan Smith (Verteo).</span>
                {customerNameError ? <span className={'value-message'}>Required</span> : ''}
                <PlainTextInput
                  placeholder={'Add a Customer Name'}
                  onChange={setPendingCustomerName}
                  value={pendingCustomerName}
                  className={`name-editor ${customerNameError ? 'empty-error' : ''}`}
                  disabled={!profileVisible}
                  onKeyDown={() => {
                    setDuplicateCustomerNameError(false)
                    setCustomerNameError(false)
                  }}
                  onBlur={() => {
                    setPendingName(
                      pendingCustomerName ? (pendingName ? pendingName : pendingCustomerName + ' Home') : pendingName
                    )
                  }}
                />
                <span className={'character-counter name' + customerNameSeverity + condensedClass}>
                  {customerNameLength < 10 ? `0${customerNameLength}` : customerNameLength} / {maxCustomerNameLength}
                </span>
                {duplicateCustomerNameError && <FormError message={'A customer already exists with that name'} />}
              </div>
            </div>
          )}
          <div className="authoring-area">
            <div className="authoring-form">
              <h3 className="section-title">{isCompany ? 'Homepage Name*' : 'Name*'}</h3>
              {isCompany && <span>This is the name of the customer's homepage -- for example, Verteo Home.</span>}
              {communityNameError ? <span className={'value-message'}>Required</span> : ''}
              <PlainTextInput
                placeholder={`Add a ${isCompany ? 'Homepage' : 'Community'} Name`}
                onChange={setPendingName}
                value={pendingName}
                className={`name-editor ${communityNameError ? 'empty-error' : ''}`}
                disabled={!profileVisible}
                onKeyDown={() => {
                  setDuplicateNameError(false)
                  setCommunityNameError(false)
                }}
              />
              <span className={'character-counter name' + nameSeverity + condensedClass}>
                {nameLength < 10 ? `0${nameLength}` : nameLength} / {maxNameLength}
              </span>
              {duplicateNameError && <FormError message={'A community already exists with that name'} />}
            </div>
          </div>
          <div className="authoring-area">
            <div className="authoring-form">
              <h3 className="section-title">{isCompany ? 'Homepage Description' : 'Description'}</h3>
              {isCompany && (
                <span>
                  This appears on the left-hand side of the customer homepage -- for example, Partners with Veeva since
                  2018.
                </span>
              )}
              <PlainTextInput
                placeholder={'Add a Description'}
                onChange={setPendingDescription}
                value={pendingDescription}
                className={'description-editor'}
                disabled={!profileVisible}
              />
              <span className={'character-counter description' + descriptionSeverity + condensedClass}>
                {descriptionLength < 10 ? `0${descriptionLength}` : descriptionLength} / {maxDescriptionLength}
              </span>
            </div>
          </div>
          {!isCompany && (
            <div className="authoring-area visibility">
              <div className="authoring-form">
                <span className="section-title">Visibility</span>
                <Dropdown
                  id="dropdown-type"
                  data-testid="type-select"
                  title={!actingSysAdmin ? 'Only admins may change visibility' : ''}
                >
                  <Dropdown.Toggle id="dropdown-basic" role={'dropdown'} disabled={!actingSysAdmin}>
                    {upperCaseFirst(String(pendingType).toLowerCase())}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      key="Public"
                      onClick={() => setPendingType(CommunityType.Public)}
                      disabled={!profileVisible}
                    >
                      Public
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="Private"
                      onClick={() => setPendingType(CommunityType.Private)}
                      disabled={!profileVisible}
                    >
                      Private
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
          <div className="authoring-area aliases">
            <div className="authoring-form">
              <h3>Aliases</h3>
              <span>
                Other names that may be used to search for{' '}
                {creatingCompany ? 'this customer' : isCompany ? community?.company?.name : community?.name}.
              </span>
              <div>
                <TextListSelector
                  placeholder="Add alias"
                  list={pendingAliasList ?? []}
                  onUpdateList={setPendingAliasList}
                  maxItemLength={MAX_ALIAS_LENGTH}
                  mediumSeverityItemLength={70}
                  duplicateErrorMessage="That alias already exists"
                  addMessage="Add new alias"
                  disabled={!profileVisible}
                  setPendingInput={setPendingAlias}
                />
              </div>
            </div>
          </div>
          {isCompany && (
            <div className="authoring-area domains" data-testid="domain-section">
              <div className="authoring-form">
                <h3>Accepted Domains*</h3>
                <span>All users with email from these domains may join Veeva Connect.</span>
                {emptyDomainError ? <span className={'value-message'}>Required</span> : ''}
                <div>
                  <TextListSelector
                    placeholder="Add domain"
                    list={pendingDomainList ?? []}
                    onUpdateList={setPendingDomainList}
                    duplicateErrorMessage="That domain has already been added"
                    validateEntry={validateDomain}
                    canRemoveItem={domainHasNoUsers}
                    errorMessageOverride={domainError}
                    clearErrorMessageOverride={() => setDomainError(null)}
                    maxItemLength={128}
                    mediumSeverityItemLength={100}
                    addMessage="Add new domain"
                    disabled={!profileVisible}
                    className={`domain-input ${emptyDomainError ? 'empty-error' : ''}`}
                    onKeyDown={() => {
                      setEmptyDomainError(false)
                    }}
                    setPendingInput={setPendingDomain}
                    isDomain={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="right-col">
          <span className="section-title">Photo</span>
          <CommunityPhotoEdit
            existingPhoto={pendingPhoto}
            communityName={community?.name}
            setRemovePhoto={handleRemovePhoto}
            setUploadId={setUploadId}
            disabled={!profileVisible}
            uploading={uploading}
            setUploading={setUploading}
          />
        </div>
      </div>
      <div className="bottom-items">
        {(isLeader || (isCompany && isVeevan)) && (
          <span>
            <Button variant="light" onClick={handleCancel} ref={bottomCancelBtnRef}>
              Cancel
            </Button>
            <Button
              data-testid={'community-settings-save-btn'}
              variant="primary"
              disabled={!profileVisible || inputExceeded || uploading}
              onClick={handleSave}
            >
              {creatingCompany ? 'Add Customer' : 'Save'}
            </Button>
          </span>
        )}
      </div>
      <Modal show={showTypeModal} onHide={handleTypeCancel} data-testid="type-modal">
        <Modal.Header closeButton />
        <Modal.Body>
          {`Are you sure you want to change this community from ${community?.type} to ${pendingType}?${
            pendingType === CommunityType.Private ? ' This will delete any reposts from this community.' : ''
          }`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleTypeCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleTypeConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={!!error} onHide={() => setError(null)}>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={() => setError(null)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDomainRemovalModal} onHide={() => setShowDomainRemovalModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          There are customer users currently associated with that domain. You cannot remove a domain that has users.
          <br />
          <br />
          If you need to move the domain to another customer, or if this domain should be removed anyway, please contact{' '}
          <a href="mailto:veevaconnect@veeva.com">veevaconnect@veeva.com</a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowDomainRemovalModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CommunitySettingsEdit
