import React from 'react'
import { Link } from 'react-router-dom'
import JoinCommunityButton from '~/common/JoinCommunityButton'
import '@css/pages/feed/RecommendedCommunityItem.scss'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { elementClicked } from '~/common/EventLogger'
import { GetRecommendedCommunityItemDocument } from '~/api/generated/graphql'
import { useQuery } from '@apollo/client'

type RecommendedCommunityItemProps = {
  communityId: string
}

const RecommendedCommunityItem = ({ communityId }: RecommendedCommunityItemProps) => {
  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.md

  const { data } = useQuery(GetRecommendedCommunityItemDocument, { variables: { id: communityId }, skip: !communityId })
  const community = data?.community
  // only public communities can be recommended, so we do not need to worry about fetching the type and possibly
  // forming a homepage link here
  const link = `/communities/${communityId}`

  if (!community) return <></>

  return (
    <div className={`recommended-item-container ${isCondensed ? 'condensed' : ''}`}>
      <Link to={link} className={'photo-container'}>
        <div
          className={'profile-photo community-photo'}
          style={community.photo ? { backgroundImage: `url(${community.photo})` } : {}}
        />
      </Link>
      <div className="community-info">
        <Link
          onClick={e =>
            elementClicked(e, 'click-feed-recommended-community', {
              community_id: community.communityId,
            })
          }
          to={link}
          className="community-name"
        >
          {community.name}
        </Link>
        <p className="community-description">{community.description}</p>
      </div>
      <JoinCommunityButton
        communityId={communityId}
        communityName={community.name}
        isOnHomeFeed={true}
        onAction={(action, e) => elementClicked(e, 'click-feed-recommended-communities-join', { communityId })}
      />
    </div>
  )
}

export default RecommendedCommunityItem
