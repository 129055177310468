import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  GetFollowingPostsFullDocument,
  MediaAlignmentType,
  MeetupInput,
  AddCommentDocument,
  AddMemberDocument,
  CommentAddCommunityDocument,
  GetPostBoxBannerDocument,
  GetPostRowDocument,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import ReactQuill from 'react-quill'
import { checkConfidentialWarning, invalidateQuery, removeTags } from '~/utils'
import '@css/pages/posts/CommentRow.scss'
import FormatOptions from '@web/images/posts/FormatOptions.svg'
import ToastComponent from '~/common/ToastComponent'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { elementClicked } from '~/common/EventLogger'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import PostConfirmationModal from '~/pages/posts/PostConfirmationModal'
import MultipleEditor, { EditorRowData } from '~/common/quill/MultipleEditor'
import { useShortcuts } from '~/contexts/ShortcutContext'
import { useMToFocusComment } from '~/common/hooks/useMToFocusComment'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'
import { useMutation, useQuery } from '@apollo/client'

type CommentAddProps = {
  postId: string
  communityId: string
  isVeevan?: boolean
  discussionTab?: boolean
  discussionStarted?: boolean
  setTab?: (s: string) => void
  hidden?: boolean
  setFocus?: (b: boolean) => void
  focus?: boolean
  setShowModal?: (b: boolean) => void
  className?: string
  newCommentsMessage?: string
  onLoadNewComments?: () => Promise<void>
}

const originalStoryRows = [{ alignment: MediaAlignmentType.FullText, forceSetFocus: false }]

export const CommentAdd = ({
  postId,
  communityId,
  discussionTab,
  discussionStarted,
  setTab,
  hidden,
  setFocus,
  focus,
  setShowModal,
  className,
  newCommentsMessage,
  onLoadNewComments,
}: CommentAddProps) => {
  const [showJoinSuccess, setShowJoinSuccess] = useState<boolean>(false)
  const handleCloseToast = () => setShowJoinSuccess(false)
  const [adding, setAdding] = useState<boolean>(false)
  const [disabledAdd, setDisabledAdd] = useState<boolean>(false)
  const [showToolbar, setShowToolbar] = useState<boolean>(false)
  const quillRef = useRef<ReactQuill | null>(null)
  const [storyRows, setStoryRows] = useState<EditorRowData[]>([
    { alignment: MediaAlignmentType.FullText, forceSetFocus: false, quillRef },
  ])
  const [storyLength, setStoryLength] = useState(0)
  const [showConfirmationWarning, setShowConfirmationWarning] = useState<boolean>(false)
  const { authUserId, profileVisible, canPostIds, isVeevan } = useAuth()
  const [addMember] = useMutation(AddMemberDocument)
  const joinButtonRef = useClickOnEnter()
  const commentAddRef = useClickOnEnter<HTMLDivElement>()
  const { breakpoint, isCondensedPortrait: isMobile } = useWindowSize()
  const isMediumOrSmaller = breakpoint <= SizeBreakpoint.md
  const [confirmationMessage, setConfirmationMessage] = useState<string>()
  const [showSensitiveWarning, setShowSensitiveWarning] = useState<boolean>(false)
  const { setSelectedPost } = useShortcuts()
  const [meetup, setMeetup] = useState<MeetupInput>()

  const hasStory = storyRows?.some(row => removeTags(row.story) || row?.mediaType)

  const [addComment, { loading: addCommentLoading }] = useMutation(AddCommentDocument)

  // can't use community context to get canPost because home feed cannot access that context
  const { data } = useQuery(CommentAddCommunityDocument, { variables: { communityId } })

  const { data: bannerData, loading: bannerLoading } = useQuery(GetPostBoxBannerDocument)
  const { setDraftingComment } = useDraftingCommentPost()
  const { data: postData } = useQuery(GetPostRowDocument, { variables: { id: postId } })
  const isHidden = hidden || postData?.post?.hidden

  const handleCancel = useCallback(() => {
    setAdding(false)
    setStoryLength(0)
    setShowToolbar(false)
    quillRef?.current?.blur()
    setStoryRows([])
    setMeetup(undefined)
    setDraftingComment?.(discussionTab ? `vd:${postId}` : postId, false)
  }, [discussionTab, postId, setDraftingComment])

  const updateQuillRef = useCallback(
    (ref: ReactQuill) => {
      if (quillRef) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        quillRef.current = ref
      }
    },
    [quillRef]
  )

  const doComment = async (args: {
    skipWarning?: boolean
    warned?: boolean
    e: SyntheticEvent
    meetup?: MeetupInput
  }) => {
    if (!hasStory) return
    const { skipWarning, warned, e } = args

    elementClicked(e, 'click-comment-add', { post_id: postId, is_veeva_discussion: discussionTab })

    if (
      !skipWarning &&
      !isVeevan &&
      storyRows.some(row => checkConfidentialWarning(row.story?.htmlWithMentions) || row.mediaType)
    ) {
      setShowConfirmationWarning(true)
      setDisabledAdd(false)
      return
    }

    setDisabledAdd(true)

    const response = await addComment({
      variables: {
        story: storyRows
          .map(row => ({
            story: row.story?.htmlWithMentions,
            uploadId: row.uploadId,
            videoUrl: row.videoUrl,
            mediaType: row.mediaType,
            alignment: row.alignment,
            filename: row.filename,
          }))
          .filter(row => row.story || row.filename || row.uploadId || row.videoUrl),
        post_id: postId,
        is_veevan_discussion: discussionTab,
        warned: warned,
        meetup: meetup,
      },
      update: cache => invalidateQuery(cache, GetFollowingPostsFullDocument),
    })
    if (response.data?.addComment?.error?.code === 'sensitiveContent') {
      setShowSensitiveWarning(true)
      setConfirmationMessage(response.data?.addComment?.error?.message ?? undefined)
      setDisabledAdd(false)
    } else {
      handleCancel()
      setDisabledAdd(false)
    }
  }
  const handleJoinCommunity = async () => {
    const response = await addMember({
      variables: {
        userId: authUserId ?? '',
        communityId: communityId,
      },
    })
    response.data?.addMember?.ok && setShowJoinSuccess(true)
    commentAddRef?.current?.blur()
  }

  const discussionText = discussionStarted ? (
    <>
      There's a Veeva discussion for this post.{' '}
      <div
        onClick={() => {
          setTab?.('Discussion')
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setTab?.('Discussion')
          }
        }}
        className={'existing-discussion'}
        tabIndex={0}
        role={'button'}
      >
        See comments {'>'}
      </div>
    </>
  ) : (
    <>
      <div
        onClick={() => {
          setTab?.('Discussion')
          setFocus?.(true)
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setTab?.('Discussion')
            setFocus?.(true)
          }
        }}
        role={'button'}
        tabIndex={0}
      >
        {' '}
        Start Veeva Discussion
      </div>
    </>
  )

  // Sets focus to the Veeva discussion add comment box from a click on the public add comment box
  useEffect(() => {
    if (focus) {
      setAdding(true)
      quillRef?.current?.focus()
      setFocus?.(false)
    }
  }, [focus, setFocus])

  const clickCommentAdd = useCallback(() => {
    if (!adding) {
      setStoryRows([{ alignment: MediaAlignmentType.FullText, quillRef, forceSetFocus: true }])
      setAdding(true)
      setSelectedPost?.({ postId, expanded: true })
      quillRef.current?.getEditor().focus()
      setStoryLength(0)
      if (!discussionTab) {
        setShowModal?.(true)
      }
    }
  }, [adding, discussionTab, postId, setSelectedPost, setShowModal])

  const focusSelf = useCallback(() => {
    if (!isHidden) {
      commentAddRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
      if (!adding) {
        clickCommentAdd()
      } else {
        commentAddRef.current?.click()
        quillRef.current?.focus()
      }
    }
  }, [adding, clickCommentAdd, commentAddRef, isHidden])

  useMToFocusComment(focusSelf, postId)

  useEffect(() => {
    if (storyLength > 0) {
      setAdding(true)
      if (!discussionTab) {
        setShowModal?.(true)
      }
    }
  }, [storyLength, setShowModal, discussionTab])

  const placeholderText = !discussionTab ? 'Add a Comment' : 'Add a Veeva-only Comment'

  if (!profileVisible || bannerLoading) return null

  if (!authUserId || (!canPostIds.has(communityId) && !(isVeevan && discussionTab)))
    return (
      <div
        className={`authoring-area comment adding${isMediumOrSmaller ? ' condensed' : ''}${
          discussionTab ? ' discussion' : ''
        } ${className}`}
        hidden={isHidden}
      >
        <ProfilePhoto userId={authUserId} />
        <div className={'authoring-form'}>
          <div className={'join-community-container'}>
            <div className={'join-community-box'}>
              <span className={'join-community link'} onClick={handleJoinCommunity} tabIndex={0} ref={joinButtonRef}>
                {'Join this community'}
              </span>{' '}
              to add a comment.
            </div>
          </div>
        </div>
      </div>
    )

  const showBanner = !!bannerData?.postBoxBanner && !discussionTab
  const buttonSize =
    newCommentsMessage == 'Show new comments'
      ? 'small'
      : newCommentsMessage == 'Show new Veeva Discussions'
        ? 'medium'
        : 'large'

  return (
    <div className={'comment-container'} hidden={isHidden}>
      {showBanner ? (
        <i className={`post-banner${isMobile ? ' mobile' : ''} comment ${className}`}>{bannerData?.postBoxBanner}</i>
      ) : (
        <div hidden={true} data-testid={'no-banner'} />
      )}
      <div
        className={`authoring-area comment adding${isMediumOrSmaller ? ' condensed' : ''} ${isMobile ? 'mobile' : ''}${
          discussionTab ? ' discussion' : ''
        }${showBanner ? ' banner-spacing' : ''} ${className}`}
        data-testid={discussionTab ? 'discussion-authoring-area' : 'authoring-area'}
      >
        {!!newCommentsMessage && !addCommentLoading && (
          <button
            className={`new-comment-message ${showBanner ? 'has-post-banner' : ''} ${buttonSize}`}
            onClick={onLoadNewComments}
            key={newCommentsMessage}
          >
            {newCommentsMessage}
          </button>
        )}
        {!isMobile && <ProfilePhoto userId={authUserId} />}
        <div className={'authoring-form'}>
          <div
            className={'add-comment-row-wrapper'}
            onClick={clickCommentAdd}
            onFocus={clickCommentAdd}
            ref={commentAddRef}
          >
            <div
              className={`add-comment-row${showToolbar ? '' : ' hide-toolbar'}${
                discussionTab ? ' discussion-row' : ' public-row'
              }`}
              data-testid={'add-comment-row'}
            >
              <MultipleEditor
                className={`story${discussionTab ? ' veeva-discussion' : ' public-discussion'}`}
                originalRowsData={originalStoryRows}
                rowsData={storyRows}
                setRowsData={setStoryRows}
                placeholder={placeholderText}
                communityId={communityId}
                postId={postId}
                veevanOnlyMentions={discussionTab}
                noToolbar={!showToolbar}
                onSubmit={doComment}
                updateQuillRef={updateQuillRef}
                setMeetup={setMeetup}
                isComment={true}
              />
            </div>
            <button
              className="toggle-upload"
              onClick={() => {
                setShowToolbar(!showToolbar)
              }}
              data-testid={'comment-toggle-toolbar'}
            >
              <img src={FormatOptions} alt={'Toolbar'} />
            </button>
          </div>
          {adding && (
            <div
              className={`comment-options${discussionTab ? ' discussion-tab' : ''}${!isVeevan ? ' non-veevan' : ''}`}
            >
              {isVeevan && !discussionTab && <div className={'discussion'}>{discussionText}</div>}
              <div className={'button-zone'}>
                <Button variant="light" size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  disabled={!hasStory || disabledAdd}
                  size="sm"
                  onClick={e => doComment({ e: e, meetup: meetup })}
                  className={discussionTab ? 'btn-orange' : ''}
                >
                  {discussionTab ? 'Add a Veeva-Only Comment' : 'Comment'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastComponent show={showJoinSuccess} onClose={handleCloseToast}>
        {`You've joined ${data?.community?.name}.`}
      </ToastComponent>
      <PostConfirmationModal
        show={showConfirmationWarning}
        hide={() => setShowConfirmationWarning(false)}
        onSubmit={e => doComment({ skipWarning: true, e: e, meetup: meetup })}
        submitText={'Comment'}
      />
      <PostConfirmationModal
        show={showSensitiveWarning}
        hide={() => {
          setShowSensitiveWarning(false)
          setConfirmationMessage('')
        }}
        onSubmit={e => doComment({ skipWarning: true, warned: true, e: e, meetup: meetup })}
        submitText={'Comment'}
        message={confirmationMessage}
      />
    </div>
  )
}
