import { useEffect } from 'react'

export const useTitle = (title: string, skip = false) => {
  useEffect(() => {
    if (skip) {
      return
    }
    const oldTitle = document.title
    title && (document.title = title)
    return () => {
      document.title = oldTitle
    }
  }, [title, skip])
}
