import React, { Dispatch, ReactNode, SetStateAction, SyntheticEvent, useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  GetAuthDocument,
  GetHomepagesDocument,
  GetPermissionsDocument,
  GetPrivateCommunitiesDocument,
  GetPrivateContentDocument,
  GetRecentlySearchedUsersDocument,
  Maybe,
  PreloadCacheDocument,
} from '~/api/generated/graphql'
import { authRegister, authStart, authVerify, clearStorageItems, logout, preloadCache } from '~/api/ServerApi'
import { Spinner } from 'react-bootstrap'
import { useApolloClient, useQuery } from '@apollo/client'
import { AuthRegistrationRequest, AuthServerResponse, AuthStatus } from '~/api/types'
import { elementClicked, flushEvents } from '~/common/EventLogger'

export interface AuthContextType {
  profileVisible: boolean
  isVeevan: boolean
  sysAdminMember: boolean
  relAdminMember: boolean
  summitAdminMember: boolean
  inAdminRole: boolean
  actingSysAdmin: boolean
  actingRelAdmin: boolean
  actingSummitAdmin: boolean
  companyId: Maybe<string>
  authUserId: Maybe<string>
  loading: boolean
  email: Maybe<string>
  registeredWithPhoto: boolean
  setRegisteredWithPhoto?: Dispatch<SetStateAction<boolean>>
  canEditIds: Set<string>
  canPostIds: Set<string>
  signIn: (email: string, callback: (resp: AuthServerResponse) => void) => void
  verifyCode: (email: string, code: string, handler: (resp: AuthServerResponse) => void) => void
  registerPreUser: (registrationRequest: AuthRegistrationRequest) => Promise<string>
  signOut: (callback: VoidFunction, e?: SyntheticEvent) => void
  sysAdminId: Maybe<string>
  relAdminId: Maybe<string>
  summitAdminId: Maybe<string>
}

const AuthContext = React.createContext<AuthContextType | undefined>(undefined)

export interface AuthService {
  authStart: (email: string) => Promise<AuthServerResponse>
  authVerify: (email: string, code: string) => Promise<AuthServerResponse>
  authRegister: (registrationRequest: AuthRegistrationRequest) => Promise<string>
  logout: () => Promise<void>
  clearStorageItems: () => void
}

const DefaultAuthService: AuthService = {
  authStart: (email: string) => authStart(email),
  authVerify: (email: string, code: string) => authVerify(email, code),
  authRegister: (registrationRequest: AuthRegistrationRequest) => authRegister(registrationRequest),
  logout: () => logout(),
  clearStorageItems: () => clearStorageItems(),
}

export const AuthProvider = ({
  authService = DefaultAuthService,
  children,
}: {
  authService?: AuthService
  children: React.ReactNode
}) => {
  const { loading: authLoading, data: authData, refetch: refetchAuthUserId } = useQuery(GetAuthDocument)
  const authUserId = authData?.currentUser?.userId
  const { loading: permLoading, data: permData } = useQuery(GetPermissionsDocument, { skip: !authUserId })
  const [registeredWithPhoto, setRegisteredWithPhoto] = useState(false)

  const profileVisible = Boolean(authData?.currentUser?.profileVisible)
  const isVeevan = Boolean(authData?.currentUser?.isVeevan)
  const sysAdminMember = Boolean(authData?.currentUser?.sysAdminMember)
  const relAdminMember = Boolean(authData?.currentUser?.relAdminMember)
  const summitAdminMember = Boolean(authData?.currentUser?.summitAdminMember)
  const inAdminRole = Boolean(authData?.currentUser?.inAdminRole)
  const actingSysAdmin = sysAdminMember && inAdminRole
  const actingRelAdmin = relAdminMember && inAdminRole
  const actingSummitAdmin = summitAdminMember && inAdminRole
  const companyId = authData?.currentUser?.company?.companyId
  const email = authData?.currentUser?.email
  const { canEditIds, canPostIds } = permData?.permissions ?? { canEditIds: [], canPostIds: [] }
  const sysAdminId = permData?.permissions?.sysAdminId
  const relAdminId = permData?.permissions?.relAdminId
  const summitAdminId = permData?.permissions?.summitAdminId

  // // We use a different query for the profile data so the cache key is under user not currentUser
  const signIn = (email: string, callback: (resp: AuthServerResponse) => void) => {
    authService.authStart(email).then(resp => {
      refetchAuthUserId().then(() => callback(resp))
    })
  }
  const verifyCode = async (
    email: string,
    code: string,
    handler: (resp: AuthServerResponse) => void
  ): Promise<void> => {
    const resp = await authService.authVerify(email, code)
    if (resp.authStatus === AuthStatus.codeVerified) {
      refetchAuthUserId().then()
    }
    handler(resp)
  }
  const registerPreUser = async (registrationRequest: AuthRegistrationRequest): Promise<string> => {
    if (registrationRequest.photo) {
      setRegisteredWithPhoto(true)
    }
    const resp = await authService.authRegister(registrationRequest)
    if (resp === '202') {
      refetchAuthUserId().then()
    }
    return resp
  }
  const signOut = (callback: VoidFunction, e?: SyntheticEvent) => {
    if (e) {
      elementClicked(e, 'login-connect')
    }
    flushEvents()
    authService.clearStorageItems()
    history.replaceState('LOGOUT', '')
    authService
      .logout()
      .then(() => refetchAuthUserId())
      .then(callback)
  }

  const value: AuthContextType = {
    loading: authLoading || permLoading,
    authUserId: authUserId ?? null,
    signIn,
    verifyCode,
    registerPreUser,
    signOut,
    profileVisible,
    isVeevan,
    email: email ?? null,
    companyId: companyId ?? null,
    registeredWithPhoto,
    setRegisteredWithPhoto,
    sysAdminMember,
    relAdminMember,
    summitAdminMember,
    inAdminRole,
    actingSysAdmin,
    actingRelAdmin,
    actingSummitAdmin,
    canEditIds: new Set(canEditIds),
    canPostIds: new Set(canPostIds),
    sysAdminId: sysAdminId ?? null,
    relAdminId: relAdminId ?? null,
    summitAdminId: summitAdminId ?? null,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  const context = React.useContext(AuthContext)
  if (context === undefined)
    throw new Error('useAuth() may be used only in the context of an <AuthProvider> component.')
  return context
}

export const useSearchCacheContext = (): SearchCacheContextType => {
  return React.useContext(SearchCacheContext)
}

type SearchCacheContextType = {
  loading: boolean
  loadingContent: boolean
  loadingHomepages: boolean
  loadingPrivateCommunities: boolean
  loadingUsers: boolean
}

export const SearchCacheContext = React.createContext<SearchCacheContextType>({
  loading: false,
  loadingContent: false,
  loadingHomepages: false,
  loadingPrivateCommunities: false,
  loadingUsers: false,
})

export const searchCacheQuery = PreloadCacheDocument

const PopulateSearchCache = ({ children }: { children: ReactNode }) => {
  const { cache } = useApolloClient()
  const [loadedCache, setLoadedCache] = useState(false)

  // Preload the private content into the cache
  const { loading: loadingPrivateContent } = useQuery(GetPrivateContentDocument)
  const { loading: loadingHomepages } = useQuery(GetHomepagesDocument)
  const { loading: loadingPrivateCommunities } = useQuery(GetPrivateCommunitiesDocument)

  // Preload recently searched users into the cache
  const recentSearches = JSON.parse(localStorage.getItem('RECENT_SEARCH_SELECTION') ?? '[]') as string[]
  const recentUserIds = recentSearches
    .filter(k => k.startsWith('User'))
    .map(k => {
      const [_, id] = k.split(':')
      return id
    })
  const { loading: loadingUsers } = useQuery(GetRecentlySearchedUsersDocument, {
    variables: { ids: recentUserIds },
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (loadedCache) return
        const response = await preloadCache()
        const json = await response.json()

        cache.writeQuery({
          query: searchCacheQuery,
          variables: {},
          data: json,
        })

        setLoadedCache(true)
      } catch (error) {
        console.error('error', error)
      }
    }
    fetchData().then()
  }, [loadedCache, cache])

  return (
    <SearchCacheContext.Provider
      value={{
        loading: !loadedCache || loadingUsers,
        loadingHomepages,
        loadingContent: !loadedCache || loadingPrivateContent,
        loadingPrivateCommunities: loadingPrivateCommunities,
        loadingUsers: loadingUsers,
      }}
    >
      {children}
    </SearchCacheContext.Provider>
  )
}

export const RequireAuth = () => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.loading && !auth.authUserId) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  }

  if (!auth.authUserId) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page. However, if the user just logged out,
    // we want to prevent this navigation if they immediately sign back in.
    const url = history.state === 'LOGOUT' ? '/' : location.pathname + location.search
    return <Navigate to="/login" replace state={url} />
  }

  if (auth.registeredWithPhoto) {
    auth.setRegisteredWithPhoto?.(false)
    return <Navigate to="/?check_photo_success=1" />
  }

  return (
    <PopulateSearchCache>
      <Outlet />
    </PopulateSearchCache>
  )
}
