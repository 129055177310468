import React, { useEffect, useState } from 'react'
import { AppHeader } from '~/common/AppHeader'
import HomeFeedActivity from '~/pages/feed/HomeFeedActivity'
import '@css/pages/feed/HomeFeed.scss'
import MyCommunitiesTile from '~/pages/feed/MyCommunitiesTile'
import RecentCommunitiesTile from '~/pages/feed/RecentCommunitiesTile'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import RecommendedCommunities from '~/pages/feed/RecommendedCommunities'
import { Footer } from '~/common/Footer'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { NewActivityProvider } from '~/contexts/NewActivityContext'
import { useAuth } from '~/auth/Auth'
import { useQuery } from '@apollo/client'
import { ProfilePhotoDocument } from '~/api/generated/graphql'

export enum HomeFeedTab {
  Following = 'Following',
  My = 'My',
  All = 'All',
}

const HomeFeed = ({ homeFeedTab }: { homeFeedTab?: HomeFeedTab }) => {
  const { breakpoint, isCondensedPortrait } = useWindowSize()
  const { authUserId } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showPrivateAccessModal, setShowPrivateAccessModal] = useState(false)
  const isCondensed = breakpoint <= SizeBreakpoint.lg
  const isMobile = isCondensedPortrait
  const storedTab = (localStorage.getItem('FEED_TAB') as HomeFeedTab) ?? HomeFeedTab.My
  // Some users may have the "All feed" tab in their local storage prior to these changes.
  // In that case, we want to redirect them to the "My feed" tab.
  const previousTab = storedTab == HomeFeedTab.All ? HomeFeedTab.My : storedTab

  const tab = homeFeedTab ? homeFeedTab : previousTab

  const { data: userData } = useQuery(ProfilePhotoDocument, {
    variables: { id: authUserId ?? '' },
    skip: !authUserId,
  })
  const [showPhotoErrorModal, setShowPhotoErrorModal] = useState(false)

  useEffect(() => {
    if (homeFeedTab) {
      localStorage.setItem('FEED_TAB', homeFeedTab)
    }
  }, [homeFeedTab])

  useEffect(() => {
    if (searchParams.get('show_private_toast') === '1') {
      setShowPrivateAccessModal(true)
      setSearchParams({})
    }
    if (searchParams.get('check_photo_success') === '1' && userData) {
      if (!userData.user?.photo) {
        setShowPhotoErrorModal(true)
      }
      setSearchParams({})
    }
  }, [searchParams, setSearchParams, userData])

  const contentArea = (
    <>
      <div className={`content-area${isCondensed ? ' condensed' : ''} home ${isMobile ? 'mobile' : ''}`}>
        <RecommendedCommunities />
        <HomeFeedActivity homeFeedTab={tab} />
      </div>
    </>
  )

  const sidebarArea = (
    <div className={`sidebar-area${isCondensed ? ' condensed' : ''} home-feed`}>
      <MyCommunitiesTile />
      <RecentCommunitiesTile />
    </div>
  )

  return (
    <NewActivityProvider>
      <div className={`two-column home-feed ${isCondensed ? 'one-col condensed' : 'not-condensed'}`}>
        <AppHeader currentPage={'home'} />
        <div className={'hero-image'} />
        {isCondensed ? (
          <div className={'column-layout-container'}>
            <div className={'content-zone'}>{contentArea}</div>
          </div>
        ) : (
          <div className={'column-layout-container'}>
            <div className={`sidebar-zone home-feed ${isCondensed ? 'condensed' : ''}`}>{sidebarArea}</div>
            <div className={'content-zone'}>{contentArea}</div>
          </div>
        )}
      </div>
      <Modal show={showPrivateAccessModal} onHide={() => setShowPrivateAccessModal(false)}>
        <Modal.Body>Sorry, you don't have access to that link</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowPrivateAccessModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPhotoErrorModal} onHide={() => setShowPhotoErrorModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div>
            <p>There was an issue adding your profile photo.</p>
            <p>
              Please open your{' '}
              <Link to={`/profiles/${authUserId}`} aria-label="Profile">
                user profile
              </Link>{' '}
              to re-add your photo.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowPhotoErrorModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </NewActivityProvider>
  )
}

export default HomeFeed
