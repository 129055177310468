import { useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import { GetPostImagesDocument } from '~/api/generated/graphql'
import { ImagePreloader } from '~/common/ImagePreloader'

type Props = {
  postIds?: string[]
}

export const PostImagePreloader = ({ postIds }: Props) => {
  const { data: imageUrlData, loading: imageUrlDataLoading } = useQuery(GetPostImagesDocument, {
    variables: { ids: postIds ?? [] },
    skip: !postIds,
  })

  const imageUrlList = useMemo(() => {
    if (imageUrlData?.postMediaUrls) {
      return imageUrlData.postMediaUrls?.filter(u => u) as string[]
    } else {
      return []
    }
  }, [imageUrlData])

  return <>{!imageUrlDataLoading && !!imageUrlList.length && <ImagePreloader urls={imageUrlList} />}</>
}
