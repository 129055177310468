import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Form } from 'react-bootstrap'
import CommunityListRow from '~/pages/community/CommunityListRow'
import '@css/pages/community/CommunityTable.scss'
import searchIcon from '@web/images/community/icon-search.svg'
import { useCommunities } from '~/contexts/CommunitiesContext'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { useStickyState } from '~/common/hooks/useStickyState'
import searchClear from '@web/images/community/search-clear.svg'
import { CommunityListTab } from '~/pages/community/CommunityListLayout'
import { useAuth } from '@web/js/auth/Auth'
import { debounce, sortCommunityList } from '@web/js/utils'
import moreIcon from '@web/images/profile/more-icon.svg'
import ToastComponent from '~/common/ToastComponent'
import { CommunityListViewModel } from '~/types'
import { MembershipAction } from '~/common/JoinCommunityButton'
import { elementClicked, searchQueryLogger } from '~/common/EventLogger'

const COMMUNITY_LIST_MY_SEARCH_KEY = 'CommunityListMySearchKey'
const COMMUNITY_LIST_ALL_SEARCH_KEY = 'CommunityListAllSearchKey'

enum CommunitySortType {
  NAME,
  TYPE,
  MEMBERS,
  ACTIVITY,
  ACTION,
  CUSTOM,
}

const CommunityList = ({ communityListTab }: { communityListTab: CommunityListTab }) => {
  const [sortAscAll, setSortAscAll] = useState<boolean>(true)
  const [sortTypeAll, setSortTypeAll] = useState<CommunitySortType>(CommunitySortType.NAME)
  const [sortAscMine, setSortAscMine] = useState<boolean>(true)
  const [sortTypeMine, setSortTypeMine] = useState<CommunitySortType>(CommunitySortType.CUSTOM)
  const [displayedCountMine, setDisplayedCountMine] = useState<number>(25)
  const [displayedCountAll, setDisplayedCountAll] = useState<number>(25)
  const [toastElements, setToastElements] = useState<JSX.Element[]>([])

  const { isCondensedPortrait } = useWindowSize()
  const [mySearchText, setMySearchText] = useStickyState('', COMMUNITY_LIST_MY_SEARCH_KEY)
  const [allSearchText, setAllSearchText] = useStickyState('', COMMUNITY_LIST_ALL_SEARCH_KEY)
  const {
    loadingAll,
    errorAll,
    loadingMine,
    errorMine,
    loadingRecommended,
    errorRecommended,
    allCommunitiesTotal,
    myCommunitiesTotal,
    allCommunities,
    myCommunities,
    recommendedCommunities,
    searchAll,
    searchMine,
    loadRecommended,
    unjoinedCommunities,
  } = useCommunities()
  const { companyId } = useAuth()

  const showMyCommunities = communityListTab === CommunityListTab.My
  const showAllCommunities =
    communityListTab === CommunityListTab.All ||
    (communityListTab === CommunityListTab.Recommended && !recommendedCommunities?.length)
  const showRecommendedCommunities = communityListTab === CommunityListTab.Recommended && recommendedCommunities?.length

  const ascendingSort = (showAllCommunities && sortAscAll) || (showMyCommunities && sortAscMine)
  const sortingName =
    (showAllCommunities && sortTypeAll == CommunitySortType.NAME) || (showMyCommunities && CommunitySortType.NAME)
  const sortingType =
    (showAllCommunities && sortTypeAll == CommunitySortType.TYPE) ||
    (showMyCommunities && sortTypeMine == CommunitySortType.TYPE)
  const sortingActivity =
    (showAllCommunities && sortTypeAll == CommunitySortType.ACTIVITY) ||
    (showMyCommunities && sortTypeMine == CommunitySortType.ACTIVITY)
  const sortingMembers =
    (showAllCommunities && sortTypeAll == CommunitySortType.MEMBERS) ||
    (showMyCommunities && sortTypeMine == CommunitySortType.MEMBERS)
  const sortingAction = showAllCommunities && sortTypeAll == CommunitySortType.ACTION

  const nameCompare = (a: CommunityListViewModel, b: CommunityListViewModel) => {
    return a.name.localeCompare(b.name)
  }

  const sortOnJoin = useCallback(() => {
    const joined = myCommunities?.sort(nameCompare)
    const unjoined = unjoinedCommunities?.sort(nameCompare)
    return sortAscAll
      ? joined?.filter(comm => !comm.companyId).concat(unjoined ?? [])
      : unjoined?.concat(joined?.filter(comm => !comm.companyId) ?? [])
  }, [myCommunities, sortAscAll, unjoinedCommunities])

  const sortActivity = useCallback((asc: boolean, comms: CommunityListViewModel[]) => {
    function sortNulls(asc: boolean) {
      return function (a: CommunityListViewModel, b: CommunityListViewModel) {
        const a1 = !a.lastActivityTime ? '' : a.lastActivityTime.valueOf()
        const b1 = !b.lastActivityTime ? '' : b.lastActivityTime.valueOf()
        if (asc) {
          return a1 == b1 ? a.name.localeCompare(b.name) : a1 > b1 ? 1 : -1
        }
        return a1 == b1 ? a.name.localeCompare(b.name) : a1 > b1 ? -1 : 1
      }
    }
    return comms?.sort(sortNulls(asc))
  }, [])

  const sortComms = useCallback(
    (sortType: CommunitySortType, sortAsc: boolean, comms: CommunityListViewModel[]) => {
      let sortedComms = comms

      switch (sortType) {
        case CommunitySortType.NAME: {
          sortAsc
            ? sortedComms?.sort(nameCompare)
            : sortedComms?.sort(function (a, b) {
                return b.name.localeCompare(a.name)
              })
          break
        }
        case CommunitySortType.TYPE: {
          sortAsc
            ? sortedComms?.sort(function (a, b) {
                return a.type.localeCompare(b.type) || a.name.localeCompare(b.name)
              })
            : sortedComms?.sort(function (a, b) {
                return b.type.localeCompare(a.type) || a.name.localeCompare(b.name)
              })
          break
        }
        case CommunitySortType.MEMBERS: {
          sortAsc
            ? sortedComms?.sort(function (a, b) {
                return (a.memberCount ?? 0) - (b.memberCount ?? 0) || a.name.localeCompare(b.name)
              })
            : sortedComms?.sort(function (a, b) {
                return (b.memberCount ?? 0) - (a.memberCount ?? 0) || a.name.localeCompare(b.name)
              })
          break
        }
        case CommunitySortType.ACTIVITY: {
          sortedComms = sortActivity(sortAsc, sortedComms)
          break
        }
        default: {
          break
        }
      }

      return sortedComms
    },
    [sortActivity]
  )

  const communitiesToShow = useMemo(() => {
    const comms = showMyCommunities
      ? myCommunities
      : showAllCommunities
        ? sortTypeAll == CommunitySortType.ACTION && unjoinedCommunities && myCommunities
          ? sortOnJoin()
          : allCommunities
        : recommendedCommunities

    return sortComms(
      showAllCommunities ? sortTypeAll : sortTypeMine,
      showAllCommunities ? sortAscAll : sortAscMine,
      comms ?? []
    )
  }, [
    allCommunities,
    myCommunities,
    recommendedCommunities,
    showAllCommunities,
    showMyCommunities,
    sortAscAll,
    sortAscMine,
    sortComms,
    sortOnJoin,
    sortTypeAll,
    sortTypeMine,
    unjoinedCommunities,
  ])

  const sortClicked = (sortOption: CommunitySortType) => {
    const sortAscMineTemp =
      sortOption == sortTypeMine
        ? !sortAscMine
        : sortOption != CommunitySortType.TYPE && sortOption != CommunitySortType.ACTIVITY
    const sortAscAllTemp =
      sortOption == sortTypeAll
        ? !sortAscAll
        : sortOption != CommunitySortType.TYPE && sortOption != CommunitySortType.ACTIVITY

    if (showAllCommunities) {
      setDisplayedCountAll(25)
      setSortTypeAll(sortOption)
      setSortAscAll(sortAscAllTemp)
    } else {
      setDisplayedCountMine(25)
      setSortTypeMine(sortOption)
      setSortAscMine(sortAscMineTemp)
    }
  }

  const loadMoreMine = async () => {
    setDisplayedCountMine(displayedCountMine + 25)
  }
  const loadMoreAll = async () => {
    setDisplayedCountAll(displayedCountAll + 25)
  }

  const hasMoreMine = (myCommunities?.length ?? 0) > displayedCountMine
  const hasMoreAll = (allCommunities?.length ?? 0) > displayedCountAll

  const loading = showMyCommunities ? loadingMine : showAllCommunities ? loadingAll : loadingRecommended
  const error = showMyCommunities ? errorMine : showAllCommunities ? errorAll : errorRecommended
  const defaultSearch = showMyCommunities ? mySearchText : allSearchText

  const showSearchClear = (mySearchText !== '' && showMyCommunities) || (allSearchText !== '' && showAllCommunities)
  const debouncedSearchLogger = useMemo(() => debounce(searchQueryLogger, 300), [])

  const clearSearch = () => {
    if (showMyCommunities) {
      setMySearchText('')
      searchMine('')
    } else if (showAllCommunities) {
      setAllSearchText('')
      searchAll('')
    }
  }

  const getToast = useCallback((commName: string) => {
    const key = `${commName}-${Date.now()}`
    return (
      <ToastComponent show={true} onClose={() => handleCloseToast(key)} key={key} className={'recommended-toast'}>
        {`You've joined ${commName}.`}
      </ToastComponent>
    )
  }, [])

  const handleCloseToast = (key: string) => {
    setToastElements(prevElements => {
      for (let i = 0; i < prevElements.length; i++) {
        if (prevElements[i].key == key) {
          prevElements.splice(i, 1)
          return [...prevElements]
        }
      }
      return prevElements
    })
  }

  const handleJoin = (comm: CommunityListViewModel, action: MembershipAction, e: SyntheticEvent) => {
    if (action == MembershipAction.join) {
      setToastElements(prevElements => prevElements.concat(getToast(comm.name)))
      elementClicked(e, 'click-community-join', { communityId: comm.communityId })
    } else {
      elementClicked(e, 'click-community-leave', { communityId: comm.communityId })
    }
  }

  const handleSearchTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchText = e.target.value
      if (showMyCommunities) {
        setMySearchText(searchText)
        searchMine(searchText)
      } else if (showAllCommunities) {
        setAllSearchText(searchText)
        searchAll(searchText)
      }
      debouncedSearchLogger(searchText, 'community-search').then()
    },
    [
      showMyCommunities,
      showAllCommunities,
      setMySearchText,
      setAllSearchText,
      searchMine,
      searchAll,
      debouncedSearchLogger,
    ]
  )

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // On refetch, load both lists using the current search text
  const refetch = useCallback(() => {
    searchMine(mySearchText)
    searchAll(allSearchText)
    loadRecommended()
  }, [mySearchText, allSearchText, searchMine, searchAll, loadRecommended])

  if (!communitiesToShow?.length && loading) return <div>loading...</div>
  if (error) return <Alert variant="danger">{errorMine?.message}</Alert>

  return (
    <div className={'table-list'} role="list">
      {!showRecommendedCommunities && (
        <div className={`searchbar${isCondensedPortrait ? ' condensed' : ''}`}>
          <img className="search-icon" src={searchIcon} alt={'Search'} />
          <Form.Control
            type="text"
            placeholder={showMyCommunities ? 'Search My Communities' : 'Search Communities'}
            value={defaultSearch}
            onChange={handleSearchTextChanged}
            role="searchBar"
            bsPrefix={' '}
          />
          {showSearchClear && (
            <img className={'search-clear'} src={searchClear} alt={'Clear'} title={'Clear'} onClick={clearSearch} />
          )}
        </div>
      )}
      {!isCondensedPortrait && (
        <div className="table-title communities">
          <span className={`wide-column ${isCondensedPortrait ? 'condensed' : ''}`}>
            <span
              className={`${showRecommendedCommunities ? '' : 'actions'}`}
              onClick={() => {
                !showRecommendedCommunities
                  ? sortClicked(CommunitySortType.NAME)
                  : () => {
                      return
                    }
              }}
            >
              <span>Name & Description</span>
              <img
                src={moreIcon}
                alt={`Sort carrot${ascendingSort ? ' ascending' : ' descending'}`}
                className={`${sortingName ? 'sorting' : ''}${ascendingSort ? ' asc' : ' '}`}
              />
            </span>
          </span>
          <span className="normal-column">
            <span
              className={`${showRecommendedCommunities ? '' : 'actions'}`}
              onClick={() => {
                !showRecommendedCommunities
                  ? sortClicked(CommunitySortType.TYPE)
                  : () => {
                      return
                    }
              }}
            >
              <span>Type</span>
              <img
                src={moreIcon}
                alt={'Carrot icon'}
                className={`${sortingType ? 'sorting' : ''}${ascendingSort ? '' : ' asc'}`} // Arrow is switched for type
              />
            </span>
          </span>
          <span className="normal-column">
            <span
              className={`${showRecommendedCommunities ? '' : 'actions'}`}
              onClick={() => {
                !showRecommendedCommunities
                  ? sortClicked(CommunitySortType.MEMBERS)
                  : () => {
                      return
                    }
              }}
            >
              <span>Members</span>
              <img
                src={moreIcon}
                alt={`Sort carrot${ascendingSort ? ' ascending' : ' descending'}`}
                className={`${sortingMembers ? 'sorting' : ''}${ascendingSort ? ' asc' : ''}`}
              />
            </span>
          </span>
          <span className="normal-column">
            <span
              className={`${showRecommendedCommunities ? '' : 'actions'}`}
              onClick={() => {
                !showRecommendedCommunities
                  ? sortClicked(CommunitySortType.ACTIVITY)
                  : () => {
                      return
                    }
              }}
            >
              <span>Last Activity</span>
              <img
                src={moreIcon}
                alt={`Sort carrot${ascendingSort ? ' ascending' : ' descending'}`}
                className={`${sortingActivity ? 'sorting' : ''}${ascendingSort ? ' asc' : ''}`}
              />
            </span>
          </span>
          <span className="normal-column end-aligned">
            <span className="max-width">
              <span
                className={`${!showAllCommunities ? '' : 'actions'}`}
                onClick={() => {
                  showAllCommunities
                    ? sortClicked(CommunitySortType.ACTION)
                    : () => {
                        return
                      }
                }}
              >
                <span>Action</span>
                <img
                  src={moreIcon}
                  alt={`Sort carrot${ascendingSort ? ' ascending' : ' descending'}`}
                  className={`${sortingAction ? 'sorting' : ''}${ascendingSort ? ' asc' : ''}`}
                />
              </span>
            </span>
          </span>
        </div>
      )}
      {communitiesToShow?.length ? (
        <div className={'content-region communities-list'}>
          {showMyCommunities && sortTypeMine == CommunitySortType.CUSTOM
            ? sortCommunityList(companyId ?? null, communitiesToShow)
                ?.slice(0, displayedCountMine)
                .map(community => (
                  <CommunityListRow
                    key={community.companyId ? community.companyId : community.communityId}
                    community={community}
                    refetch={refetch}
                    onAction={(action, e) => handleJoin(community, action, e as SyntheticEvent)}
                  />
                ))
            : showRecommendedCommunities
              ? communitiesToShow?.map(community => (
                  <CommunityListRow
                    community={community}
                    key={community.companyId ? community.companyId : community.communityId}
                    refetch={refetch}
                    onAction={(action, e) => handleJoin(community, action, e as SyntheticEvent)}
                  />
                ))
              : communitiesToShow
                  ?.slice(0, showAllCommunities ? displayedCountAll : displayedCountMine)
                  .map(community => (
                    <CommunityListRow
                      community={community}
                      key={community.companyId ? community.companyId : community.communityId}
                      refetch={refetch}
                      onAction={(action, e) => handleJoin(community, action, e as SyntheticEvent)}
                    />
                  ))}
        </div>
      ) : (
        <div className={'empty-section'}>No results found</div>
      )}
      <div className={'button-zone load-more'}>
        {showMyCommunities && hasMoreMine && (
          <button className={'btn btn-primary btn-sm'} onClick={loadMoreMine}>
            <strong>Show more</strong> {`(showing ${displayedCountMine} of ${myCommunitiesTotal})`}
          </button>
        )}
        {showAllCommunities && hasMoreAll && (
          <button className={'btn btn-primary btn-sm'} onClick={loadMoreAll}>
            <strong>Show more</strong> {`(showing ${displayedCountAll} of ${allCommunitiesTotal})`}
          </button>
        )}
      </div>
      {toastElements.length > 0 && toastElements.map(e => e)}
    </div>
  )
}

export default CommunityList
