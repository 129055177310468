import React, { useEffect } from 'react'
import CommunityList from '~/pages/community/CommunityList'
import CommunityListSidebar from '~/pages/community/CommunityListSidebar'
import { SizeBreakpoint } from '~/common/hooks/useWindowSize'
import ColumnLayout from '~/common/ColumnLayout'
import { useTitle } from '~/common/hooks/useTitle'

export enum CommunityListTab {
  All = 'All',
  My = 'My',
  Recommended = 'Recommended',
}

const CommunityListLayout = ({ communityListTab }: { communityListTab?: CommunityListTab }) => {
  const communityTab = (localStorage.getItem('COMMUNITY_TAB') as CommunityListTab) ?? CommunityListTab.All

  const commTab = communityListTab ? communityListTab : communityTab
  useTitle(`Communities - ${commTab} - Veeva Connect`)
  useEffect(() => {
    if (communityListTab) {
      localStorage.setItem('COMMUNITY_TAB', communityListTab)
    }
  }, [communityListTab])

  return (
    <>
      <ColumnLayout threshold={SizeBreakpoint.md} currentPage={'comm'} additionalColumnClass={'comm-list'}>
        <CommunityListSidebar communityListTab={commTab} />
        <CommunityList communityListTab={commTab} />
      </ColumnLayout>
    </>
  )
}

export default CommunityListLayout
