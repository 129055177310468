import React, { FocusEvent, SyntheticEvent, useRef, useState } from 'react'
import '@css/common/AddPersonBox.scss'
import { CompanyFacet } from '~/pages/search/AdvancedSearchSidebar'
import { FacetResponse } from '~/pages/search/AdvancedSearch'
import { useApolloClient } from '@apollo/client'
import CompanySuggestionService from '~/common/CompanySuggestionService'
import { Suggestion } from '~/common/quill/QuillEditor'
import { FacetSuggestion } from '~/pages/search/FacetSuggestion'
import { Form } from 'react-bootstrap'

type AddCompanyBoxProps = {
  searchText: string
  placeholder: string
  isCondensed: boolean
  selectedCompanies: CompanyFacet[]
  responseFacets: FacetResponse[] | undefined
  onTextChange: (inputText: string) => void
  onAddCompany: (companyFacet?: CompanyFacet, e?: SyntheticEvent) => void
}

const AddCompanyBox = ({
  searchText,
  placeholder,
  isCondensed,
  selectedCompanies,
  responseFacets,
  onTextChange,
  onAddCompany,
}: AddCompanyBoxProps) => {
  const client = useApolloClient()
  const [matchedCompanies, setMatchedCompanies] = useState<Suggestion[]>([])
  const [selectedSuggestion, setSelectedSuggestion] = useState(0)
  const inputRef = useRef<HTMLInputElement>(null)

  const getSuggestions = (search: string) => {
    const onlyIncludeIds = new Set(responseFacets?.map(f => f.key) ?? [])
    const excludeIds = new Set(selectedCompanies.map(c => c.companyId))
    return CompanySuggestionService.getSuggestions(search, client.cache, onlyIncludeIds, excludeIds, false)
  }

  const handleAddCompanyTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value
    setMatchedCompanies(getSuggestions(search))
    onTextChange(search)
  }

  const getSuggestionList = () => {
    // Combine matched companies with facet counts from the server response, then order by highest count
    const suggestionsWithCounts = matchedCompanies
      .map(s => ({
        ...s,
        count: responseFacets?.find(f => f.key === s.community?.companyId)?.count ?? 0,
      }))
      .sort((a, b) => {
        return b.count - a.count
      })

    return suggestionsWithCounts.map((s, i) => {
      return (
        <div key={s.id}>
          <FacetSuggestion
            index={i}
            suggestion={s}
            selectedSuggestion={selectedSuggestion}
            selectSuggestion={e => selectSuggestion(s, e)}
          />
        </div>
      )
    })
  }

  const handleInputKeyPresses = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && matchedCompanies.length) {
      selectSuggestion(matchedCompanies[selectedSuggestion], e)
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      setSelectedSuggestion(Math.min(selectedSuggestion + 1, matchedCompanies.length - 1))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setSelectedSuggestion(Math.max(selectedSuggestion - 1, 0))
    }
  }

  const selectSuggestion = (suggestion: Suggestion, e: SyntheticEvent) => {
    onTextChange('')
    setMatchedCompanies([])
    const company = {
      companyId: suggestion.id,
      name: suggestion.community?.name,
    }
    onAddCompany(company as CompanyFacet, e)
  }

  const handleBlur = (e: FocusEvent) => {
    // Don't blur if child elements (company list items) are focused
    if (!e.currentTarget?.contains(e.relatedTarget)) {
      setMatchedCompanies([])
    }
  }

  const handleFocus = () => {
    setMatchedCompanies(getSuggestions(searchText))
  }

  return (
    <div className={`add-company ${isCondensed ? 'condensed' : ''}`} data-testid={'add-company-facet'}>
      <div className="input-container" onBlur={handleBlur}>
        <Form.Control
          type="text"
          placeholder={placeholder}
          onChange={handleAddCompanyTextChanged}
          value={searchText}
          onKeyDown={handleInputKeyPresses}
          role="input"
          onFocus={handleFocus}
          ref={inputRef}
          data-testid={'add-company-input'}
          name="search"
        />
        {Boolean(matchedCompanies.length) && (
          <div className="suggestion-list">
            <ul>{getSuggestionList()}</ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddCompanyBox
