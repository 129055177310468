import React, { useEffect, useRef } from 'react'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import '@css/common/OneColumnLayout.scss'
import '@css/common/TwoColumnLayout.scss'
import { AppHeader } from '~/common/AppHeader'
import { useAuth } from '~/auth/Auth'
import { Footer } from '~/common/Footer'

type ColumnLayoutProps = {
  children: [first: JSX.Element, second: JSX.Element]
  threshold: SizeBreakpoint
  currentPage?: string
  additionalColumnClass?: string
  additionalContentClass?: string
}

// adaptable layout that switches from TwoColumn to OneColumn at a specified breakpoint and prevents loss of state
const ColumnLayout = ({
  children: [first, second],
  threshold,
  currentPage,
  additionalColumnClass,
}: ColumnLayoutProps) => {
  const { isCondensed, breakpoint, isCondensedPortrait: isMobile, windowSize } = useWindowSize()
  const { profileVisible } = useAuth()
  const showOneColumn = breakpoint <= threshold
  const isCommunityPage = ['comm-list', 'community-layout'].includes(additionalColumnClass ?? '')
  const sidebarRef = useRef<HTMLDivElement>(null)

  const sidebarBottom = sidebarRef.current?.getBoundingClientRect().bottom ?? 0

  useEffect(() => {
    if (sidebarRef.current) {
      // if the sidebar is cut off, we want it to scroll with the right panel content. If not, we want it to be fixed.
      if (sidebarBottom > windowSize.height) {
        sidebarRef.current.classList.add('scroll')
        sidebarRef.current.classList.remove('fixed')
      } else {
        sidebarRef.current.classList.add('fixed')
        sidebarRef.current.classList.remove('scroll')
      }
    }
  }, [windowSize.height, sidebarBottom])

  return (
    <>
      <div
        className={`${
          showOneColumn ? `one-column ${isCondensed ? 'condensed' : ''}` : 'two-column'
        } ${additionalColumnClass} ${isMobile ? 'mobile' : ''}`}
      >
        <AppHeader currentPage={currentPage} />
        <div className={'column-layout-container'}>
          <div className={showOneColumn ? 'topper-zone' : `sidebar-zone ${!profileVisible ? 'read-only' : ''}`}>
            <div
              className={`${showOneColumn ? 'content container' : 'sidebar-area'} ${
                isCommunityPage && 'community-sidebar-area'
              }`}
              ref={sidebarRef}
            >
              <div className={showOneColumn ? 'row' : 'two-column-placeholder'}>
                <div className={showOneColumn ? 'header-area col-sm-12' : 'two-column-placeholder'}>{first}</div>
              </div>
            </div>
          </div>
          <div className={`content-zone${!showOneColumn && !profileVisible ? ' read-only' : ''}`}>
            <div className={`${showOneColumn ? 'content container' : 'content-area'}`}>
              <div className={showOneColumn ? 'content-zone row' : 'two-column-placeholder'}>
                <div
                  className={
                    showOneColumn
                      ? `content-area  col-sm-12${isCondensed ? ' condensed' : ' not-condensed'}`
                      : 'two-column-placeholder'
                  }
                >
                  {second}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ColumnLayout
