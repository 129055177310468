import React, { useEffect, useState } from 'react'
import { asHtmlWithMentions, asString } from '~/utils'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import { UserModel } from '~/types'
import { Html, Maybe } from '~/api/generated/graphql'
import ProfileEditHeader from '~/pages/profile/ProfileEditHeader'
import MentionableText from '~/common/MentionableText'
import { useProfile } from '~/contexts/ProfileContext'

type Props = {
  user: UserModel
  headerText: string
  canEdit: boolean
  isOwnProfile?: boolean
  modifiedTime?: Date
  content?: Maybe<Html>
  placeHolder: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveContent: (newContent: Maybe<Html>) => Promise<any>
  companyId?: string
}

export const ProfileEditableText = ({
  user,
  headerText,
  modifiedTime,
  content,
  canEdit,
  saveContent,
  placeHolder,
  isOwnProfile = false,
  companyId,
}: Props) => {
  const [editing, setEditing] = useState(false)
  const [editContent, setEditContent] = useState(content ?? null)
  const { updateEditingRoles } = useProfile()

  const clickCancel = () => {
    setEditContent(content ?? null)
    setEditing(false)
    updateEditingRoles?.(companyId ?? 'about', false)
  }
  const clickSave = () => {
    saveContent(editContent).then(() => setEditing(false))
    updateEditingRoles?.(companyId ?? 'about', false)
  }

  useEffect(() => {
    clickCancel()
    // Ensures the state of the Profile Page is set to the currently viewed user
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const contentIsEmpty =
    !content || content.html?.length === 0 || content.html === '<p></p>' || content.html === '<p><br></p>'
  const emptyMessage = isOwnProfile ? `<span class="empty-content">${placeHolder}</span>` : ''

  return (
    <div className="editable-text">
      <ProfileEditHeader
        userId={user.userId}
        editing={editing}
        headerText={headerText}
        clickCancel={clickCancel}
        clickSave={clickSave}
        modifiedTime={modifiedTime}
        canEdit={canEdit}
        setEditing={setEditing}
        companyId={companyId}
      />
      {editing ? (
        <>
          <QuillEditor<Maybe<Html>>
            className="quill-editor"
            toolbar={QuillToolbar.Full}
            placeholder={placeHolder}
            initialHtml={editContent}
            setHtml={setEditContent}
            allowMentions={false}
          />
        </>
      ) : (
        <>
          <div className="content-section quill-editor-elements">
            {/*Profile editable text fields don't include @ mentions but are still created from the quill editor. */}
            {/*We want to treat links the same as we do in the post stories. */}
            <MentionableText value={asHtmlWithMentions(contentIsEmpty ? emptyMessage : asString(content))} />
          </div>
        </>
      )}
    </div>
  )
}
