import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import '@css/pages/community/CommunityTable.scss'
import '@css/pages/community/CommunityMembers.scss'
import '@css/pages/company/CompanyAccountPartners.scss'
import '@css/pages/company/CompanyMemberRow.scss'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import ToastComponent from '~/common/ToastComponent'
import Globe from '@web/images/community/globe-icon.svg'
import {
  Membership,
  Maybe,
  AddRoleDocument,
  EditRoleDocument,
  GetCommunityMemberRowUserDocument,
  RemoveMemberDocument,
} from '~/api/generated/graphql'
import { useCommunity } from '~/contexts/CommunityContext'
import { useAuth } from '~/auth/Auth'
import { useNavigate } from 'react-router'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { getAccountPartnerTypeDisplay, getFullName, getGlobalLeadList, removeMemberCache } from '~/utils'
import pencilIcon from '@web/images/community/pencil-icon.svg'
import PlainTextInput from '~/common/PlainTextInput'
import GlobalLeadSelector from '~/pages/company/GlobalLeadSelector'
import MemberRemove from '@web/images/community/MemberRemove'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { elementClicked } from '~/common/EventLogger'
import { useMutation, useQuery } from '@apollo/client'

export type ChangeMemberTypeParams = {
  userId: string
  leader: boolean
  accountLead: boolean
  commercialLead: boolean
  rdqLead: boolean
}

export type Leads = { account: Maybe<string>[]; rdq: Maybe<string>[]; commercial: Maybe<string>[] }
export const getLeads = (members?: Membership[]) =>
  members && {
    account: members.filter(m => m.accountLead).map(m => m.userId ?? null),
    commercial: members.filter(m => m.commercialLead).map(m => m.userId ?? null),
    rdq: members.filter(m => m.rdqLead).map(m => m.userId ?? null),
  }

interface CompanyMemberRowProps {
  onChangeMemberType: (params: ChangeMemberTypeParams) => Promise<void>
  member: Membership
  isVeeva: boolean
  leads?: Leads
  setParentModalText?: (s: string) => void
  isAccountPartner?: boolean
  role: string | undefined
}

export const CompanyMemberRow = ({
  onChangeMemberType,
  member,
  isVeeva,
  leads,
  setParentModalText,
  isAccountPartner,
  role,
}: CompanyMemberRowProps) => {
  const { userId, leader, accountLead, commercialLead, rdqLead } = member

  const { breakpoint } = useWindowSize()
  const { authUserId, isVeevan, profileVisible } = useAuth()
  const { communityId, companyId } = useCommunity()
  const navigate = useNavigate()
  const [displayRole, setDisplayRole] = useState(role)
  const [isManaging, setIsManaging] = useState<boolean>(false)
  const [pendingRole, setPendingRole] = useState(role)

  // role is initially undefined while waiting to get the value for roleData
  useEffect(() => {
    setPendingRole(role)
    setDisplayRole(role)
  }, [role])

  const { data: userData } = useQuery(GetCommunityMemberRowUserDocument, {
    variables: { id: userId ?? '' },
    skip: !userId,
  })
  const communityMember = userData?.user

  const showEditPencil =
    isVeevan &&
    profileVisible &&
    !isManaging &&
    !isVeeva &&
    !(companyId && companyId === communityMember?.company?.companyId)

  const profileLink = `/profiles/${userId}`

  const [accountPartnerChecked, setAccountPartnerChecked] = useState<boolean>(member?.leader ?? false)
  const [globalAccountChecked, setGlobalAccountChecked] = useState<boolean>(member?.accountLead ?? false)
  const [globalCommercialChecked, setGlobalCommercialChecked] = useState<boolean>(member?.commercialLead ?? false)
  const [globalQualityChecked, setGlobalQualityChecked] = useState<boolean>(member?.rdqLead ?? false)

  const showGlobe = globalAccountChecked || globalCommercialChecked || globalQualityChecked
  const [globeVisibility, setGlobeVisibility] = useState(showGlobe)
  const [memberTypeDisplay, setMemberTypeDisplay] = useState<string>(
    getAccountPartnerTypeDisplay(
      accountPartnerChecked,
      globalAccountChecked,
      globalCommercialChecked,
      globalQualityChecked
    )
  )
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
  const [isHoveringRemove, setIsHoveringRemove] = useState(false)

  const [duplicateText, setDuplicateText] = useState<string | null>(null)
  const [modalText, setModalText] = useState<string | null>(null)
  const [toast, setToast] = useState<string | null>(null)

  const handleHideModal = useCallback(() => {
    setModalText(null)
  }, [])

  const accountLeadExists = useMemo(() => {
    return leads?.account.findIndex(m => m != member.userId) !== -1
  }, [leads, member.userId])

  const commercialLeadExists = useMemo(() => {
    return leads?.commercial.findIndex(m => m != member.userId) !== -1
  }, [leads, member.userId])

  const rdqLeadExists = useMemo(() => {
    return leads?.rdq.findIndex(m => m != member.userId) !== -1
  }, [leads, member.userId])

  const [addRole] = useMutation(AddRoleDocument)
  const [updateRole] = useMutation(EditRoleDocument)

  const handleEditClick = (e: SyntheticEvent) => {
    setIsManaging(!isManaging)
    elementClicked(e, 'click-community-members-edit', { userId: communityMember?.userId, communityId: communityId })
  }

  const resetPendingChanges = () => {
    setPendingRole(role)
    setAccountPartnerChecked(leader)
    setGlobalAccountChecked(accountLead)
    setGlobalCommercialChecked(commercialLead)
    setGlobalQualityChecked(rdqLead)
    setMemberTypeDisplay(getAccountPartnerTypeDisplay(leader, accountLead, commercialLead, rdqLead))
  }

  const checkDuplicateLeads = async (e: SyntheticEvent) => {
    if (
      (globalAccountChecked && accountLeadExists) ||
      (globalCommercialChecked && commercialLeadExists) ||
      (globalQualityChecked && rdqLeadExists)
    ) {
      const text = `Are you sure you want to make this change? The following roles are already assigned to someone: ${getGlobalLeadList(
        globalAccountChecked,
        globalCommercialChecked,
        globalQualityChecked,
        ', '
      )}`
      setDuplicateText(text)
      setShowDuplicateDialog(true)
    } else {
      await updateType(e)
    }
  }

  const updateType = async (e: SyntheticEvent) => {
    setShowDuplicateDialog(false)
    await onChangeMemberType({
      userId: userId ?? '',
      leader: accountPartnerChecked ?? false,
      accountLead: globalAccountChecked ?? false,
      commercialLead: globalCommercialChecked ?? false,
      rdqLead: globalQualityChecked ?? false,
    })
    const typeUpdated =
      accountPartnerChecked !== leader ||
      globalAccountChecked !== accountLead ||
      globalCommercialChecked !== commercialLead ||
      globalQualityChecked !== rdqLead
    if (typeUpdated) {
      elementClicked(e, 'click-community-members-type-updated', {
        userId: member?.user?.userId,
        communityId: communityId,
      })
      setToast('Type updated')
    }
    setIsManaging(false)
  }

  const updateOrAddRole = async (e: SyntheticEvent) => {
    elementClicked(e, 'click-community-members-role-updated', {
      communityId: communityId,
      userId: communityMember?.userId,
    })
    if (displayRole !== undefined && displayRole !== pendingRole) {
      await doUpdateRole()
    } else if (pendingRole && !role) {
      await doAddRole()
    }
    await checkDuplicateLeads(e)
  }

  const doAddRole = async () => {
    if (pendingRole === null) {
      return
    }
    const response = await addRole({
      variables: {
        userId: userId ?? '',
        companyId: companyId ?? '',
        description: pendingRole,
      },
    })
    if (response.data?.addRole?.ok) {
      setToast('Role description updated successfully.')
      setDisplayRole(response.data?.addRole?.data?.description ?? '')
    }
  }

  const doUpdateRole = async () => {
    const response = await updateRole({
      variables: {
        userId: userId ?? '',
        companyId: companyId ?? '',
        description: pendingRole,
      },
    })
    if (response.data?.editRole?.ok) {
      setToast('Role description updated successfully.')
      setDisplayRole(response.data?.editRole?.data?.description ?? '')
    }
    setIsManaging(false)
  }

  const [removeMember] = useMutation(RemoveMemberDocument, {
    update: (cache, { data }) => {
      data?.removeMember?.ok && removeMemberCache(cache, data)
    },
  })

  const handleClickRemove = (e: SyntheticEvent) => {
    elementClicked(e, 'click-community-members-deleted', { userId: member?.user?.userId, communityId: communityId })
    setShowRemoveDialog(true)
  }
  const handleCancelRemove = () => setShowRemoveDialog(false)
  const handleConfirmDelete = async () => {
    const response = await removeMember({
      variables: {
        userId: member?.userId || '',
        communityId: communityId ?? '' ?? '',
      },
    })
    setShowRemoveDialog(false)
    if (authUserId === member?.userId) {
      navigate(`/companies/${companyId}/home`)
    }
    if (response?.data?.removeMember?.ok) {
      setParentModalText?.(`The ${isAccountPartner ? 'account partner' : 'member'} was removed successfully`)
    } else if (response?.data?.removeMember?.error) {
      switch (response.data.removeMember.error.code) {
        case 'cannotRemoveNonVeevan':
          setModalText(`Employees cannot be removed from their customer homepage`)
          break
        default: // fallback just in case
          setModalText('Something went wrong. Please try again.')
          break
      }
    }
  }

  const memberFullName = getFullName(communityMember)
  const memberCompanyName = communityMember?.company?.name
  const memberTitle = communityMember?.title
  const profileTitle = 'View ' + memberFullName + "'s Profile"

  const editRef = useClickOnEnter<HTMLImageElement>()
  const memberInfoRef = useClickOnEnter<HTMLAnchorElement>()

  const handleAccountPartnerCheck = () => {
    if (accountPartnerChecked) {
      setAccountPartnerChecked(false)
      setGlobalAccountChecked(false)
      setGlobalCommercialChecked(false)
      setGlobalQualityChecked(false)
    } else {
      setAccountPartnerChecked(true)
    }
  }

  const handleGlobalAccountCheck = () => {
    setGlobalAccountChecked(!globalAccountChecked)
    setAccountPartnerChecked(true)
  }

  const handleGlobalCommercialCheck = () => {
    setGlobalCommercialChecked(!globalCommercialChecked)
    setAccountPartnerChecked(true)
  }

  const handleGlobalQualityCheck = () => {
    setGlobalQualityChecked(!globalQualityChecked)
    setAccountPartnerChecked(true)
  }

  const handleRolesDone = () => {
    setMemberTypeDisplay(
      getAccountPartnerTypeDisplay(
        accountPartnerChecked,
        globalAccountChecked,
        globalCommercialChecked,
        globalQualityChecked
      )
    )
    setGlobeVisibility(globalAccountChecked || globalCommercialChecked || globalQualityChecked)
  }

  const nameBadgeLayout = (
    <Link
      ref={memberInfoRef}
      to={profileLink}
      tabIndex={0}
      className={`image-with-details ${isVeeva ? ' full-column' : ' semi-wide-column'}`}
      title={profileTitle}
    >
      <ProfilePhoto userId={userId} />
      <div className="member-container">
        <div className="member-name">{memberFullName}</div>
        <div className="member-title">{memberTitle}</div>
        <div className="member-company">{memberCompanyName}</div>
      </div>
    </Link>
  )

  const modals = (
    <>
      <Modal show={showRemoveDialog} onHide={handleCancelRemove} className={'delete'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>Are you sure you want to remove this member from this customer homepage?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={handleCancelRemove}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={handleConfirmDelete}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDuplicateDialog} onHide={() => setShowDuplicateDialog(false)} className={'duplicate'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>{duplicateText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowDuplicateDialog(false)}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={updateType}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={!!modalText} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Body>
            <p>{modalText}</p>
          </Modal.Body>
        </Modal.Header>
      </Modal>
      <ToastComponent show={!!toast} onClose={() => setToast(null)}>
        {toast ? toast : ''}
      </ToastComponent>
    </>
  )

  if (breakpoint < SizeBreakpoint.lg)
    // mobile layout
    return (
      <div className={'condensed-member'}>
        <div className={'member-card'}>
          {leader && !isManaging && !isVeeva && <span className={'member-leader'}>{memberTypeDisplay}</span>}
          {isManaging && (
            <div className={'button-row global-lead-container'}>
              {communityMember?.isVeevan && (
                <GlobalLeadSelector
                  userId={userId || ''}
                  type={memberTypeDisplay}
                  accountPartnerChecked={accountPartnerChecked}
                  globalAccountChecked={globalAccountChecked}
                  globalCommercialChecked={globalCommercialChecked}
                  globalQualityChecked={globalQualityChecked}
                  handleAccountPartnerCheck={handleAccountPartnerCheck}
                  handleGlobalAccountCheck={handleGlobalAccountCheck}
                  handleGlobalCommercialCheck={handleGlobalCommercialCheck}
                  handleGlobalQualityCheck={handleGlobalQualityCheck}
                  handleRolesDone={handleRolesDone}
                  showGlobe={globeVisibility}
                />
              )}
            </div>
          )}
          <Link
            className={`condensed-image-with-details member-details`}
            title={profileTitle}
            ref={memberInfoRef}
            to={profileLink}
            tabIndex={-1}
          >
            <ProfilePhoto userId={userId} />
            <div className="member-container">
              <div className="member-name" tabIndex={-1}>
                {memberFullName}
              </div>
              <div className="member-title" tabIndex={-1}>
                {memberTitle}
              </div>
              <div className="member-company" tabIndex={-1}>
                {memberCompanyName}
              </div>
            </div>
          </Link>
          {communityMember?.isVeevan &&
            !isVeeva &&
            (isManaging ? (
              <>
                <span className="member-role-description">Description of Role</span>
                <div className="role-edit-column authoring-form" data-testid="quill">
                  <PlainTextInput
                    placeholder={'Role Description'}
                    onChange={setPendingRole}
                    value={pendingRole ?? null}
                  />
                </div>
              </>
            ) : (
              <span className={`member-role`} role="role" dangerouslySetInnerHTML={{ __html: displayRole ?? '' }} />
            ))}
          {isManaging && (
            <div className={'button-row bottom-row'}>
              {!(companyId && companyId === communityMember?.company?.companyId) && (
                <span className="remove-button" onClick={handleClickRemove} role={'remove-button'} tabIndex={0}>
                  <MemberRemove fill={'#c00000'} />
                </span>
              )}
              <Button
                variant="light"
                onClick={() => {
                  resetPendingChanges()
                  setIsManaging(!isManaging)
                }}
                role={'cancel-button'}
                tabIndex={0}
              >
                Cancel
              </Button>
              {communityMember?.isVeevan && (
                <Button onClick={updateOrAddRole} role={'save-button'} tabIndex={0}>
                  Save
                </Button>
              )}
            </div>
          )}
        </div>
        {showEditPencil && (
          <img
            className="edit-button"
            src={pencilIcon}
            alt="Edit"
            onClick={handleEditClick}
            role={'button'}
            ref={editRef}
            tabIndex={0}
          />
        )}
        {modals}
      </div>
    )
  else
    return (
      <>
        <div className="table-row members company-members">
          {isManaging ? (
            // managing layout
            <>
              {nameBadgeLayout}
              <GlobalLeadSelector
                userId={userId || ''}
                type={memberTypeDisplay}
                accountPartnerChecked={accountPartnerChecked}
                globalAccountChecked={globalAccountChecked}
                globalCommercialChecked={globalCommercialChecked}
                globalQualityChecked={globalQualityChecked}
                handleAccountPartnerCheck={handleAccountPartnerCheck}
                handleGlobalAccountCheck={handleGlobalAccountCheck}
                handleGlobalCommercialCheck={handleGlobalCommercialCheck}
                handleGlobalQualityCheck={handleGlobalQualityCheck}
                handleRolesDone={handleRolesDone}
                showGlobe={globeVisibility}
              />
              <div className={`authoring-form company-role-column`} data-testid="quill">
                <PlainTextInput
                  placeholder={'Role Description'}
                  onChange={setPendingRole}
                  value={pendingRole ?? null}
                />
              </div>
              <div className={`button-row`}>
                <Button
                  variant="light"
                  onClick={() => {
                    resetPendingChanges()
                    setIsManaging(!isManaging)
                  }}
                  role={'cancel-button'}
                  tabIndex={0}
                >
                  Cancel
                </Button>
                {communityMember?.isVeevan && (
                  <Button onClick={updateOrAddRole} role={'save-button'} tabIndex={0}>
                    Save
                  </Button>
                )}
                {!(companyId && companyId === communityMember?.company?.companyId) && (
                  <span
                    className="remove-button"
                    onClick={handleClickRemove}
                    onMouseOver={() => setIsHoveringRemove(true)}
                    onMouseOut={() => setIsHoveringRemove(false)}
                    role={'remove-button'}
                    title="Remove member"
                    tabIndex={0}
                  >
                    {isHoveringRemove ? <MemberRemove fill={'#c00000'} /> : <MemberRemove fill={'#676969'} />}
                  </span>
                )}
              </div>
            </>
          ) : (
            // standard layout
            <>
              {/*NAME & JOB TITLE COLUMN*/}
              {nameBadgeLayout}
              {!isVeeva && (
                <>
                  {/*TYPE COLUMN*/}
                  <div className={'leader-type-list company-type-column'}>
                    {showGlobe && <img src={Globe} alt={'Global'} />}
                    <span className={`row-text member-leader${showGlobe ? ' globe' : ' no-globe'}`} role="type">
                      {memberTypeDisplay}
                    </span>
                  </div>
                  {/*ROLE COLUMN*/}
                  <span
                    className={`row-text company-role-column`}
                    role="role"
                    dangerouslySetInnerHTML={{ __html: displayRole ?? '' }}
                  />
                </>
              )}
              <div className="edit-actions">
                {showEditPencil && (
                  <img
                    className="edit-button"
                    src={pencilIcon}
                    alt="Edit"
                    onClick={handleEditClick}
                    role={'button'}
                    ref={editRef}
                    tabIndex={0}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {modals}
      </>
    )
}
