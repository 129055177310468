import React, { useCallback } from 'react'
import '@css/pages/notifications/NotificationListItem.scss'
import { NotificationModel } from '~/types'
import { Link } from 'react-router-dom'
import NotificationMessage from '~/pages/notifications/NotificationMessage'
import {
  CommunityType,
  NotificationType,
  PostType,
  MarkViewedDocument,
  NotificationListCommunityDocument,
  NotificationListUserDocument,
} from '~/api/generated/graphql'
import { photoStyle, toTimeAgo } from '~/utils'
import eventIcon from '@web/images/notifications/notification-event.svg'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { elementClicked } from '~/common/EventLogger'
import { useMutation, useQuery } from '@apollo/client'

type NotificationListItemProps = {
  notification: NotificationModel
  onClick: () => void
}

const PROFILE_PHOTO_TYPES = [
  NotificationType.CommentLikeTellOwnr,
  NotificationType.CommentMentionTellSubj,
  NotificationType.NewCommentTellOwnr,
  NotificationType.NewCommentTellLdrs,
  NotificationType.NewCommentTellLdrOwnr,
  NotificationType.DelContentTellLdrs,
  NotificationType.DelCommunityTellLdrs,
  NotificationType.PostLikeTellOwnr,
  NotificationType.PostMentionTellSubj,
  NotificationType.NewPostTellLdrs,
  NotificationType.NewPostTellMmbr,
  NotificationType.CommentAllTellMmbrs,
  NotificationType.PostAllTellMmbrs,
  NotificationType.NewContentTellMmbr,
  NotificationType.NewContentTellLdrs,
  NotificationType.NonVeevanRegistersTellLdrs,
  NotificationType.NewCommentTellCommenters,
  NotificationType.NewCommentTellParticipants,
  NotificationType.NewCommentTellFollowers,
  NotificationType.NewCommentTellLdrFollowers,
  NotificationType.NewCommentTellLdrCommenters,
  NotificationType.NewCommentTellLdrParticipants,
  NotificationType.VeevanProfileModified,
  NotificationType.UpdContentTellOwnr,
  NotificationType.UpdContentTellLdrs,
  NotificationType.UpdContentTellOldAuthor,
  NotificationType.ConvPostTellOwnr,
  NotificationType.ConvPostTellLdrs,
  NotificationType.PostMovedTellLeaders,
]
const COMMUNITY_PHOTO_TYPES = [
  NotificationType.AddMemberTellMmbr,
  NotificationType.DemotedTellLdrs,
  NotificationType.DemotedTellSubj,
  NotificationType.PromotedTellLdrs,
  NotificationType.PromotedTellSubj,
  NotificationType.MemberRemovedTellLdrs,
  NotificationType.MemberRemovedTellMmbr,
  NotificationType.LdrLeftTellLdrs,
  NotificationType.LdrRemovedTellLdrs,
  NotificationType.AddMemberTellLdrs,
  NotificationType.MemberJoinTellLdrs,
  NotificationType.MemberLeftTellLdrs,
  NotificationType.InvitationAccepted,
]
const EVENT_TYPES = [
  NotificationType.NewCommentMeetupTellViewers,
  NotificationType.NewCommentMeetupTellLeaders,
  NotificationType.NewPostMeetupTellMembers,
  NotificationType.NewPostMeetupTellLeaders,
  NotificationType.NewEventTellMmbr,
  NotificationType.UpdEventTellMmbr,
]

const COMMUNITY_FOOTER_TYPES = [
  NotificationType.CommentLikeTellOwnr,
  NotificationType.CommentMentionTellSubj,
  NotificationType.NewCommentTellLdrs,
  NotificationType.NewCommentTellOwnr,
  NotificationType.NewCommentTellLdrOwnr,
  NotificationType.MemberJoinTellLdrs,
  NotificationType.MemberRemovedTellLdrs,
  NotificationType.NewEventTellMmbr,
  NotificationType.UpdEventTellMmbr,
  NotificationType.AddMemberTellLdrs,
  NotificationType.PostLikeTellOwnr,
  NotificationType.PostMentionTellSubj,
  NotificationType.NewPostTellLdrs,
  NotificationType.NewPostTellMmbr,
  NotificationType.NewCommentMeetupTellViewers,
  NotificationType.NewCommentMeetupTellLeaders,
  NotificationType.NewPostMeetupTellMembers,
  NotificationType.NewPostMeetupTellLeaders,
  NotificationType.MemberLeftTellLdrs,
  NotificationType.LdrLeftTellLdrs,
  NotificationType.LdrRemovedTellLdrs,
  NotificationType.DemotedTellLdrs,
  NotificationType.CommentAllTellMmbrs,
  NotificationType.PostAllTellMmbrs,
  NotificationType.NewContentTellMmbr,
  NotificationType.NewCommentTellCommenters,
  NotificationType.NewCommentTellParticipants,
  NotificationType.NewCommentTellFollowers,
  NotificationType.NewCommentTellLdrFollowers,
  NotificationType.NewCommentTellLdrCommenters,
  NotificationType.NewCommentTellLdrParticipants,
  NotificationType.InvitationAccepted,
  NotificationType.PostMovedTellLeaders,
]
const NotificationListItem = ({ notification, onClick }: NotificationListItemProps) => {
  const { breakpoint } = useWindowSize()
  const showSmallVeevaIcon = breakpoint < SizeBreakpoint.xl
  const type = notification.notificationType

  const showProfilePhoto = PROFILE_PHOTO_TYPES.includes(type)
  const showCommunityPhoto = COMMUNITY_PHOTO_TYPES.includes(type)
  const showEventIcon = EVENT_TYPES.includes(type)
  const showCommunityFooter = COMMUNITY_FOOTER_TYPES.includes(type)

  const { data: communityData } = useQuery(NotificationListCommunityDocument, {
    variables: { id: notification?.communityId ?? '' },
    skip: !notification?.communityId,
  })
  const { community } = communityData ?? {}
  const { data: actorData } = useQuery(NotificationListUserDocument, {
    variables: { id: notification?.actorId ?? '' },
    skip: !notification?.actorId,
  })
  const { user: actor } = actorData ?? {}

  const isHomepage = community?.type == CommunityType.Homepage
  const communityRouteType = isHomepage ? 'companies' : 'communities'
  const communityId = isHomepage ? community?.companyId : community?.communityId
  const postId = notification.post?.postId
  const postType = notification.post?.postType
  const commentId = notification.comment?.commentId
  const eventId = notification.event?.eventId

  let link = ''
  switch (notification.notificationType) {
    case NotificationType.AddMemberTellMmbr:
    case NotificationType.MemberRemovedTellMmbr:
      link = `/${communityRouteType}/${communityId}`
      break
    case NotificationType.CommentMentionTellSubj:
      link = notification.comment?.isVeevanDiscussion
        ? `/${communityRouteType}/${communityId}/posts/${postId}?c=${commentId}&vd=1`
        : `/${communityRouteType}/${communityId}/posts/${postId}?c=${commentId}`
      break
    case NotificationType.PostMentionTellSubj:
    case NotificationType.NewPostTellMmbr:
    case NotificationType.PostLikeTellOwnr:
    case NotificationType.NewPostTellLdrs:
    case NotificationType.NewPostMeetupTellMembers:
    case NotificationType.NewPostMeetupTellLeaders:
    case NotificationType.PostAllTellMmbrs:
    case NotificationType.NewContentTellMmbr:
    case NotificationType.NewContentTellLdrs:
      link = `/${communityRouteType}/${communityId}/posts/${postId}`
      break
    case NotificationType.NewCommentMeetupTellViewers:
    case NotificationType.NewCommentMeetupTellLeaders:
    case NotificationType.NewCommentTellOwnr:
    case NotificationType.NewCommentTellLdrOwnr:
    case NotificationType.NewCommentTellLdrs:
    case NotificationType.CommentAllTellMmbrs:
    case NotificationType.CommentLikeTellOwnr:
    case NotificationType.NewCommentTellCommenters:
    case NotificationType.NewCommentTellParticipants:
    case NotificationType.NewCommentTellFollowers:
    case NotificationType.NewCommentTellLdrFollowers:
    case NotificationType.NewCommentTellLdrCommenters:
    case NotificationType.NewCommentTellLdrParticipants:
      link = notification.comment?.isVeevanDiscussion
        ? `/${communityRouteType}/${communityId}/posts/${postId}?c=${commentId}&vd=1`
        : `/${communityRouteType}/${communityId}/posts/${postId}?c=${commentId}`
      break
    case NotificationType.NewEventTellMmbr:
    case NotificationType.UpdEventTellMmbr:
      link = `/${communityRouteType}/${communityId}/events?e=${eventId}`
      break
    case NotificationType.AddMemberTellLdrs:
    case NotificationType.MemberRemovedTellLdrs:
    case NotificationType.NonVeevanRegistersTellLdrs:
    case NotificationType.InvitationAccepted:
    case NotificationType.VeevanProfileModified:
    case NotificationType.MemberJoinTellLdrs:
    case NotificationType.MemberLeftTellLdrs:
      link = `/profiles/${notification.userId}/`
      break
    case NotificationType.DelContentTellLdrs:
      link = `/${communityRouteType}/${communityId}/history`
      break
    case NotificationType.PromotedTellSubj:
    case NotificationType.PromotedTellLdrs:
    case NotificationType.DemotedTellSubj:
    case NotificationType.DemotedTellLdrs:
    case NotificationType.LdrLeftTellLdrs:
    case NotificationType.LdrRemovedTellLdrs:
      link = `/${communityRouteType}/${communityId}/members`
      break
    case NotificationType.UpdContentTellOwnr:
    case NotificationType.UpdContentTellLdrs:
    case NotificationType.UpdContentTellOldAuthor:
      link = `/${communityRouteType}/${communityId}/content/${postId}`
      break
    case NotificationType.ConvPostTellOwnr:
    case NotificationType.ConvPostTellLdrs:
    case NotificationType.PostMovedTellLeaders:
      link = `/${communityRouteType}/${communityId}/${postType === PostType.Content ? 'content' : 'posts'}/${postId}`
      break
  }

  const profilePhoto = photoStyle(actor?.photo ?? null)
  const communityPhoto = photoStyle(community?.photo ?? null)

  const [markViewed] = useMutation(MarkViewedDocument, { refetchQueries: ['GetNotifications'] })
  const handleNotificationClicked = useCallback(
    (e: React.MouseEvent) => {
      elementClicked(e, 'click-notification-item')
      markViewed({ variables: { notificationId: notification.notificationId } }).then()
      onClick()
    },
    [notification, onClick, markViewed]
  )

  const communityClass = `${isHomepage ? 'company' : 'community'}`
  return (
    <Link
      to={link}
      className={`notification ${!notification.viewedAt ? 'unviewed' : ''}`}
      onClick={handleNotificationClicked}
    >
      <>
        {showProfilePhoto && (
          <div className="user profile-photo">
            <div
              className={`photo ${showSmallVeevaIcon && 'small-veeva-icon'}`}
              style={profilePhoto}
              data-company={actor?.company?.name}
            />
          </div>
        )}
        {showCommunityPhoto && <div className={`profile-photo ${communityClass}`} style={communityPhoto} />}
        {showEventIcon && <img src={eventIcon} alt={'Event'} className="profile-photo" />}
      </>
      <div className={'notification-content'}>
        <NotificationMessage notification={notification} />
        <span className={'timestamp'}>{toTimeAgo(notification.created)}</span>
        {showCommunityFooter && (
          <div className={'notification-footer'}>
            <div className={`profile-photo community-photo-footer ${communityClass}`} style={communityPhoto} />
            <span>{community?.name}</span>
          </div>
        )}
      </div>
    </Link>
  )
}

export default NotificationListItem
