import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AsyncTypeahead, Hint, Menu, MenuItem } from 'react-bootstrap-typeahead'
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead'
import { CommunityToDisplay } from '~/pages/page/PostSelectionModal'
import { RenderMenuProps } from 'react-bootstrap-typeahead/types/components/Typeahead'
import { TypeaheadInputProps } from 'react-bootstrap-typeahead/types/types'
import searchClear from '@web/images/community/search-clear.svg'
import { debounce } from '~/utils'
import { Form } from 'react-bootstrap'

type TypeaheadDropdownProps = {
  options: CommunityToDisplay[]
  handleSearch: (s: string) => void
  onSelect: (s: string) => void
  onClear?: () => void
  placeholder?: string
}
const PageCommunityTypeahead = ({
  options,
  handleSearch,
  onSelect,
  onClear,
  placeholder = 'Select a community to list content posts',
}: TypeaheadDropdownProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const typeaheadRef = useRef<Typeahead>(null)
  const [showClearButton, setShowClearButton] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [loadingSearch, setLoadingSearch] = useState<boolean>(true)

  const clearInput = () => {
    if (searchInputRef?.current) {
      searchInputRef.current.value = ''
      typeaheadRef.current?.clear()
      searchInputRef.current.blur()
      onClear?.()
    }
  }

  useEffect(() => {
    if (searchInputRef?.current?.value !== '') {
      setShowClearButton(true)
    }
  }, [searchInputRef])

  const renderMenu = (results: CommunityToDisplay[], menuProps: RenderMenuProps) => {
    const filteredResults = results.filter(result => result.name)

    return filteredResults.length > 0 ? (
      <div>
        <Menu className={`${loadingSearch ? 'loading' : ''}`} id={menuProps.id} ref={menuProps.ref}>
          {filteredResults.map((result, index) => {
            if (result.name) {
              return (
                <MenuItem key={index} className={`result-row`} option={result} position={index++}>
                  {result.name}
                </MenuItem>
              )
            }
          })}
        </Menu>
      </div>
    ) : (
      <></>
    )
  }

  const renderInput = (inputProps: TypeaheadInputProps) => {
    const { onFocus, onKeyDown, onBlur, onChange } = inputProps
    return (
      <Hint>
        <div className={`community-search-container`}>
          <Form.Control
            id={'community-search-input'}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            ref={searchInputRef}
            data-testid={'community-search-input'}
            autoComplete={'off'}
          />
          {showClearButton && (
            <img
              src={searchClear}
              alt={'Clear Search'}
              className={'community-search-clear'}
              role={'button'}
              onClick={() => {
                setShowClearButton(false)
                clearInput()
              }}
              data-testid={'community-search-clear'}
            />
          )}
        </div>
      </Hint>
    )
  }

  const debouncedSearchResults = useMemo(() => debounce(() => setLoadingSearch(false), 300), [])
  const bufferSearch = () => {
    setLoadingSearch(true)
    debouncedSearchResults().then()
    if (searchInputRef?.current?.value) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  return (
    <AsyncTypeahead
      ref={typeaheadRef}
      id={'typeahead-search'}
      options={options}
      placeholder={placeholder}
      labelKey={'name'}
      filterBy={() => true}
      renderMenu={renderMenu}
      renderInput={renderInput}
      isLoading={false}
      maxResults={100000}
      minLength={1}
      onSearch={handleSearch}
      onFocus={() => {
        if (searchInputRef?.current?.value) {
          handleSearch(searchInputRef?.current?.value)
          bufferSearch()
        }
      }}
      onBlur={() => {
        setShowMenu(false)
      }}
      open={showMenu}
      onInputChange={(text: string) => {
        bufferSearch()
        if (text) setShowClearButton(true)
        else setShowClearButton(false)
      }}
      onChange={selected => {
        const castedSelect = (selected as CommunityToDisplay[])[0]
        if (castedSelect) {
          if (searchInputRef?.current?.value) {
            searchInputRef.current.value = castedSelect.name
            onSelect(castedSelect.communityId)
            setShowMenu(false)
          }
        }
      }}
      inputProps={{}}
    ></AsyncTypeahead>
  )
}

export default PageCommunityTypeahead
