import { BlockEmbed } from 'quill/blots/block'

class MeetupBlot extends BlockEmbed {
  static blotName = 'meetup'
  static tagName = 'meetup'

  static create(args: { meetupId: string }) {
    const container = document.createElement('meetup')
    container.className = 'meetup'
    container.contentEditable = 'false'
    container.className = 'meetup'

    const registerBtn = document.createElement('button')
    registerBtn.setAttribute('class', 'btn-plain')
    const title = document.createElement('svg')
    title.className = 'btn-title'
    const span = document.createElement('span')
    span.innerText = 'Click to register!'

    registerBtn.appendChild(title)
    registerBtn.appendChild(span)
    container.appendChild(registerBtn)
    container.setAttribute('data-id', args.meetupId)
    container.setAttribute('data-testid', 'meetup-embed')
    return container
  }

  static value(node: Element) {
    return {
      meetupId: node.getAttribute('data-id'),
    }
  }
}

export default MeetupBlot
