import { useMemo } from 'react'
import {
  GetDiscussionCommentAuthorsDocument,
  GetPostCreatedByDocument,
  GetPublicCommentAuthorsDocument,
  ThreadUserFragmentDoc,
} from '~/api/generated/graphql'
import { Maybe } from '~/api/generated/graphql'
import { useApolloClient, useQuery } from '@apollo/client'
import { userByNameSort } from '~/utils'

export const usePostThreadIds = (postId: Maybe<string>) => {
  const client = useApolloClient()
  const { data: postData } = useQuery(GetPostCreatedByDocument, { variables: { id: postId ?? '' }, skip: !postId })
  const { data: publicCommentsData } = useQuery(GetPublicCommentAuthorsDocument, {
    variables: { id: postId ?? '' },
    skip: !postId,
  })
  const { data: discussionCommentsData } = useQuery(GetDiscussionCommentAuthorsDocument, {
    variables: { id: postId ?? '' },
    skip: !postId,
  })

  return useMemo(() => {
    const ids = new Set<string>()
    if (postData) {
      ids.add(postData?.post?.createdById ?? '')
    }
    if (publicCommentsData) {
      publicCommentsData.publicComments?.comments
        ?.map(c => {
          const userId = c?.createdById
          const user = client.readFragment({
            fragment: ThreadUserFragmentDoc,
            id: client.cache.identify({ __typename: 'User' as const, userId }),
          })
          if (user) return user
        })
        .sort(userByNameSort)
        .forEach(u => {
          if (u?.userId) ids.add(u.userId)
        })
    }
    if (discussionCommentsData) {
      discussionCommentsData.discussionComments?.comments
        ?.map(c => {
          const userId = c?.createdById
          const user = client.readFragment({
            fragment: ThreadUserFragmentDoc,
            id: client.cache.identify({ __typename: 'User' as const, userId }),
          })
          if (user) return user
        })
        .sort(userByNameSort)
        .forEach(u => {
          if (u?.userId) ids.add(u?.userId)
        })
    }
    return Array.from(ids).filter(Boolean)
  }, [postData, publicCommentsData, discussionCommentsData, client])
}
