import React, { useCallback, useState } from 'react'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import { Button } from 'react-bootstrap'
import { EditCommunityDocument, Html, Maybe } from '~/api/generated/graphql'
import { useCommunity } from '~/contexts/CommunityContext'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { asHtml, asString, HtmlOrStringValue } from '~/utils'
import { useMutation } from '@apollo/client'

interface CommunityAboutEditProps {
  about?: Maybe<Html>
  onDone: (shouldBeExpanded: boolean) => void
  onCancel: () => void
  expandAbout: boolean
}

const emptyEditorString = asHtml('<p><br></p>')

const CommunityAboutEdit = ({ about, onDone, onCancel, expandAbout }: CommunityAboutEditProps) => {
  const { communityId } = useCommunity()
  const [pendingAbout, setPendingAbout] = useState(about)

  const [expandChecked, setExpandChecked] = useState(expandAbout)

  const { isCondensed } = useWindowSize()

  const resetPendingAbout = useCallback(() => {
    if (about != emptyEditorString) {
      setPendingAbout(about)
    } else {
      setPendingAbout(null)
    }
  }, [about])

  const [updateAbout] = useMutation(EditCommunityDocument)
  const doUpdateAbout = async () => {
    let newAbout: Maybe<HtmlOrStringValue> = null
    if (pendingAbout != emptyEditorString) {
      newAbout = pendingAbout ?? null
    }
    await updateAbout({
      variables: {
        about: asString(newAbout),
        communityId: communityId ?? '',
        expandAbout: expandChecked,
      },
    })
    onDone(expandChecked)
  }

  return (
    <div className="about-editor">
      <QuillEditor<Maybe<Html>>
        toolbar={QuillToolbar.Limited}
        placeholder="Add a Description"
        initialHtml={pendingAbout}
        setHtml={setPendingAbout}
      />
      <div className={`button-row${isCondensed ? ' condensed' : ''}`}>
        <div className={'checkbox-container'}>
          <input
            id="sync-checkbox"
            type="checkbox"
            checked={expandChecked}
            onChange={() => {
              setExpandChecked(!expandChecked)
            }}
            role={'expand-checkbox'}
          />
          <label htmlFor={'sync-checkbox'}>Keep this section expanded</label>
        </div>
        <div>
          <Button
            variant="light"
            onClick={() => {
              resetPendingAbout()
              onCancel()
            }}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button onClick={() => doUpdateAbout()}>Save</Button>
        </div>
      </div>
    </div>
  )
}

export default CommunityAboutEdit
