import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  AddPostViewDocument,
  CanAddContentDocument,
  CanAddContentQuery,
  EditContentDocument,
  EditPostDocument,
  GetContentsDocument,
  GetContentsQuery,
  GetFollowedPostsDocument,
  GetFollowedPostsQuery,
  GetNewCommentsDocument,
  GetPostBodyDocument,
  GetPostsDocument,
  GetPostsQuery,
  GetPublicCommentsDocument,
  GetPublicCommentsQuery,
  GetVeevaDiscussionDocument,
  GetVeevaDiscussionQuery,
  MarkViewedForPostDocument,
  MediaType,
  Meetup,
  NewCommentAlertDocument,
  Post,
  PostBodyUserDocument,
  PostType,
  SetHidePostDocument,
  SetPostFollowDocument,
  SubscriptionInfo,
} from '~/api/generated/graphql'
import { MediaComponent } from '~/pages/posts/MediaComponent'
import { CommentAdd } from '~/pages/posts/CommentAdd'
import ToastComponent from '~/common/ToastComponent'
import { PostLikes } from '~/pages/posts/Likes'
import { CommentRow } from '~/pages/posts/CommentRow'
import { CommentEdit } from '~/pages/posts/CommentEdit'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { asGQL, checkConfidentialWarning, getCommentsNotificationInfo } from '~/utils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useAuth } from '~/auth/Auth'
import { Button, Modal } from 'react-bootstrap'
import PostStory from '~/pages/posts/PostStory'
import { useApolloClient, useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client'
import { elementClicked } from '~/common/EventLogger'
import PostConfirmationModal from '~/pages/posts/PostConfirmationModal'
import {
  updateCacheContent,
  updateCachePosts,
  updateCommunityPostFollow,
  updateFeedPostFollow,
  usePermissions,
} from '~/pages/posts/PostUtils'
import { useNavigate } from 'react-router'
import { useCommunity } from '~/contexts/CommunityContext'
import { CommentLoadingMediaProvider, useCommentLoadingMedia } from '~/contexts/CommentLoadingMediaContext'
import { useShortcuts } from '~/contexts/ShortcutContext'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'
import { UnsavedWarningModal } from '~/common/UnsavedWarningModal'
import { useNewActivity } from '~/contexts/NewActivityContext'
import { usePageVisibility } from '~/contexts/PageVisibilityContext'

type Props = {
  postId: string
  isSinglePost?: boolean
  isRepostPage?: boolean
  scrollToComment?: string
  defaultToVeevaDiscussion?: boolean
  onClickEditDraft?: () => void
  onClickDeleteDraft?: () => void
  collapsePost?: () => void
  postListPath?: string
}

export const PostBody = ({
  postId,
  isSinglePost,
  isRepostPage,
  scrollToComment,
  defaultToVeevaDiscussion,
  onClickEditDraft,
  onClickDeleteDraft,
  collapsePost,
  postListPath,
}: Props) => {
  const { profileVisible, isVeevan, authUserId, actingSysAdmin } = useAuth()

  const [editingComment, setEditingComment] = useState<string | null>()
  const [showToast, setShowToast] = useState(false)
  const { communityId } = useCommunity()
  const [addPostViewMutation] = useMutation(AddPostViewDocument)
  const [markNotificationForPostViewed] = useMutation(MarkViewedForPostDocument)
  const { data: postData, loading } = useQuery(GetPostBodyDocument, { variables: { id: postId } })
  const { setNewCommentIds } = useNewActivity()

  const { data: discussionData, loading: discussionLoading } = useQuery(GetVeevaDiscussionDocument, {
    variables: { id: postId },
    skip: !isVeevan,
    fetchPolicy: 'cache-and-network',
  })

  const { data: publicCommentsData, loading: publicCommentsLoading } = useQuery(GetPublicCommentsDocument, {
    variables: { id: postId },
    fetchPolicy: 'cache-and-network',
  })

  const [getNewComments] = useLazyQuery(GetNewCommentsDocument, { fetchPolicy: 'network-only' })
  const [newComments, setNewComments] = useState<SubscriptionInfo[]>([])
  const client = useApolloClient()

  const { pageIsVisible } = usePageVisibility()
  const { data: discussionsOnVisibility } = useQuery(GetVeevaDiscussionDocument, {
    variables: { id: postId },
    skip: !isVeevan || !pageIsVisible,
    fetchPolicy: 'no-cache',
  })

  const onFetchDiscussionsData = useCallback(
    (resp: GetVeevaDiscussionQuery) => {
      const fetchedComments = resp?.discussionComments?.comments?.map(c => c?.commentId ?? '') ?? []
      const existingComments = discussionData?.discussionComments?.comments?.map(c => c?.commentId ?? '') ?? []
      const newComments = fetchedComments.filter(c => !existingComments?.includes(c))
      if (newComments.length > 0) {
        setNewCommentIds?.(c => {
          return [...c, ...newComments]
        })
        setNewComments(c => {
          return [
            ...c,
            ...newComments.map(newCommentId => {
              return {
                objId: newCommentId,
                parentId: postId,
                isVeevaDiscussion: true,
              } as SubscriptionInfo
            }),
          ]
        })
      }
    },
    [discussionData?.discussionComments?.comments, postId, setNewCommentIds]
  )

  const onFetchPublicCommentsData = useCallback(
    (resp: GetPublicCommentsQuery) => {
      const fetchedComments = resp?.publicComments?.comments?.map(c => c?.commentId ?? '') ?? []
      const existingComments = publicCommentsData?.publicComments?.comments?.map(c => c?.commentId ?? '') ?? []
      const newComments = fetchedComments.filter(c => !existingComments?.includes(c))
      if (newComments.length > 0) {
        setNewCommentIds?.(c => {
          return [...c, ...newComments]
        })
        setNewComments(c => {
          return [
            ...c,
            ...newComments.map(newCommentId => {
              return {
                objId: newCommentId,
                parentId: postId,
              } as SubscriptionInfo
            }),
          ]
        })
      }
    },
    [postId, publicCommentsData?.publicComments?.comments, setNewCommentIds]
  )

  const { data: publicCommentsOnVisibility } = useQuery(GetPublicCommentsDocument, {
    variables: { id: postId },
    fetchPolicy: 'no-cache',
    skip: !isVeevan || !pageIsVisible,
  })

  useEffect(() => {
    if (discussionsOnVisibility) {
      onFetchDiscussionsData(discussionsOnVisibility)
    }
    if (publicCommentsOnVisibility) {
      onFetchPublicCommentsData(publicCommentsOnVisibility)
    }
  }, [discussionsOnVisibility, onFetchDiscussionsData, onFetchPublicCommentsData, publicCommentsOnVisibility])

  useSubscription(NewCommentAlertDocument, {
    variables: { postId: postId },
    onData: ({ data }) => {
      const alertData = data?.data?.newCommentAlert
      if (data.data && alertData) {
        setNewComments(origList => {
          origList.push(alertData)
          // spread the list so that dependencies update
          return [...origList]
        })
        setNewCommentIds?.(origList => {
          origList.push(alertData?.objId)
          return [...origList]
        })
      }
    },
    skip: !isVeevan || !pageIsVisible,
  })

  // we are subscribed to all new comments which means we will receive data for comments that were just created
  // by the context user. To combat this, filter out all comments that we already know about from the new comments
  const filteredNewComments = useMemo(() => {
    const discussionIds = new Set(
      discussionData?.discussionComments?.comments?.map(c => c?.commentId).filter(Boolean) as string[]
    )
    const publicIds = new Set(
      publicCommentsData?.publicComments?.comments?.map(c => c?.commentId).filter(Boolean) as string[]
    )

    return newComments.filter(c => !discussionIds.has(c.objId) && !publicIds.has(c.objId))
  }, [newComments, discussionData, publicCommentsData])

  const { post } = postData ?? {}
  const { canEdit } = usePermissions(post?.createdById, postId)
  const { setSelectedPost } = useShortcuts()

  const [focus, setFocus] = useState<boolean>(false)

  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [viewedDiscussion, setViewedDiscussion] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState('')
  const [showConfidentialWarning, setShowConfidentialWarning] = useState<boolean>(false)
  const [loadedComments, setLoadedComments] = useState<Set<string>>()
  const [confirmationMessage, setConfirmationMessage] = useState<string>()

  const comments = useMemo(() => {
    return publicCommentsData?.publicComments?.comments.filter(Boolean)
  }, [publicCommentsData?.publicComments?.comments])

  const discussion = useMemo(() => {
    return discussionData?.discussionComments?.comments.filter(Boolean)
  }, [discussionData?.discussionComments?.comments])

  const discussionCount = discussion ? discussion.length : 0
  const discussionStarted = discussionCount > 0
  const commentsCount = comments ? comments.length : 0
  const commentsStarted = commentsCount > 0

  // Default to Discussion tab if read only and there are only discussion tabs available or if coming from a discussion
  // comment link
  const [tab, setTab] = useState<string>(
    defaultToVeevaDiscussion || (!profileVisible && !commentsStarted && discussionStarted) ? 'Discussion' : 'Public'
  )

  const commentsToShow = tab == 'Public' ? comments : discussion
  const isDiscussionTab = tab == 'Discussion'
  const countText = 'Veeva Discussion' + (discussionStarted ? ' (' + discussionCount + ')' : '')
  const discussionTabText = !discussionStarted && tab == 'Public' ? 'Start Veeva Discussion' : countText

  const likes = useMemo(() => {
    return post?.likes?.edges.map(e => e?.node?.userId).filter(Boolean) as string[] | undefined
  }, [post?.likes])

  const { breakpoint, isCondensedPortrait: isMobile } = useWindowSize()
  const isMediumOrSmaller = breakpoint <= SizeBreakpoint.md

  const isRepost = post?.isRepost
  const repost = isRepost ? ' repost' : ''

  const { data: repostAuthorData, loading: repostAuthorLoading } = useQuery(PostBodyUserDocument, {
    variables: { id: post?.repost?.createdById ?? '' },
    skip: !post?.repost?.createdById,
  })
  useEffect(() => {
    addPostViewMutation({ variables: { id: postId ?? '' } }).then()
  }, [addPostViewMutation, postId])

  // Separated out to only use relevant parts of comments in the useDeepCompareEffect
  const commentsList = getCommentsNotificationInfo(
    publicCommentsData?.publicComments?.comments?.map(c => ({
      commentId: c?.commentId ?? '',
      likes: c?.likes?.edges.map(l => l?.node?.userId).filter(Boolean) as string[],
      authorId: c?.createdById ?? '',
    })) ?? []
  )

  // useEffect uses referential equality, but in the case of the comment and like arrays, we only want to compare the values with a deep compare
  useDeepCompareEffect(() => {
    if (post?.postId) {
      markNotificationForPostViewed({
        variables: {
          post: {
            id: post?.postId ?? '',
            likers: likes,
            mentioners: [post?.createdById ?? null],
            allMentioners: [post?.createdById ?? null],
          },
          comments: commentsList,
        },
      }).then()
    }
  }, [markNotificationForPostViewed, post?.postId, post?.createdById, post?.likes, commentsList])

  const handleDeleteComment = (id: string) => {
    setShowToast(true)
    setToastMessage('The comment was deleted successfully.')
    setNewComments(origList => {
      return [...origList].filter(i => i.objId != id)
    })
    setNewCommentIds?.(origList => {
      return [...origList].filter(i => i != id)
    })
  }
  const discussionTabRef = useClickOnEnter<HTMLDivElement>()
  const commentTabRef = useClickOnEnter<HTMLDivElement>()

  const handleCommentEdit = () => {
    setEditingComment(null)
  }

  const clickDiscussionTab = () => {
    setTab('Discussion')
    setViewedDiscussion(true)
    if (!discussionStarted) {
      setFocus(true)
    }
  }

  const inlineImages = document.getElementsByClassName('ql-full-image')
  for (let i = 0; i < inlineImages.length; i++) {
    inlineImages[i].setAttribute('target', '_blank')
  }

  const canViewTabs =
    ((isVeevan && profileVisible) || (isVeevan && ((comments && comments?.length > 0) || discussionStarted))) &&
    !isRepostPage

  const followed = post?.isFollowing ?? false

  const [setPostFollowMutation] = useMutation(SetPostFollowDocument, {
    update(cache, { data }) {
      const oldPosts = cache.readQuery<GetFollowedPostsQuery>({
        query: GetFollowedPostsDocument,
        variables: { communityId: post?.communityId, userId: authUserId ?? '' },
      })
      const edges = data?.setPostFollow?.post?.isFollowing
        ? [{ node: data?.setPostFollow?.post, typename: 'PostEdge' }, ...(oldPosts?.posts?.edges ?? [])]
        : [...(oldPosts?.posts?.edges.filter(e => e?.node?.postId != data?.setPostFollow?.post?.postId) ?? [])]
      const newPostsData = {
        posts: {
          ...oldPosts?.posts,
          pageInfo: { ...oldPosts?.posts?.pageInfo, hasNextPage: oldPosts?.posts?.pageInfo.hasNextPage ?? false },
          edges,
        },
      }
      if (post) {
        client.writeQuery({
          query: GetFollowedPostsDocument,
          variables: { communityId: post.communityId },
          data: newPostsData,
        })
      }
    },
  })

  const handleClickFollow = async (e: SyntheticEvent) => {
    const post = asGQL('Post', {
      postId: postId,
      isFollowing: !followed,
      draft: postData?.post?.draft,
      createdTime: postData?.post?.createdTime,
      lastActivityTime: postData?.post?.lastActivityTime,
      hidden: postData?.post?.hidden,
    })

    const resp = await setPostFollowMutation({
      variables: { id: postId, value: !followed },
      optimisticResponse: {
        setPostFollow: {
          __typename: 'SetPostFollow' as const,
          ok: true,
          error: {},
          post,
        },
      },
      update(cache, { data }) {
        communityId
          ? updateCommunityPostFollow(cache, client, authUserId, data?.setPostFollow?.post as Post, communityId)
          : updateFeedPostFollow(cache, client, data?.setPostFollow?.post as Post)
      },
    })

    if (!followed) {
      elementClicked(e, 'click-post-follow', {
        post_id: postId,
      })
      setShowToast(true)
      if (resp?.data?.setPostFollow?.ok) {
        setToastMessage('Post added to Followed Posts')
      }
    } else {
      elementClicked(e, 'click-post-unfollow', {
        post_id: postId,
      })
    }
  }

  const [editPost] = useMutation(EditPostDocument, {
    update(cache, { data }) {
      updateCachePosts(cache, client, authUserId, data?.editPost?.post as Post)
      if (data?.editPost?.post?.postType === PostType.Content) {
        updateCacheContent(cache, client, communityId, authUserId, data?.editPost?.post as Post)
      }
    },
  })
  const [editContent] = useMutation(EditContentDocument, {
    update(cache, { data }) {
      updateCachePosts(cache, client, authUserId, data?.editPost?.post as Post)
      if (data?.editPost?.post?.postType === PostType.Content) {
        updateCacheContent(cache, client, communityId, authUserId, data?.editPost?.post as Post)
      }
    },
  })
  const doPost = async (e: SyntheticEvent, skipWarning: boolean, warned?: boolean) => {
    elementClicked(e, 'click-post-add', { community_id: post?.communityId })
    if (
      !skipWarning &&
      !isVeevan &&
      (checkConfidentialWarning(post?.story?.htmlWithMentions) ||
        post?.mediaType ||
        post?.mediaUrl ||
        (post?.media?.length ?? 0) > 0)
    ) {
      setShowConfidentialWarning(true)
      return
    }
    if (post?.postType === PostType.Content) {
      const response = await editContent({
        variables: {
          post_id: postId,
          content_title: post?.contentTitle,
          draft: false,
          warned: warned,
        },
      })
      if (response.data?.editPost?.error?.code === 'sensitiveContent') {
        setShowConfidentialWarning(true)
        setConfirmationMessage(response.data?.editPost?.error?.message ?? undefined)
      } else if (response.data?.editPost?.error) {
        setShowToast(true)
        setToastMessage(response.data?.editPost?.error.message ?? '')
      } else {
        collapsePost?.()
      }
    } else {
      const response = await editPost({ variables: { post_id: postId, draft: false, warned: warned } })
      if (response.data?.editPost?.error?.code === 'sensitiveContent') {
        setShowConfidentialWarning(true)
        setConfirmationMessage(response.data?.editPost?.error?.message ?? undefined)
      } else {
        collapsePost?.()
      }
    }
  }

  const [hidePost] = useMutation(SetHidePostDocument, {
    update(cache, { data }) {
      const oldCount = cache.readQuery<CanAddContentQuery>({
        query: CanAddContentDocument,
        variables: { communityId: post?.communityId ?? '' },
      })

      const oldData = oldCount?.canAddContent
      const newCount = (oldData?.count ?? 0) - (data?.setHidePost?.post?.draft ? 0 : 1)

      const newCountData = {
        canAddContent: {
          ok: true,
          count: newCount,
          max: oldData?.max ?? 42,
          __typename: 'ContentValidator' as const,
        },
      }

      client.writeQuery({
        query: CanAddContentDocument,
        variables: { communityId: post?.communityId ?? '' },
        data: newCountData,
      })

      const oldPostsData = cache.readQuery<GetPostsQuery>({
        query: GetPostsDocument,
        variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
      })

      const newPostsData = {
        posts: {
          ...oldPostsData?.posts,
          pageInfo: {
            ...oldPostsData?.posts?.pageInfo,
            hasNextPage: oldPostsData?.posts?.pageInfo.hasNextPage ?? false,
          },
          edges: [
            ...(oldPostsData?.posts?.edges.filter(
              // hidden posts should still show up for admin
              n => actingSysAdmin || n?.node?.postId != data?.setHidePost?.post?.postId
            ) ?? []),
          ],
        },
      }

      client.writeQuery({
        query: GetPostsDocument,
        variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
        data: newPostsData,
      })

      if (post?.contentTitle) {
        const oldContentData = cache.readQuery<GetContentsQuery>({
          query: GetContentsDocument,
          variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
        })

        const newContentData = {
          posts: {
            ...oldContentData?.posts,
            edges: [
              ...(oldContentData?.posts?.edges.filter(
                // hidden posts should still show up for admin
                n => actingSysAdmin || n?.node?.postId != data?.setHidePost?.post?.postId
              ) ?? []),
            ],
          },
        }

        client.writeQuery({
          query: GetContentsDocument,
          variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
          data: newContentData,
        })
      }
    },
  })

  const handleCancelDelete = () => setShowDeleteDialog(false)
  const navigate = useNavigate()

  const handleConfirmDelete = async (e: SyntheticEvent) => {
    setDeleteDisabled(true)
    await hidePost({ variables: { id: postId, value: true } })
    onClickDeleteDraft?.()
    setDeleteDisabled(false)
    setShowDeleteDialog(false)
    elementClicked(e, 'click-post-draft-delete', {
      postId: postId,
    })
    if (isSinglePost && postListPath) {
      navigate(postListPath)
    }
  }

  // when we scroll to a comment we need to wait for the media in the post story to finish loading first
  const postStoryRowCount = useMemo(() => {
    let count = 0
    if (post?.story?.htmlWithMentions) {
      try {
        count = (JSON.parse(post?.story?.htmlWithMentions) as string[]).length
      } catch {
        count = 1 // we have the story in the "old" format where we store a string rather than an array of strings
      }

      if (post?.mediaUrl) {
        count++
      }
    }
    return count
  }, [post?.mediaUrl, post?.story?.htmlWithMentions])
  const { mediaLoaded: postMediaLoaded } = useCommentLoadingMedia()
  const commentsLoading =
    publicCommentsLoading ||
    discussionLoading ||
    loadedComments?.size != commentsToShow?.length ||
    postMediaLoaded?.size != postStoryRowCount

  const FollowButton = () => {
    return (
      <div className={`follow-btn-container`}>
        <button
          className={`btn-plain follow-button ${followed ? 'followed' : ''} ${post?.hidden ? 'hidden' : ''}`}
          onClick={handleClickFollow}
          disabled={post?.hidden}
          data-testid={'follow-post-button'}
        >
          <div className={`btn-title`} />
          <span>{followed ? 'Followed' : 'Follow'}</span>
        </button>
      </div>
    )
  }

  const { checkDraftingComment, setDraftingComment } = useDraftingCommentPost()
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [pendingEditingComment, setPendingEditingComment] = useState('')

  const handleClickCommentEdit = (commentId: string) => {
    if (checkDraftingComment?.(postId, true)) {
      setPendingEditingComment(commentId)
      setShowWarningModal(true)
    } else {
      setEditingComment(commentId)
    }
  }

  const confirmEditComment = () => {
    setDraftingComment?.(editingComment ?? '', false)
    setEditingComment(pendingEditingComment)
    setPendingEditingComment('')
    setShowWarningModal(false)
  }

  const newCommentsMessage = useMemo(() => {
    if (!filteredNewComments.length) {
      return ''
    } else if (filteredNewComments.every(c => c.isVeevaDiscussion)) {
      return 'Show new Veeva Discussions'
    } else if (filteredNewComments.some(c => c.isVeevaDiscussion)) {
      return 'Show new comments and Veeva Discussions'
    } else {
      return 'Show new comments'
    }
  }, [filteredNewComments])

  // If we switch between tabs, we want to scroll down to see the latest comment
  const [lastLoadedComment, setLastLoadedComment] = useState('')

  const lastNewCommentId = useMemo(() => {
    // we're switching tabs, so we need to find the id to scroll to
    if (filteredNewComments.length) {
      if (filteredNewComments.every(c => c.isVeevaDiscussion) || filteredNewComments.every(c => !c.isVeevaDiscussion)) {
        return filteredNewComments[filteredNewComments.length - 1].objId
      } else if (tab == 'Public') {
        return filteredNewComments.find(c => !c.isVeevaDiscussion)?.objId ?? ''
      } else {
        return filteredNewComments.find(c => c.isVeevaDiscussion)?.objId ?? ''
      }
    }

    return '' // this means we don't need to switch tabs, therefore don't need to scroll
  }, [filteredNewComments, tab])

  const updateCommentsCache = useCallback(async () => {
    const commentIds = filteredNewComments.map(c => c.objId)
    const commentData = await getNewComments({
      variables: { commentIds },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setLastLoadedComment(lastNewCommentId)
      },
    })

    const newDiscussionComments = [...(discussionData?.discussionComments?.comments ?? [])]
    const newPublicComments = [...(publicCommentsData?.publicComments?.comments ?? [])]
    let hasNewPublic = false
    let hasNewDiscussion = false
    commentData?.data?.comments?.edges?.forEach(newComment => {
      const comment = newComment?.node

      if (comment?.isVeevanDiscussion) {
        hasNewDiscussion = true
        newDiscussionComments.push(comment)
      } else if (comment) {
        hasNewPublic = true
        newPublicComments.push(comment)
      }
    })

    if (hasNewDiscussion) {
      const newDiscussionData = {
        ...discussionData,
        discussionComments: {
          ...discussionData?.discussionComments,
          id: `discussion:${postId}`,
          comments: newDiscussionComments,
        },
      }
      client.writeQuery({ query: GetVeevaDiscussionDocument, variables: { id: postId }, data: newDiscussionData })
    }

    if (hasNewPublic) {
      const newPublicData = {
        ...publicCommentsData,
        publicComments: {
          ...publicCommentsData?.publicComments,
          id: `public:${postId}`,
          comments: newPublicComments,
        },
      }
      client.writeQuery({ query: GetPublicCommentsDocument, variables: { id: postId }, data: newPublicData })
    }

    if (filteredNewComments.every(c => c.isVeevaDiscussion)) {
      setTab('Discussion')
    } else if (filteredNewComments.every(c => !c.isVeevaDiscussion)) {
      setTab('Public')
    }

    setViewedDiscussion(filteredNewComments.some(c => c.isVeevaDiscussion)) // prevent discussion warning pop up if in the middle of commenting
    setNewComments([])
  }, [discussionData, client, postId, publicCommentsData, filteredNewComments, getNewComments, lastNewCommentId])

  const showDiscussionTab = discussionData || !discussionLoading
  if (loading || (!repostAuthorData && repostAuthorLoading)) return null

  return (
    <>
      {!post?.draft ? (
        <>
          <div
            className={`post-subitems ${post?.hidden ? 'hidden' : ''}`}
            onClick={() => setSelectedPost?.({ postId, expanded: true })}
          >
            <div
              className={`post-body${
                (!comments?.length && tab == 'Public') || (!discussion?.length && isDiscussionTab) ? ' no-comments' : ''
              }${isMediumOrSmaller ? ' condensed' : ''} ${repost} ${isMobile ? 'mobile' : ''} ${
                canViewTabs || isRepostPage ? '' : 'no-tabs'
              }`}
            >
              {post?.mediaType && (
                <div className={'media-holder'}>
                  <div className={'media-item'}>
                    <MediaComponent
                      media_type={post?.mediaType}
                      url={post?.mediaUrl ?? undefined}
                      content_title={post?.mediaName ?? undefined}
                      post_id={postId}
                      index={-1}
                    />
                  </div>
                </div>
              )}
              {
                <PostStory
                  media={post?.media ?? []}
                  story={post?.story}
                  hasPrimaryMedia={Boolean(post?.mediaUrl)}
                  meetup={post?.meetup as Meetup}
                  postId={post?.postId}
                  repostId={post?.repostId}
                  primaryVideoUrl={post?.mediaType === MediaType.Video ? post?.mediaUrl : null}
                />
              }
              {profileVisible && !isRepostPage && (
                <div className={`post-options${isMediumOrSmaller ? ' condensed' : ''}`}>
                  {FollowButton()}
                  <PostLikes id={postId} hidden={post?.hidden ?? false} />
                </div>
              )}
            </div>
            {canViewTabs && (
              <div className={`comment-tabs${isMediumOrSmaller ? ' condensed' : ''}`}>
                <div
                  className={`${tab == 'Public' ? 'selected' : ''}`}
                  onClick={() => {
                    setTab('Public')
                  }}
                  hidden={!profileVisible && !commentsStarted}
                  tabIndex={0}
                  ref={commentTabRef}
                >
                  Public Comments
                </div>
                {showDiscussionTab && (
                  <div
                    className={`${isDiscussionTab ? 'selected ' : ''}${
                      !discussionStarted && tab == 'Public' ? 'no-discussion ' : ''
                    }discussion-tab`}
                    onClick={clickDiscussionTab}
                    ref={discussionTabRef}
                    hidden={!profileVisible && !discussionStarted}
                    tabIndex={0}
                  >
                    {discussionTabText}
                  </div>
                )}
                <div className={'border-right'}></div>
              </div>
            )}
            {isVeevan && isDiscussionTab && (
              <div className={`discussion-visibility${isMediumOrSmaller ? ' condensed' : ''}`}>
                <i tabIndex={0}>Visible only to Veeva Users</i>
              </div>
            )}
            {!isRepostPage && (
              <div
                className={`comment-list${
                  (!comments?.length && tab == 'Public') || (!discussion?.length && isDiscussionTab)
                    ? ' no-comments'
                    : isDiscussionTab
                      ? ' discussion-list'
                      : ''
                }${isMediumOrSmaller ? ' condensed' : ''} ${isMobile ? 'mobile' : ''}`}
              >
                {commentsLoading && <div hidden={true} data-testid={`loading-comments-${postId}`} />}
                {commentsToShow?.map(c =>
                  c?.commentId === editingComment ? (
                    <CommentEdit
                      key={'editComment_' + c?.commentId}
                      commentId={c?.commentId ?? ''}
                      onDone={handleCommentEdit}
                      communityId={post?.communityId ?? ''}
                    />
                  ) : (
                    <CommentLoadingMediaProvider setLoadedComments={setLoadedComments} key={c?.commentId}>
                      <CommentRow
                        scrollToComment={scrollToComment == c?.commentId && !commentsLoading}
                        commentId={c?.commentId ?? ''}
                        handleDelete={handleDeleteComment}
                        onClickEdit={() => handleClickCommentEdit(c?.commentId ?? '')}
                        isOnSinglePost={isSinglePost}
                        isVeevanDiscussion={c?.isVeevanDiscussion}
                        postId={postId}
                        postHidden={post?.hidden ?? false}
                        lastLoadedComment={lastLoadedComment}
                        setLastLoadedComment={setLastLoadedComment}
                      />
                    </CommentLoadingMediaProvider>
                  )
                )}
              </div>
            )}
          </div>
          {!isRepostPage && (
            // Regular comment add box
            <CommentAdd
              postId={postId}
              communityId={post?.communityId ?? ''}
              discussionTab={false}
              discussionStarted={discussionStarted}
              setTab={setTab}
              hidden={isDiscussionTab}
              setFocus={setFocus}
              setShowModal={setShowModal}
              className={!commentsStarted ? 'no-public-comments' : ''}
              newCommentsMessage={newCommentsMessage}
              onLoadNewComments={updateCommentsCache}
            />
          )}{' '}
          {!isRepostPage && (
            // Veeva discussion add box
            <CommentAdd
              postId={postId}
              communityId={post?.communityId ?? ''}
              discussionTab={true}
              discussionStarted={discussionStarted}
              setTab={setTab}
              hidden={!isDiscussionTab}
              setFocus={setFocus}
              focus={focus}
              className={!commentsStarted ? 'no-public-comments' : ''}
              newCommentsMessage={newCommentsMessage}
              onLoadNewComments={updateCommentsCache}
            />
          )}{' '}
        </>
      ) : (
        <div className={'draft-subitems'}>
          <div className={'post-subitems'} onClick={() => setSelectedPost?.({ postId, expanded: true })}>
            {post?.mediaType && (
              <div className={'media-holder'}>
                <div className={'media-item'}>
                  <MediaComponent
                    media_type={post?.mediaType}
                    url={post?.mediaUrl ?? undefined}
                    content_title={post?.mediaName ?? undefined}
                    post_id={postId}
                  />
                </div>
              </div>
            )}
            {
              <PostStory
                media={post?.media ?? []}
                story={post?.story}
                meetup={post?.meetup as Meetup}
                postId={post?.postId}
                repostId={post?.repostId}
                primaryVideoUrl={post?.mediaType === MediaType.Video ? post?.mediaUrl : null}
              />
            }
          </div>
          <div className={'divider-line'}></div>
          <div className={'button-container'}>
            <div className={'button-zone'}>
              <Button
                onClick={() => onClickEditDraft?.()}
                variant="outline-secondary"
                disabled={!canEdit}
                className={'edit-draft'}
                role="button"
                data-testid={'edit-draft-btn'}
              >
                <span>Edit Draft</span>
              </Button>
              <Button
                onClick={e => {
                  e.currentTarget.blur()
                  setShowDeleteDialog(true)
                }}
                variant="light"
                disabled={!canEdit}
                className={'delete-draft'}
                role="button"
                data-testid={'delete-draft-btn'}
              >
                <span>Delete Draft</span>
              </Button>
            </div>
            <div className={'button-zone'}>
              <Button size="sm" disabled={!canEdit} onClick={e => doPost(e, false)} data-testid={'post-btn'}>
                Post
              </Button>
            </div>
          </div>
        </div>
      )}
      {isVeevan && tab == 'Public' && discussion && discussion[discussionCount - 1]?.createdById != authUserId && (
        <Modal
          show={showModal && !viewedDiscussion && discussionStarted}
          className={'discussion-modal'}
          data-testid={'discussion-modal'}
        >
          <Modal.Body>
            <p className={'message'}>There's a Veeva discussion on this post. Please take a look before commenting.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                setShowModal(false)
                setTab('Discussion')
                setViewedDiscussion(true)
              }}
            >
              <i>View Veeva-only comments on this post {'>'}</i>
            </Button>
            <Button
              variant="orange"
              size="sm"
              onClick={() => {
                setShowModal(false)
                setViewedDiscussion(true)
              }}
              className={'btn-orange'}
            >
              It's ok. I already did.
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={showDeleteDialog} onHide={handleCancelDelete} className={'delete'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>Are you sure you want to delete this draft?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={handleConfirmDelete} disabled={deleteDisabled}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastComponent onClose={() => setShowToast(false)} show={showToast} bg={'success'}>
        {toastMessage}
      </ToastComponent>
      <PostConfirmationModal
        show={showConfidentialWarning}
        hide={() => {
          setShowConfidentialWarning(false)
        }}
        onSubmit={e => doPost(e, true, true)}
        submitText={'Post'}
        message={confirmationMessage}
      />
      <UnsavedWarningModal
        showWarningModal={showWarningModal}
        onCancel={() => setShowWarningModal(false)}
        onContinue={confirmEditComment}
        leavingPost={false}
      />
    </>
  )
}
