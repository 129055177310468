import React, { MutableRefObject, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'quill-mention/dist/quill.mention.css'
import '@css/common/QuillEditor.scss'
import 'quill-mention'
import MagicUrl from 'quill-magic-url'
import type { EmitterSource as Sources, Range as RangeStatic } from 'quill'
import Quill from 'quill'
import Link from 'quill/formats/link'
import Delta, { Op } from 'quill-delta'
import { asHtml, asHtmlWithMentions, asString, debounce, HtmlOrStringValue, LINK_REGEX } from '~/utils'
import { getHtmlLength, getNodeText } from '~/pages/posts/PostUtils'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import * as HTMLReactParser from 'html-react-parser'
import { useAuth } from '~/auth/Auth'
import { PlainClipboard } from '~/common/quill/PlainClipboard'
import MentionsService from '~/common/quill/MentionService'
import { SupportGBoard } from '~/common/quill/SupportGBoard'
import {
  CacheUserFragment,
  GetQuillCommunityDocument,
  Maybe,
  MeetupInput,
  SearchUsersDocument,
  ValidateMeetupDocument,
  ValidateMeetupQuery,
} from '~/api/generated/graphql'
import { MSWORD_MATCHERS } from '~/common/quill/MSWordMatchers'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { isMacOs } from 'react-device-detect'
import MeetupBlot from '~/common/quill/MeetupBlot'
import ToastComponent from '~/common/ToastComponent'
import template from 'template/suggestion.hbs'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'
import LinkModal, { padDigit } from '~/common/quill/LinkModal'
import { usePostThreadIds } from '~/common/hooks/usePostThreadIds'

ReactQuill.Quill.register('modules/magicUrl', MagicUrl)
ReactQuill.Quill.register('modules/clipboard', PlainClipboard, true)
ReactQuill.Quill.register('modules/supportGBoard', SupportGBoard)
ReactQuill.Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
ReactQuill.Quill.register(MeetupBlot)

// Override the destroyEditor function because it fails to unset the editor after destroying it
ReactQuill.prototype.destroyEditor = function () {
  if (!this.editor) return
  this.unhookEditor(this.editor)
  this.editor = undefined
}

export enum QuillToolbar {
  None,
  Limited,
  Full,
  PostStory,
  WrappedPostStory,
  Overview,
}

type Props<T extends Maybe<HtmlOrStringValue>> = {
  className?: string
  initialHtml?: T
  toolbar: QuillToolbar
  placeholder?: string
  onKeyDown?: () => void
  setHtml?: (value: T) => void
  setTextLength?: (value: number) => void
  allowMentions?: boolean
  preventLineBreak?: boolean
  readOnly?: boolean
  communityId?: string
  postId?: string
  allowLinks?: boolean
  quillRef?: MutableRefObject<ReactQuill | null>
  maxLength?: number
  onFocus?: () => void
  onBlur?: () => void
  veevanOnlyMentions?: boolean
  addPastedImage?: (file: File, index: number) => void
  rowIndex?: number
  onFullMediaClick?: () => void
  onRightMediaClick?: () => void
  onLeftMediaClick?: () => void
  onSubmit?: (args: {
    skipWarning?: boolean
    warned?: boolean
    e?: SyntheticEvent | KeyboardEvent
    meetup?: MeetupInput
  }) => Promise<void>
  hasMeetup?: boolean
  updateQuillRef?: (ref: ReactQuill) => void
  setMeetup?: (meetup: MeetupInput) => void
  isTitle?: boolean
  allowVideoTimestamps?: boolean
  primaryVideoUrl?: Maybe<string>
}

export type Suggestion = {
  community?: {
    communityId?: Maybe<string>
    companyId?: Maybe<string>
    name?: Maybe<string>
  }
  id: Maybe<string>
  link: Maybe<string>
  photo: Maybe<string>
  user?: {
    firstName?: Maybe<string>
    lastName?: Maybe<string>
    nickName?: Maybe<string>
    userId?: Maybe<string>
    title?: Maybe<string>
    company?: Maybe<{
      name?: Maybe<string>
    }>
    hidden?: Maybe<boolean>
    isVeevan: boolean
    outOfOffice?: boolean
  }
  value: string
  isVeevanMentioner?: boolean
}

const QuillEditor = <T extends Maybe<HtmlOrStringValue>>({
  className,
  initialHtml,
  toolbar = QuillToolbar.Full,
  placeholder = 'Share an update with the company',
  onKeyDown,
  setHtml,
  setTextLength,
  allowMentions,
  preventLineBreak,
  readOnly,
  communityId,
  postId,
  allowLinks = true,
  quillRef,
  maxLength,
  onFocus,
  onBlur,
  veevanOnlyMentions,
  addPastedImage,
  rowIndex,
  onFullMediaClick,
  onRightMediaClick,
  onLeftMediaClick,
  onSubmit,
  hasMeetup,
  updateQuillRef,
  setMeetup,
  isTitle,
  allowVideoTimestamps,
  primaryVideoUrl,
}: Props<T>) => {
  const { cache } = useApolloClient()
  const { isVeevan, canEditIds } = useAuth()
  const localQuillRef = useRef<ReactQuill>(null)
  const { setDraftingPost } = useDraftingCommentPost()
  const [searchUsers] = useLazyQuery(SearchUsersDocument)
  const debouncedSearchUsers = useMemo(
    () =>
      debounce(async (query: string, numSuggestions: number, veevansOnly?: boolean) => {
        try {
          const usersResp = await searchUsers({
            variables: {
              query,
              threadCommunityId: communityId,
              pageSize: numSuggestions,
              isVeevan: veevansOnly ? true : undefined,
            },
          })
          return (usersResp?.data?.userSearch?.users.filter(Boolean) ?? []) as CacheUserFragment[]
        } catch (e) {
          // we can just ignore any errors in the response and only show the cache results
          console.log(e)
        }
      }, 300),
    [searchUsers, communityId]
  )

  const [originalHtml] = useState(initialHtml)

  const { data } = useQuery(GetQuillCommunityDocument, {
    variables: { id: communityId || '' },
    skip: !communityId,
  })
  const [validateMeetup, { loading: validateMeetupLoading, data: meetupData }] = useLazyQuery(ValidateMeetupDocument)

  // use a ref callback instead of a useRef because mutating the ref won't trigger any useEffects using it as a dependency
  const [ref, setRef] = useState<ReactQuill | null>(quillRef?.current ?? localQuillRef.current)
  const handleReactRef = useCallback(
    (node: ReactQuill) => {
      setRef(node)
      if (quillRef) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        quillRef.current = node
        updateQuillRef?.(node)
      }
    },
    [quillRef, updateQuillRef]
  )

  const isMeetupLink = (link: string) => {
    const namespace = JSON.parse(document.getElementById('data/NS')?.textContent ?? '""')
    if (namespace === 'prod') {
      return link.includes('veeva.zoom.us/j/')
    } else {
      return link.includes('zoom.us/j/')
    }
  }

  const memberIds = usePostThreadIds(postId ?? null)

  const [showLinkModal, setShowLinkModal] = useState<boolean>(false)
  const [displayText, setDisplayText] = useState<string>('')
  const [linkText, setLinkText] = useState<string>('')
  const [editingLink, setEditingLink] = useState<HTMLAnchorElement | null>(null)
  const [copyLinkText, setCopyLinkText] = useState<boolean>(true)
  const [linkIsMeetup, setLinkIsMeetup] = useState<boolean>(false)
  const [meetupLink, setMeetupLink] = useState<string>('')
  const [meetupLinkResult, setMeetupLinkResult] = useState<string>('')
  const [meetupId, setMeetupId] = useState<string>('')
  const [showLinkErrorToast, setShowLinkErrorToast] = useState(false)
  const [showHasMeetupError, setShowHasMeetupError] = useState(false)
  const [videoStartTime, setVideoStartTime] = useState<string>('')

  const setCustomLink = useCallback(() => {
    // Using the quill ref to get the selected text causes this callback to re-calculate whenever the quill ref updates
    // which re-generates the modules and creates some annoying side effects such as some of the keyboard bindings
    // being thrown out. Therefore, just use the window to get the highlighted string
    const selection = window.getSelection()?.toString()
    if (selection) {
      setDisplayText(selection)
      // auto populate the timestamp text if we detect that there's a timestamp in the selected text and the
      // user is allowed to add a timestamp
    }
    setShowLinkModal(true)
  }, [setDisplayText, setShowLinkModal])

  // Checks to see if we click on an existing link in the quill editor and grabs the corresponding values if the user chooses to edit the link.
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const anchorElements = Array.from(ref?.editor?.root.querySelectorAll('a') ?? [])
      const anchorIndex = anchorElements.indexOf(event.target as HTMLAnchorElement)
      if (anchorIndex > -1 && event.target !== ref?.editor?.root.querySelector('.ql-tooltip .ql-action')) {
        const target = event.target as HTMLAnchorElement
        const videoTimeMatch = (target.pathname + target.search).match(
          /\/communities\/[\w-]+\/posts\/(?<postId>[\w-]+)\?([\S]+)?t=?(?<hours>\d\d):(?<minutes>\d\d):(?<seconds>\d\d)/
        )
        if (videoTimeMatch) {
          // convert hh:mm:ss format back to mm:ss
          const totalMinutes =
            parseInt(videoTimeMatch?.groups?.hours ?? '0') * 60 + parseInt(videoTimeMatch?.groups?.minutes ?? '0')
          setVideoStartTime(`${padDigit(totalMinutes)}:${videoTimeMatch?.groups?.seconds}`)
        } else {
          setLinkText(target.href)
        }
        setDisplayText(target.text)
        setEditingLink(anchorElements[anchorIndex])
        setCopyLinkText(false)
      } else {
        setEditingLink(null)
      }
    }

    const area = ref?.editingAreaRef.current as Element

    const handleClickEditingArea = (event: Event) => {
      if (event.target === area.querySelector('.ql-tooltip .ql-action')) {
        setCustomLink()
      }
    }

    ref?.editor?.root.addEventListener('click', handleClick)
    area?.addEventListener('click', handleClickEditingArea)

    return () => {
      ref?.editor?.root.removeEventListener('click', handleClick)
      area?.removeEventListener('click', handleClickEditingArea)
    }
  }, [ref?.editingAreaRef, ref?.editor?.root, setCustomLink, setVideoStartTime])

  // Ensures that we don't lose the value of the highlighted selection
  const selection = useMemo(() => {
    return ref?.editor?.getSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, showLinkModal])
  const index = selection?.index ?? 0
  const length = selection?.length ?? 0

  const clearLinkFields = () => {
    setDisplayText('')
    setLinkText('')
    setEditingLink(null)
    setShowLinkModal(false)
    setLinkIsMeetup(false)
    setCopyLinkText(true)
    setMeetupLink('')
    setMeetupLinkResult('')
    setShowHasMeetupError(false)
    setVideoStartTime('')
  }

  const parseMeetup = (meetupData: ValidateMeetupQuery) => {
    const meetup = meetupData?.validateMeetup?.data
    const date = new Date(meetup?.startTime ?? '')
    date.setSeconds(0, 0)

    return { gid: meetup?.gid, startTime: date.toISOString().replace(/.000Z/, ''), link: meetup?.link }
  }

  const quill = ref?.editor ? ref?.getEditor() : null

  const handleLinkSubmit = useCallback(
    (videoTimestampStartTime?: string) => {
      if (linkIsMeetup) {
        const newContents: Op[] | undefined = quill
          ?.getContents()
          .ops?.filter(a => !(typeof a.insert === 'string') || !isMeetupLink(a.insert))
        if (newContents) {
          quill?.setContents(newContents as unknown as Delta)
        }
        quill?.insertEmbed(index, 'meetup', { meetupId: meetupId })
        meetupData && setMeetup?.(parseMeetup(meetupData))
        clearLinkFields()
      } else {
        ref?.focus()
        if (length > 0) {
          ref?.getEditor().deleteText(index, length)
        }
        const url = videoTimestampStartTime
          ? `${window.location.origin}/communities/${communityId}/posts/${postId}?t=${videoTimestampStartTime}`
          : linkText
        ref?.getEditor().insertText(index, displayText, 'link', url)
        clearLinkFields()
      }
    },
    [
      index,
      length,
      linkIsMeetup,
      meetupData,
      meetupId,
      quill,
      ref,
      setMeetup,
      displayText,
      linkText,
      communityId,
      postId,
    ]
  )

  // Editing pre-existing link in quill editor
  const handleLinkEdit = useCallback(
    (videoTimestampStartTime?: string) => {
      if (linkIsMeetup) {
        quill?.insertEmbed(index, 'meetup', { meetupId: meetupId })
        meetupData && setMeetup?.(parseMeetup(meetupData))
        const newContents: Op[] | undefined = quill
          ?.getContents()
          .ops?.filter(a => !(typeof a.insert === 'string') || !isMeetupLink(a.insert))
        if (newContents) {
          quill?.setContents(newContents as unknown as Delta)
        }
      } else if (editingLink) {
        if (videoTimestampStartTime) {
          editingLink.href = `${window.location.origin}/communities/${communityId}/posts/${postId}?t=${videoTimestampStartTime}`
        } else {
          editingLink.href = linkText
        }

        editingLink.text = displayText
      }
      clearLinkFields()
    },
    [
      editingLink,
      index,
      linkIsMeetup,
      meetupData,
      meetupId,
      quill,
      setMeetup,
      linkText,
      displayText,
      communityId,
      postId,
    ]
  )

  const imagePasteHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (imageDataUrl: string, type: string, imageData: any) => {
      // not using regular image data type because author provides some extra functions like toFile()
      const file = imageData.toFile()
      if (file) {
        addPastedImage?.(file, rowIndex ?? 0)
      }
    },
    [addPastedImage, rowIndex]
  )

  const handleCodeKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (displayText == '') {
      setCopyLinkText(true)
    } else if (ref?.editor?.getText(index, length) || editingLink) {
      setCopyLinkText(false)
    }

    if (event.key === 'Enter') {
      if (!(linkIsMeetup && (meetupReturnedError || validateMeetupLoading || showHasMeetupError))) {
        event.preventDefault()
        editingLink ? handleLinkEdit() : handleLinkSubmit()
      }
    }
  }

  const handleLinkChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLinkText(e.target.value)
      if (copyLinkText) {
        setDisplayText(e.target.value)
      }
      if (isMeetupLink(e.target.value) && isVeevan) {
        if (!hasMeetup) {
          setDisplayText('Click to register!')
          validateMeetup({ variables: { zoomLink: e.target.value ?? '' } }).then(r => {
            setMeetupLinkResult(r.data?.validateMeetup?.data?.link ?? '')
            setMeetupId(r.data?.validateMeetup?.data?.gid ?? '')
          })
          setLinkIsMeetup(true)
          setMeetupLink(e.target.value)
        } else {
          setShowHasMeetupError(true)
        }
      } else if (showHasMeetupError) {
        setShowHasMeetupError(false)
      }
    },
    [copyLinkText, isVeevan, showHasMeetupError, hasMeetup, validateMeetup]
  )

  const meetupReturnedError = Boolean(meetupData && (!meetupData?.validateMeetup || meetupData?.validateMeetup?.error))

  const handleMeetupPaste = useCallback(
    (node: Node, delta: Delta) => {
      const meetingMatches = isMeetupLink(node.textContent ?? '')

      if (
        toolbar === QuillToolbar.PostStory &&
        meetingMatches &&
        isVeevan &&
        !hasMeetup &&
        delta.ops?.some(v => v.insert)
      ) {
        setLinkIsMeetup(true)
        setDisplayText('Click to register!')
        setLinkText(node.textContent ?? '')
        setShowLinkModal(true)
        setCopyLinkText(false)
        setMeetupLink(node.textContent ?? '')
        validateMeetup({ variables: { zoomLink: node.textContent ?? '' } }).then(r => {
          if (r.data?.validateMeetup?.ok) {
            setMeetupLinkResult(r.data?.validateMeetup?.data?.link ?? '')
            setMeetupId(r.data?.validateMeetup.data?.gid ?? '')
          }
        })
        // undo issue where Quill for some reason attaches the color to the node which makes it appear as a link
        // even after the link is removed
        if (node.parentElement) node.parentElement.style.color = ''
        return delta
      } else if (toolbar === QuillToolbar.PostStory && meetingMatches && isVeevan && hasMeetup) {
        setShowLinkErrorToast(true)
      }
      return delta
    },
    [validateMeetup, hasMeetup, isVeevan, toolbar, setShowLinkErrorToast]
  )
  const modules = useMemo<Record<string, unknown>>(() => {
    // these are the queries to get the toolbar buttons in quill editor.
    const buttons = [
      '.ql-picker-label',
      '.ql-bold',
      '.ql-italic',
      '.ql-underline',
      'ql-color.ql-picker > .ql-picker-label',
      '.ql-clean',
      '.ql-color-picker > .ql-picker-label',
      '.ql-custom-link',
      '.ql-custom-image-full',
      '.ql-custom-image-left',
      '.ql-custom-image-right',
    ]

    if (toolbar === QuillToolbar.Full || toolbar === QuillToolbar.Limited) {
      buttons.map(button => {
        document.querySelector(button)?.setAttribute('tabindex', '-1')
      })
      // two of the buttons have the same className
      Array.from(document.querySelectorAll('.ql-list')).map(button => {
        button.setAttribute('tabindex', '-1')
      })
    }

    const getToolbar = () => {
      switch (toolbar) {
        case QuillToolbar.Limited:
          return {
            container: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              ['custom-link'],
            ],
            handlers: { 'custom-link': setCustomLink },
          }
        case QuillToolbar.Overview:
          return {
            container: [
              [{ header: [4, 5, false] }],
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              ['custom-link'],
            ],
            handlers: { 'custom-link': setCustomLink },
          }
        case QuillToolbar.Full:
          return [
            [{ header: [4, 5, false] }],
            ['bold', 'italic', 'underline'],
            [{ color: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['clean'],
          ]
        case QuillToolbar.PostStory: {
          const defaultTools = [
            [{ header: [4, 5, false] }],
            ['bold', 'italic'],
            [{ color: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, 'code-block'],
            ['clean'],
          ]
          return {
            container: [
              ...defaultTools,
              ['custom-link'],
              ['custom-image-left', 'custom-image-full', 'custom-image-right'],
            ],
            handlers: {
              'custom-link': setCustomLink,
              'custom-image-left': onLeftMediaClick,
              'custom-image-full': onFullMediaClick,
              'custom-image-right': onRightMediaClick,
            },
          }
        }
        default:
          return false
      }
    }

    //overwrites default tab behavior (typing a tab) and propagates the event to the default browser handler
    const getMentionsWrapper = (
      search: string,
      renderList: (suggestions: Suggestion[], searchTerm: string) => void
    ) => {
      MentionsService.getMentions(
        debouncedSearchUsers,
        search,
        renderList,
        cache,
        data?.community
          ? {
              communityId: data.community.communityId ?? null,
              type: data.community.type,
              canEdit: canEditIds.has(data.community.communityId ?? ''),
              photo: data.community.photo,
              name: data.community.name,
            }
          : null,
        Array.from(memberIds),
        veevanOnlyMentions
      )
    }

    return {
      toolbar: getToolbar(),
      supportGBoard: {},
      imageDropAndPaste: {
        handler: imagePasteHandler,
      },
      clipboard: {
        matchers: [
          [Node.TEXT_NODE, handleMeetupPaste],
          [Node.ELEMENT_NODE, handleMeetupPaste],
        ],
      },
      ...(allowMentions && {
        mention: {
          allowedChars: /^\S.*$/,
          minChars: 1,
          spaceAfterInsert: true,
          source: getMentionsWrapper,
          renderItem: ({ id, user, community, value, photo }: Suggestion) => {
            const div = document.createElement('div')
            div.innerHTML = template({ id, user, community, value, photo, isVeevanMentioner: isVeevan })
            return div
          },
        },
      }),
      magicUrl: allowLinks && {
        urlRegularExpression: LINK_REGEX,
        globalRegularExpression: LINK_REGEX,
        normalizeUrlOptions: {
          removeTrailingSlash: false,
          removeSingleSlash: false,
          sortQueryParameters: false,
        },
      },
    }
  }, [
    data,
    allowLinks,
    allowMentions,
    cache,
    isVeevan,
    toolbar,
    memberIds,
    imagePasteHandler,
    veevanOnlyMentions,
    canEditIds,
    onLeftMediaClick,
    onRightMediaClick,
    onFullMediaClick,
    handleMeetupPaste,
    setCustomLink,
    debouncedSearchUsers,
  ])

  const previousValue = useRef(initialHtml)

  const handleChange = useCallback(
    (value: string, delta: Delta, source: Sources, editor: UnprivilegedEditor) => {
      const asResult = (value: string): T => (allowMentions ? (asHtmlWithMentions(value) as T) : (asHtml(value) as T))

      if (maxLength && setHtml && getHtmlLength(asHtml(editor.getHTML())) > maxLength) {
        // If a max character length has been defined, prevent further input once the limit has been reached
        const previousText = getNodeText(HTMLReactParser.htmlToDOM(asString(previousValue.current))).trimEnd()
        setHtml(asResult(previousText.slice(0, maxLength)))

        // Cursor still progresses even if at max character length, this keeps it in place
        const prevIndex = ref?.selection?.index
        if (prevIndex) {
          const range: RangeStatic = {
            index: prevIndex - 1,
            length: 0,
          }
          ref?.setEditorSelection(ref?.getEditor(), range)
        }
        return
      }
      if (source == 'api' && value == '<p><br></p>') {
        // workaround for quill oddity that sometimes triggers onChange when unmounting which causes bizarre behaviors
        // in MultipleEditor
        return
      }
      if (setHtml) {
        previousValue.current = asResult(value)
        setHtml(previousValue.current)
      }
      if (setTextLength) {
        setTextLength(getHtmlLength(asHtml(editor.getHTML())))
      }

      if (isTitle) {
        const oldText = getNodeText(HTMLReactParser.htmlToDOM(asString(originalHtml)))
        const newText = getNodeText(HTMLReactParser.htmlToDOM(asString(value)))
        // If we are currently in the post add box, then we don't have an associated postId, so we use the communityId as a "placeholder" id to keep track of it
        setDraftingPost?.(
          `title:${postId ?? communityId ?? ''}`,
          oldText ? oldText != newText : Boolean(newText),
          false
        )
      }

      if (preventLineBreak && setHtml) {
        // new input is `delta.ops[0].insert` if first given input from the editor or `delta.ops[1].insert` otherwise
        const deltaInsert =
          delta?.ops && delta.ops[1]
            ? delta.ops[1].insert
            : delta?.ops && delta.ops[0]
              ? delta.ops[0].insert
              : undefined
        if (deltaInsert === '\n') setHtml(asResult(value.replace('<p><br></p>', '')))
      }
    },
    [
      maxLength,
      setHtml,
      setTextLength,
      originalHtml,
      preventLineBreak,
      allowMentions,
      ref,
      postId,
      setDraftingPost,
      communityId,
      isTitle,
    ]
  )

  // Quill doesn't respond to changes in the placeholder property, so manually update
  useEffect(() => {
    const editor = ref?.editor ? ref?.getEditor() : null
    if (editor) {
      editor.root.dataset.placeholder = placeholder
    }
  }, [placeholder, ref])

  // add tooltips for toolbar buttons by setting html title attribute
  useEffect(() => {
    const container = ref?.editingAreaRef.current ? ref.getEditingArea().parentElement : undefined
    container?.querySelector('.ql-bold')?.setAttribute('title', 'Bold')
    container?.querySelector('.ql-italic')?.setAttribute('title', 'Italic')
    container?.querySelector('.ql-color-picker')?.setAttribute('title', 'Text Color')
    container?.querySelector('.ql-code-block')?.setAttribute('title', 'Code')
    container?.querySelector('.ql-clean')?.setAttribute('title', 'Clear formatting')
    container?.querySelector('.ql-custom-link')?.setAttribute('title', 'Insert link')
    container?.querySelector('button[value="ordered"]')?.setAttribute('title', 'Numbered List')
    container?.querySelector('button[value="bullet"]')?.setAttribute('title', 'Bulleted List')
    container?.querySelector('button[value="-1"]')?.setAttribute('title', 'Decrease indent')
    container?.querySelector('button[value="+1"]')?.setAttribute('title', 'Increase indent')
    container
      ?.querySelector('.ql-custom-image-full')
      ?.setAttribute('title', 'Insert photo, video, or file (full width)')
    container?.querySelector('.ql-custom-image-left')?.setAttribute('title', 'Insert photo, video, or file (left)')
    container?.querySelector('.ql-custom-image-right')?.setAttribute('title', 'Insert photo, video, or file (right)')
  }, [ref])

  // Quill doesn't let us choose which way to orient the mentions list, so we can manually set the menu to show beneath
  // wherever the user is currently typing the @ mention
  const adjustMentionsMenu = useCallback(() => {
    const quill = ref?.editor ? ref?.getEditor() : null
    const mentionsContainer = Array.from(document.getElementsByClassName('ql-mention-list-container'))[0] as HTMLElement

    const selection = quill?.getSelection()
    const bounds = selection ? quill?.getBounds(selection.index) : null

    if (bounds && mentionsContainer) {
      mentionsContainer.style.transform = `translate(0px, ${bounds.bottom}px)`
    }
  }, [ref])

  const linkHotKeyHandler = useCallback(() => {
    if (quill?.hasFocus() && toolbar !== QuillToolbar.None) {
      setCustomLink()
    }
  }, [quill, toolbar, setCustomLink])

  const strikeHotKeyHandler = useCallback(
    (range: RangeStatic) => {
      if (quill) {
        const formats = quill.getFormat(range)
        quill.format('strike', !formats.strike)
      }
    },
    [quill]
  )

  useEffect(() => {
    if (quill) {
      const keyContext = isMacOs ? { metaKey: true } : { ctrlKey: true }

      quill.on('text-change', adjustMentionsMenu)
      quill.on('selection-change', adjustMentionsMenu)
      quill.on('editor-change', adjustMentionsMenu)

      quill.keyboard?.addBinding({ key: 'x' }, { metaKey: true, shiftKey: true }, strikeHotKeyHandler)
      quill.keyboard?.addBinding({ key: '%' }, { altKey: true, shiftKey: true }, strikeHotKeyHandler)

      quill.keyboard?.addBinding({ key: 'k' }, keyContext, linkHotKeyHandler)
      quill.keyboard?.addBinding({ key: 'escape' }, () => {
        quill.blur()
      })

      // disable pasting images/videos - https://github.com/quilljs/quill/issues/1108
      quill?.clipboard.addMatcher('IMG', () => {
        const Delta = Quill.import('delta')
        return new Delta().insert('')
      })
      quill?.clipboard.addMatcher('PICTURE', () => {
        const Delta = Quill.import('delta')
        return new Delta().insert('')
      })
      quill?.clipboard.addMatcher('A', (node, delta) => {
        if (toolbar === QuillToolbar.None) {
          const Delta = Quill.import('delta')
          return new Delta().insert(node.textContent ?? '')
        }
        return delta
      })
      MSWORD_MATCHERS.forEach(matcher => {
        quill?.clipboard.addMatcher(matcher[0], matcher[1])
      })

      return () => {
        quill.off('text-change', adjustMentionsMenu)
        quill.off('selection-change', adjustMentionsMenu)
        quill.off('editor-change', adjustMentionsMenu)
      }
    }
  }, [adjustMentionsMenu, linkHotKeyHandler, strikeHotKeyHandler, quill, toolbar])

  const submitEnter = useCallback(
    (e: KeyboardEvent) => {
      if ((!isMacOs && e.ctrlKey && e.key == 'Enter') || (isMacOs && e.metaKey && e.key == 'Enter')) {
        onSubmit?.({ skipWarning: false, warned: false, e: e })
      }
    },
    [onSubmit]
  )

  return (
    <>
      <ReactQuill
        ref={handleReactRef}
        className={className}
        modules={modules}
        value={asString(initialHtml)}
        theme="snow"
        onChange={handleChange}
        onKeyDown={e => {
          onKeyDown?.()
          //quill overwrites bindings to the enter key
          submitEnter(e)
        }}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        formats={[
          'bold',
          'italic',
          'underline',
          'strike',
          'list',
          'link',
          'mention',
          'color',
          'header',
          'indent',
          'align',
          'code-block',
          'meetup',
        ]}
      />
      {showLinkModal && (
        <LinkModal
          showLinkModal={true}
          setShowLinkModal={setShowLinkModal}
          clearLinkFields={clearLinkFields}
          linkIsMeetup={linkIsMeetup}
          validateMeetupLoading={validateMeetupLoading}
          meetupLink={meetupLink}
          displayText={displayText}
          setCopyLinkText={setCopyLinkText}
          handleCodeKeyDown={handleCodeKeyDown}
          handleLinkChanged={handleLinkChanged}
          showHasMeetupError={showHasMeetupError}
          setDisplayText={setDisplayText}
          linkText={linkText}
          meetupLinkResult={meetupLinkResult}
          editingLink={editingLink}
          handleLinkEdit={handleLinkEdit}
          handleLinkSubmit={handleLinkSubmit}
          meetupReturnedError={meetupReturnedError}
          postId={postId ?? null}
          primaryVideoUrl={primaryVideoUrl}
          allowVideoTimestamps={allowVideoTimestamps ?? false}
          videoStartTime={videoStartTime}
          setVideoStartTime={setVideoStartTime}
          meetupData={meetupData}
        />
      )}

      <ToastComponent bg={'danger'} show={showLinkErrorToast} onClose={() => setShowLinkErrorToast(false)}>
        Multiple meetups within the same post or comment are not allowed.
      </ToastComponent>
    </>
  )
}

// Solution for users omitting protocol in links: https://github.com/zenoamaro/react-quill/issues/632
Link.sanitize = function (url: string) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`
  }
  return url
}

export default QuillEditor
