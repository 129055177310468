import React, { KeyboardEventHandler, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { QuillMeetupModal } from '~/common/quill/QuillMeetupModal'
import VideoOptions from '~/common/quill/VideoOptions'
import { ValidateMeetupQuery } from '~/api/generated/graphql'
import { Maybe } from '~/api/generated/graphql'
import { getVideoLength, getVideoStartTime, updateWistiaLink } from '~/utils'

type LinkModalProps = {
  showLinkModal: boolean
  setShowLinkModal: (show: boolean) => void
  clearLinkFields: () => void
  linkIsMeetup: boolean
  validateMeetupLoading: boolean
  meetupLink: string
  displayText: string
  setCopyLinkText: (copy: boolean) => void
  handleCodeKeyDown: KeyboardEventHandler<HTMLInputElement>
  handleLinkChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  showHasMeetupError: boolean
  setDisplayText: (displayText: string) => void
  linkText: string
  meetupLinkResult: string
  meetupData?: ValidateMeetupQuery | undefined
  allWistiaUrls?: string[]
  editingLink: HTMLAnchorElement | null
  handleLinkEdit: (videoTimestamp?: string) => void
  handleLinkSubmit: (videoTimestamp?: string) => void
  meetupReturnedError: boolean
  postId: Maybe<string>
  primaryVideoUrl?: Maybe<string>
  allowVideoTimestamps: boolean
  videoStartTime: string | undefined
  setVideoStartTime: (t: string) => void
}

export const padDigit = (digit: number) => {
  return digit > 9 ? digit : `0${digit}`
}

// go through the expected variations of the time format and normalize them
const validateTimeInput = (timeValue?: string) => {
  if (!timeValue) return null

  const timeMatches = timeValue.match(/(?<minutes>\d{1,2}):(?<seconds>\d\d)/)?.groups
  if (!timeMatches) return null
  const seconds = timeMatches?.seconds ? parseInt(timeMatches.seconds) : 0
  const minutes = timeMatches?.minutes ? parseInt(timeMatches.minutes) : 0
  if (seconds && seconds > 59) {
    return null
  } else if (minutes && minutes > 59) {
    const hours = Math.floor(minutes / 60)
    const adjustedMinutes = minutes - hours * 60
    return `${padDigit(hours)}:${padDigit(adjustedMinutes)}:${timeMatches.seconds}`
  } else {
    return `00:${padDigit(minutes)}:${timeMatches.seconds}`
  }
}

const LinkModal = ({
  showLinkModal,
  setShowLinkModal,
  clearLinkFields,
  linkIsMeetup,
  validateMeetupLoading,
  meetupLink,
  displayText,
  setCopyLinkText,
  handleCodeKeyDown,
  handleLinkChanged,
  showHasMeetupError,
  setDisplayText,
  linkText,
  meetupLinkResult,
  meetupData,
  editingLink,
  handleLinkEdit,
  handleLinkSubmit,
  meetupReturnedError,
  primaryVideoUrl,
  allowVideoTimestamps,
  videoStartTime,
  setVideoStartTime,
}: LinkModalProps) => {
  const [videoOptionsExpanded, setVideoOptionsExpanded] = useState<boolean>(Boolean(videoStartTime))
  const [timeInputError, setTimeInputError] = useState<string>()

  const handleClickInsert = async () => {
    if (videoOptionsExpanded) {
      const validatedInput = validateTimeInput(videoStartTime)
      if (validatedInput) {
        try {
          // convert veevasystems.wistia.com/... links to fast.wistia.com/... links
          const wistiaId = updateWistiaLink(primaryVideoUrl ?? null)?.match(/(?<wistiaId>\w+).jsonp/)?.groups?.wistiaId
          const videoLength = (await getVideoLength(wistiaId)) ?? 0
          const enteredLength = getVideoStartTime(validatedInput) ?? 0
          if (enteredLength > videoLength) {
            setTimeInputError('The video is not that long. Please input a valid time.')
          } else {
            if (editingLink) handleLinkEdit(validatedInput)
            else handleLinkSubmit(validatedInput)
          }
        } catch (e) {
          // if fetching the length fails for any reason, just proceed and trust the user knows to enter a valid time
          if (editingLink) handleLinkEdit(validatedInput)
          else handleLinkSubmit(validatedInput)
        }
      } else {
        setTimeInputError('Please input a valid time.')
      }
    } else {
      if (editingLink) handleLinkEdit()
      else handleLinkSubmit()
    }
  }

  const handleSetExpanded = (expanded: boolean) => {
    if (!videoStartTime) {
      const timestampMatch = displayText.match(/(?<minutes>\d{1,2}):(?<seconds>\d\d)/)
      if (
        timestampMatch &&
        allowVideoTimestamps &&
        primaryVideoUrl &&
        parseInt(timestampMatch?.groups?.seconds ?? '0') < 60
      ) {
        const minutesText = padDigit(parseInt(timestampMatch?.groups?.minutes ?? '0'))
        const parsedTimestamp = `${minutesText}:${timestampMatch?.groups?.seconds}`
        setVideoStartTime(parsedTimestamp)
      }
    }

    setVideoOptionsExpanded(expanded)
  }

  return (
    <Modal
      show={showLinkModal}
      onHide={() => {
        setShowLinkModal(false)
        clearLinkFields()
        setTimeInputError('')
        setVideoOptionsExpanded(false)
        setVideoStartTime('')
      }}
      className={'link-modal'}
      data-testid={'link-modal'}
    >
      <Modal.Header closeButton>
        <h3 data-testid={'link-modal-header'}>Insert Link</h3>
      </Modal.Header>
      <Modal.Body>
        {/*TODO: separate QuillMeetupModal into its own component and decouple it from the Link modal as it makes the logic messier*/}
        <QuillMeetupModal
          linkIsMeetup={linkIsMeetup}
          validateMeetupLoading={validateMeetupLoading}
          meetupLink={meetupLink}
          displayText={displayText}
          handleCodeKeyDown={handleCodeKeyDown}
          handleLinkChanged={handleLinkChanged}
          setCopyLinkText={setCopyLinkText}
          linkText={linkText}
          setDisplayText={setDisplayText}
          showHasMeetupError={showHasMeetupError}
          meetupData={meetupData}
          meetupLinkResult={meetupLinkResult}
          hasVideo={videoOptionsExpanded}
        />
        {allowVideoTimestamps && (
          <VideoOptions
            isExpanded={videoOptionsExpanded}
            setIsExpanded={handleSetExpanded}
            startTime={videoStartTime}
            setStartTime={setVideoStartTime}
            hasPrimaryMedia={Boolean(primaryVideoUrl)}
            timeInputError={timeInputError}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          size="sm"
          onClick={handleClickInsert}
          disabled={meetupReturnedError || validateMeetupLoading || showHasMeetupError}
        >
          Insert Link
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LinkModal
