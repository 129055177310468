import React from 'react'
import { toTimeAgo } from '~/utils'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type Props = {
  time: Date
  timeNow?: Date
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const TimeAgo = ({ time, timeNow }: Props) => {
  const dateTime = new Date(time)
  const day = dateTime.getDate()
  const month = MONTHS[dateTime.getMonth()]
  const year = dateTime.getFullYear()
  const hours = dateTime.getHours()
  const minutes = dateTime.getMinutes()
  const seconds = dateTime.getSeconds()
  const xm = hours >= 12 ? 'pm' : 'am'

  const pad = (num: number) => {
    return `${num < 10 ? '0' : ''}${num}`
  }

  return (
    <OverlayTrigger
      placement={'top'}
      overlay={
        <Tooltip data-testid={'time-tooltip'}>
          {`${day} ${month} ${year} ${hours == 0 || hours == 12 ? 12 : hours % 12}:${pad(minutes)}:${pad(
            seconds
          )} ${xm}`}
        </Tooltip>
      }
    >
      <span data-testid={'time-ago'}>{toTimeAgo(time, timeNow)}</span>
    </OverlayTrigger>
  )
}

export default TimeAgo
