import { SyntheticEvent } from 'react'
import { SearchObjectType } from '~/common/TypeaheadSearch'

declare global {
  interface Window {
    EventProcessor: {
      getInstance: () => {
        searchEvent: (name: string, details?: unknown) => null
        userEvent: (name: string, details?: unknown) => null
        errorEvent: (name: string, details?: unknown) => null
        flushEvents: () => null
      }
    }
  }
}

type DomPathElement = Node & { hasAttribute?: (a: string) => boolean; id?: string }

// based on https://stackoverflow.com/questions/5728558/get-the-dom-path-of-the-clicked-a
const getDomPath = (e: DomPathElement) => {
  let el = e
  const stack: string[] = []
  while (el.parentNode != null) {
    let sibCount = 0
    let sibIndex = 0
    for (let i = 0; i < el.parentNode.childNodes.length; i++) {
      const sib = el.parentNode.childNodes[i]
      if (sib.nodeName === el.nodeName) {
        if (sib === el) {
          sibIndex = sibCount
        }
        sibCount++
      }
    }
    if (el.hasAttribute && el.hasAttribute('id') && el.id !== '') {
      stack.unshift(el.nodeName.toLowerCase() + '#' + el.id)
    } else if (sibCount > 1) {
      stack.unshift(el.nodeName.toLowerCase() + ':eq(' + sibIndex + ')')
    } else {
      stack.unshift(el.nodeName.toLowerCase())
    }
    el = el.parentNode
  }

  return stack.slice(1) // removes the html element
}

export const logNetworkError = (error: Error) => {
  window.EventProcessor.getInstance().userEvent('network-error', {
    message: error.message,
  })
}

export const advancedSearchClicked = (e: SyntheticEvent, community_link: string) => {
  window.EventProcessor.getInstance().searchEvent('click-advanced-search-instant-search-link', {
    dompath: getDomPath(e.target as DomPathElement),
    community_link,
  })
}

export const searchClicked = (e: SyntheticEvent, key: string, i: number) => {
  window.EventProcessor.getInstance().searchEvent('instant-search-click', {
    dompath: getDomPath(e.target as DomPathElement),
    category: key,
    index: i,
  })
}

export const searchAbandoned = (e: Event, searchQuery: string, logName: string, context?: unknown) => {
  window.EventProcessor.getInstance().searchEvent(
    logName,
    Object.assign({ query: searchQuery, dompath: getDomPath(e.target as DomPathElement) }, context)
  )
}

export const searchQueryLogger = (searchQuery: string, logName: string, context?: unknown) => {
  window.EventProcessor.getInstance().searchEvent(logName, Object.assign({ query: searchQuery }, context))
}

export const searchResultsLogger = (searchQuery: string, filteredOptions: { type: SearchObjectType }[]) => {
  const resultCounts = filteredOptions.reduce(
    (acc, o) => acc.set(o['type'], (acc.get(o['type']) || 0) + 1),
    new Map<SearchObjectType, number>()
  )
  window.EventProcessor.getInstance().searchEvent('instant-search', {
    query: searchQuery,
    matches: Object.fromEntries(resultCounts.entries()),
  })
}

export const elementClicked = (e: SyntheticEvent | undefined, name: string, context?: unknown) => {
  const details = e
    ? Object.assign(
        {
          dompath: getDomPath(e.target as DomPathElement),
        },
        context
      )
    : context
  window.EventProcessor.getInstance().userEvent(name, details)
}

export const elementClickedNoEvent = (name: string, context?: unknown) => {
  window.EventProcessor.getInstance().userEvent(name, context)
}

export const flushEvents = () => {
  window.EventProcessor.getInstance().flushEvents()
}
