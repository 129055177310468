import Delta from 'quill-delta'

// by default, pasting lists from MS Word to quill does not work properly. This defines custom matchers to try to address that
// https://github.com/quilljs/quill/issues/1225
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function matchMsWordList(node: any, dt: Delta): Delta {
  // Clone the operations
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ops = dt.ops?.map((op: any) => Object.assign({}, op))

  // Trim the front of the first op to remove the bullet/number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bulletOp = ops?.find((op: any) => op.insert && op.insert.trim().length)
  if (!bulletOp) {
    return dt
  }

  bulletOp.insert = bulletOp.insert.trimLeft()
  const listPrefix = bulletOp.insert.match(/^.*?(^·|\.)/) || bulletOp.insert[0]
  bulletOp.insert = bulletOp.insert.substring(listPrefix[0].length, bulletOp.insert.length).trimLeft()

  // Trim the newline off the last op
  const last = ops?.[ops.length - 1]
  last.insert = last.insert.substring(0, last.insert.length - 1)

  // Determine the list type
  const listType = listPrefix[0].length === 1 ? 'bullet' : 'ordered'

  // Determine the list indent
  const style = node.getAttribute('style').replace(/\n+/g, '')
  const levelMatch = style.match(/level(\d+)/)
  const indent = levelMatch ? levelMatch[1] - 1 : 0

  // Add the list attribute
  ops?.push({ insert: '\n', attributes: { list: listType, indent } })

  return new Delta(ops)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function maybeMatchMsWordList(node: any, dt: Delta): Delta {
  if ((dt.ops[0].insert as string).trimStart()[0] === '·') {
    return matchMsWordList(node, dt)
  }

  return dt
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MSWORD_MATCHERS: Array<[string, (node: any, delta: Delta) => Delta]> = [
  ['p.MsoListParagraphCxSpFirst', matchMsWordList],
  ['p.MsoListParagraphCxSpMiddle', matchMsWordList],
  ['p.MsoListParagraphCxSpLast', matchMsWordList],
  ['p.MsoListParagraph', matchMsWordList],
  ['p.msolistparagraph', matchMsWordList],
  ['p.MsoNormal', maybeMatchMsWordList],
]
