import React, { SyntheticEvent, useMemo, useState } from 'react'
import { useAuth } from '~/auth/Auth'
import { useProfile } from '~/contexts/ProfileContext'
import '@css/pages/profile/ProfileHistory.scss'
import { GetUserHistoryDocument } from '~/api/generated/graphql'
import moreIcon from '@web/images/profile/more-icon.svg'
import { Link } from 'react-router-dom'
import { removeTags, toTimeAgo, UTCtoLocal } from '~/utils'
import { HistoryRowModel } from '~/types'
import { elementClicked } from '~/common/EventLogger'
import { useQuery } from '@apollo/client'

const ProfileHistory = ({ updated }: { updated: boolean }) => {
  const columns = ['Modified date', 'Modified by', 'Field', 'Old value', 'New value']

  const { user } = useProfile()

  const { authUserId, isVeevan } = useAuth()

  const authUserIsUser = user && authUserId && user.userId === authUserId
  const canView = authUserIsUser || isVeevan

  const [viewHistory, setViewHistory] = useState<boolean>(false)

  const viewHistoryClick = (e: SyntheticEvent) => {
    elementClicked(e, 'click-profile-history', { userId: user?.userId })
    setViewHistory(!viewHistory)
  }

  const { data, loading, fetchMore, refetch } = useQuery(GetUserHistoryDocument, {
    variables: { userId: user?.userId },
    fetchPolicy: 'network-only',
  })

  const hasMore = data?.history?.pageInfo.hasNextPage ?? false

  const loadMore = () => {
    fetchMore({ variables: { cursor: data?.history?.pageInfo.endCursor } }).then()
  }

  if (updated) {
    refetch().then()
  }

  const userHistoryData = useMemo(() => {
    if (!loading && data) {
      return (
        (data?.history?.edges?.map(edge => {
          return {
            historyId: edge?.node?.historyId,
            modifiedTime:
              new Date(user?.createdTime || '') <= new Date(edge?.node?.modifiedTime)
                ? edge?.node?.modifiedTime
                : user?.createdTime,
            modifiedBy: {
              firstName: edge?.node?.modifiedBy?.firstName,
              lastName: edge?.node?.modifiedBy?.lastName,
              userId: edge?.node?.modifiedBy?.userId,
              hidden: edge?.node?.modifiedBy?.hidden,
            },
            field: edge?.node?.field,
            oldValue: edge?.node?.oldValue,
            newValue: edge?.node?.newValue,
            table: edge?.node?.table,
            action: edge?.node?.action,
            community: {
              name: edge?.node?.community?.name,
            },
            company: {
              name: edge?.node?.company?.name,
            },
          }
        }) as HistoryRowModel[]) ?? []
      )
    }
  }, [data, loading, user])

  const historyExists = userHistoryData && userHistoryData[0]?.modifiedTime

  const fullName = (fName: string, lName: string) => {
    return `${fName} ${lName}`
  }

  if (!canView) {
    return null
  }

  type UserHistoryParts = {
    email: string
    firstName: string
    lastName: string
    userId: string
    hidden: boolean
    company: {
      name: string
      companyId: string
    }
  }

  const getUserLink = (user: UserHistoryParts, s: string) => {
    if (!user?.hidden && user?.firstName) {
      return (
        <>
          <Link to={`/profiles/${user.userId}`} className={'history-link'}>
            {fullName(user.firstName, user.lastName)}
          </Link>{' '}
          {s}
        </>
      )
    } else {
      return <>{fullName(user?.firstName ?? '', user?.lastName ?? '')}</>
    }
  }

  const getTableInfo = (row: HistoryRowModel) => {
    if (row.table == 'MEMBERSHIP') {
      let oldVal, newVal, field
      if (row.action == 'CREATE') {
        field = 'Membership'
        oldVal = removeTags(row.oldValue)
        newVal =
          user?.userId == row.modifiedBy.userId ? (
            getUserLink(user as UserHistoryParts, ` joined the ${row.community.name} community.`)
          ) : (
            <>
              {getUserLink(row.modifiedBy, ' added')}{' '}
              {getUserLink(user as UserHistoryParts, ` to the ${row.community.name} community.`)}
            </>
          )
      } else if (row.action == 'DELETE') {
        field = 'Membership'
        oldVal = removeTags(row.oldValue)
        newVal =
          user?.userId == row.modifiedBy.userId ? (
            getUserLink(user as UserHistoryParts, ` left the ${row.community.name} community.`)
          ) : (
            <>
              {getUserLink(row.modifiedBy, ' removed')}{' '}
              {getUserLink(user as UserHistoryParts, ` from the ${row.community.name} community.`)}
            </>
          )
      } else if (row.action === 'UPDATE') {
        field = 'Membership'
        oldVal = row.newValue === '1' ? 'Member' : row.community.isHomepage ? 'Account Partner' : 'Leader'
        const op = row.newValue === '1' ? 'became' : ' is no longer'
        const role = row.community.isHomepage ? 'an account partner' : 'a leader'
        newVal = (
          <>
            {getUserLink(user as UserHistoryParts, '')} {op} {role} of the {row.community.name} community.
          </>
        )
      }
      return [<td key={'field'}>{field}</td>, <td key={'oldVal'}>{oldVal}</td>, <td key={'newVal'}>{newVal}</td>]
    } else if (row.table === 'ROLE') {
      return [
        <td key={'field'}>{`Role at ${row.company?.name}`}</td>,
        <td key={'oldVal'}>{row.oldValue}</td>,
        <td key={'newVal'}>{row.newValue}</td>,
      ]
    } else {
      return [
        <td key={'field'}>{row.field}</td>,
        <td key={'oldVal'}>{removeTags(row.oldValue)}</td>,
        <td key={'newVal'}>{removeTags(row.newValue)}</td>,
      ]
    }
  }

  return (
    <div className={'history-container'}>
      {viewHistory ? (
        <div className={'history-header-view'}>
          <h5>Profile History</h5>
          <button onClick={viewHistoryClick} className={'history-link'}>
            Hide History
          </button>
        </div>
      ) : (
        <div className={'history-header'}>
          {historyExists ? (
            <p>Last modified {toTimeAgo(new Date(userHistoryData[0]?.modifiedTime))}</p>
          ) : (
            <p>No history has been recorded.</p>
          )}
          {historyExists && (
            <button onClick={viewHistoryClick} className={'history-link'} data-testid={'history-link'}>
              View History
            </button>
          )}
        </div>
      )}
      <div className={'table-container'}>
        {viewHistory && (
          <div>
            <table>
              <thead>
                <tr>
                  {columns.map(col => (
                    <td key={col}>{col}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {userHistoryData?.map(row => (
                  <tr key={row.historyId}>
                    <td>{UTCtoLocal(row.modifiedTime)}</td>
                    <td key={row.modifiedBy.userId}>
                      {!row.modifiedBy.hidden ? (
                        <Link to={`/profiles/${row.modifiedBy.userId}`} className={'history-link'}>
                          {fullName(row.modifiedBy.firstName, row.modifiedBy.lastName)}
                        </Link>
                      ) : (
                        <>{fullName(row.modifiedBy.firstName, row.modifiedBy.lastName)}</>
                      )}
                    </td>
                    {getTableInfo(row)}
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className="history-show-more">
                <a onClick={loadMore}>
                  MORE <img src={moreIcon} />
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileHistory
