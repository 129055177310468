import React, { useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useCommunities } from '~/contexts/CommunitiesContext'
import { CommunityListViewModel, FeedCommunityListViewModel } from '~/types'
import { Link } from 'react-router-dom'
import { useAuth } from '~/auth/Auth'
import { CommunityListTab } from '~/pages/community/CommunityListLayout'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { communitiesToCommunitiesListView, sortCommunityList } from '~/utils'
import { CommunitySortEnum, GetDropdownCommunityDocument, GetMyCommunitiesDocument } from '~/api/generated/graphql'
import { elementClicked } from '~/common/EventLogger'
import { useCommunity } from '~/contexts/CommunityContext'
import { useQuery } from '@apollo/client'

interface CommunitySelectorDropdownProps {
  communityId?: string
  communityListTab: CommunityListTab
  listPage?: boolean
}

const CommunitySelectorDropdown = ({ communityId, communityListTab, listPage }: CommunitySelectorDropdownProps) => {
  const { canPostIds } = useAuth()
  const { recommendedCommunities } = useCommunities()
  const { loading: communityLoading } = useCommunity()
  const { data: basicCommunityData } = useQuery(GetDropdownCommunityDocument, {
    variables: { id: communityId || '' },
    skip: !communityId || communityLoading,
  })
  const { loading: loadingMine, data: allMyCommunities } = useQuery(GetMyCommunitiesDocument, {
    variables: { communityFilter: {}, communitySort: [CommunitySortEnum.NameAsc] },
    notifyOnNetworkStatusChange: true,
  })
  const { companyId } = useAuth()
  const loading = loadingMine && !allMyCommunities
  const { isCondensed } = useWindowSize()

  const myCommunities = useMemo(
    () => communitiesToCommunitiesListView(allMyCommunities, canPostIds),
    [allMyCommunities, canPostIds]
  )

  const checkSelected = (c: FeedCommunityListViewModel | CommunityListViewModel): boolean => {
    if (c.companyId) {
      return location.pathname.includes(c.companyId)
    }
    return false
  }

  const sortedCommunities = useMemo(() => {
    if (myCommunities) {
      return sortCommunityList(companyId, [...myCommunities])
    }
  }, [companyId, myCommunities])

  return (
    <div className="navbar-section parent-width">
      <div className={isCondensed ? 'dark-text' : 'light-text'}>{isCondensed ? 'Showing:' : 'Communities'}</div>
      <Dropdown id="dropdown-community">
        <Dropdown.Toggle id="dropdown-basic" role="community-dropdown" className={'community-menu-item'}>
          {communityId && (
            <>
              <div
                className="dropdown-icon profile-photo community-photo"
                style={
                  basicCommunityData?.community?.photo
                    ? { backgroundImage: `url(${basicCommunityData?.community.photo ?? ''})` }
                    : {}
                }
              />
              <span>{basicCommunityData?.community?.name}</span>
            </>
          )}
          {!communityId && <span>{`${communityListTab} Communities`}</span>}
        </Dropdown.Toggle>
        {isCondensed && listPage ? (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={e => elementClicked(e, 'click-community-menu-all')}
              className={'community-menu-item'}
              key="all"
              as={Link}
              to={'/allcommunities'}
            >
              <span>All Communities</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={e => elementClicked(e, 'click-community-menu-my')}
              className={'community-menu-item'}
              key="my"
              as={Link}
              to={'/mycommunities'}
            >
              <span>My Communities</span>
            </Dropdown.Item>
            {!!recommendedCommunities?.length && (
              <Dropdown.Item
                onClick={e => elementClicked(e, 'click-community-menu-recommended')}
                className={'community-menu-item'}
                key="rec"
                as={Link}
                to={'/recommendedcommunities'}
              >
                <span>Recommended Communities</span>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={e => elementClicked(e, 'click-community-menu-all')}
              className={'community-menu-item'}
              key="all"
              as={Link}
              to={'/allcommunities'}
            >
              <span>All Communities</span>
            </Dropdown.Item>
            {loading && <Dropdown.Item key="loading">Loading Communities...</Dropdown.Item>}
            {!loading && (
              <>
                {sortedCommunities?.map(community => (
                  <Dropdown.Item
                    onClick={e =>
                      elementClicked(e, 'click-community-menu-item', {
                        type: community.companyId ? 'company' : 'community',
                        id: community.companyId ? community.companyId : community.communityId,
                      })
                    }
                    className={`community-menu-item ${checkSelected(community) ? 'selected' : ''}`}
                    key={community.companyId ? community.companyId : community.communityId}
                    as={Link}
                    to={
                      community.companyId
                        ? `/companies/${community.companyId}/home`
                        : `/communities/${community.communityId}`
                    }
                  >
                    <div
                      className="dropdown-icon profile-photo community-photo"
                      style={community.photo ? { backgroundImage: `url(${community.photo ?? ''})` } : {}}
                    />
                    <span>{community.name}</span>
                  </Dropdown.Item>
                ))}
              </>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  )
}

export default CommunitySelectorDropdown
