import React, { SyntheticEvent, useMemo, useState } from 'react'
import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  CommunitySortEnum,
  AddMemberDocument,
  AddRoleDocument,
  GetMyCommunitiesDocument,
  RemoveMemberDocument,
} from '~/api/generated/graphql'
import '@css/common/JoinCommunityButton.scss'
import ToastComponent from '~/common/ToastComponent'
import { useMediaUpload } from '~/contexts/MediaUploadContext'
import { useAuth } from '~/auth/Auth'
import { useCommunity } from '~/contexts/CommunityContext'
import { removeMemberCache } from '~/utils'
import { useMutation, useQuery } from '@apollo/client'

export enum MembershipAction {
  join = 'join',
  leave = 'leave',
}

interface JoinCommunityButtonProps {
  communityId: string
  communityName: string
  onAction?: (action: MembershipAction, e: SyntheticEvent) => void
  fillFullWidth?: boolean
  isCompany?: boolean
  isOnHomeFeed?: boolean
  disableLeave?: boolean
  showToast?: boolean
}

const JoinCommunityButton = ({
  onAction,
  fillFullWidth,
  isCompany,
  communityId,
  communityName,
  isOnHomeFeed,
  disableLeave,
  showToast = true,
}: JoinCommunityButtonProps) => {
  const { authUserId, profileVisible, loading: authLoading } = useAuth()
  const { data, loading: userCommunitiesLoading } = useQuery(GetMyCommunitiesDocument, {
    variables: { communityFilter: {}, communitySort: [CommunitySortEnum.NameAsc] },
    notifyOnNetworkStatusChange: true,
  })
  const { companyId } = useCommunity()
  const communityIds = useMemo(() => data?.currentUser?.communities?.edges.map(e => e?.node?.communityId) || [], [data])

  // NOTE: do not use isMember from usePermissions due to that one counting admins as members
  const isMember = useMemo(() => Boolean(communityIds?.some(c => c === communityId)), [communityIds, communityId])
  const [showLeaveModal, setShowLeaveModal] = useState<boolean>(false)
  const [showJoinSuccess, setShowJoinSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const handleLeaveClick = () => setShowLeaveModal(true)
  const handleLeaveCancel = () => setShowLeaveModal(false)
  const handleCloseToast = () => setShowJoinSuccess(false)
  const [addMemberMutation] = useMutation(AddMemberDocument)
  const [removeMemberMutation] = useMutation(RemoveMemberDocument, {
    update: (cache, { data }) => {
      data?.removeMember?.ok && removeMemberCache(cache, data)
    },
  })
  const communityText = isCompany ? 'customer homepage' : 'community'
  const { uploading } = useMediaUpload()
  const showJoin = !isMember
  const [disabled, setDisabled] = useState<boolean>(false)
  const [leaving, setLeaving] = useState(true)

  const [addRole] = useMutation(AddRoleDocument)
  const doAddRole = async () => {
    await addRole({
      variables: {
        userId: authUserId ?? '',
        companyId: companyId ?? '',
        description: '',
      },
    })
  }

  const handleJoinClick = async (e: SyntheticEvent) => {
    setDisabled(true)
    onAction?.(MembershipAction.join, e)
    try {
      const response = await addMemberMutation({
        variables: {
          userId: authUserId ?? '',
          communityId,
        },
      })
      if (response.data?.addMember?.ok) {
        if (companyId) {
          doAddRole().then()
        }
        setLeaving(false)
        setShowJoinSuccess(true)
      } else {
        setError(`Unable to join ${communityText}`)
      }
    } catch {
      setError(`Something went wrong. Please try again.`)
    }
    setDisabled(false)
  }

  const handleLeaveConfirm = async (e: SyntheticEvent) => {
    setDisabled(true)
    onAction?.(MembershipAction.leave, e)
    try {
      setShowLeaveModal(false)
      const response = await removeMemberMutation({
        variables: {
          userId: authUserId ?? '',
          communityId,
        },
      })
      if (response.data?.removeMember?.ok) {
        setLeaving(true)
        setShowJoinSuccess(true)
      } else {
        if (response.data?.removeMember?.error?.code === 'onlyLeader') {
          setError(`At least one ${isCompany ? 'account partner' : 'leader'} is required in a ${communityText}`)
        } else {
          setError('Something went wrong. Please try again.')
        }
      }
    } catch {
      setError(`Something went wrong. Please try again.`)
    }
    setDisabled(false)
  }
  const loading = authLoading || userCommunitiesLoading
  if (!data && loading) return null
  // noinspection RequiredAttributes
  return (
    <>
      <div className={`button-zone ${fillFullWidth ? 'list-button' : ''}`}>
        {showJoin && profileVisible && (
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={<Tooltip id="tooltip-bottom">This will add you as a member of the community</Tooltip>}
          >
            <Button
              onClick={handleJoinClick}
              variant="outline-secondary"
              className={'add'}
              role="button"
              disabled={disabled}
            >
              <span>Join</span>
            </Button>
          </OverlayTrigger>
        )}
        {!showJoin &&
          profileVisible &&
          (isOnHomeFeed || disableLeave ? (
            <Button variant="outline-secondary" className={'checked'}>
              <span className={'no-dropdown'}>Joined</span>
            </Button>
          ) : (
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" className={'checked join-button-toggle'} role="button">
                <span>Joined</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLeaveClick} eventKey="1">
                  Leave
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ))}
      </div>
      <Modal show={showLeaveModal} onHide={handleLeaveCancel}>
        <Modal.Header closeButton />
        <Modal.Body>
          {uploading
            ? `File upload still in progress, are you you sure you want to leave?`
            : `Are you sure you want to leave the ${communityText}?`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleLeaveCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleLeaveConfirm} disabled={disabled}>
            Leave
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={!!error} onHide={() => setError(null)}>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setError(null)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastComponent show={showJoinSuccess && showToast} onClose={handleCloseToast}>
        {`You've ${leaving ? 'left' : 'joined'} ${communityName}.`}
      </ToastComponent>
    </>
  )
}

export default JoinCommunityButton
