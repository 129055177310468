import React, { useCallback, useState } from 'react'
import { RepostCommunityModel } from '~/types'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  CanAddContentDocument,
  GetEventCommunityDocument,
  PostType,
  RepostDocument,
  RepostEventDocument,
} from '~/api/generated/graphql'
import { Link } from 'react-router-dom'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { RepostType } from '~/common/RepostOverlay'
import { getEventPath, getPostPath, getStartOfCurrentDay } from '~/utils'
import { elementClicked } from '~/common/EventLogger'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'

type RepostSelectionRowProps = {
  community: RepostCommunityModel
  eventId?: string
  postId?: string
  isContent?: boolean
  onRepost?: (commId: string) => void
  repostType?: RepostType
}

export const RepostSelectionRow = ({
  community,
  eventId,
  postId,
  isContent,
  onRepost,
  repostType,
}: RepostSelectionRowProps) => {
  const { communityId } = community

  const [repostEnabled, setRepostEnabled] = useState(true)
  const [isContentChecked, setContentChecked] = useState(false)
  const [showContentWarning, setShowContentWarning] = useState(false)
  const [repostLink, setRepostLink] = useState<string>('')
  const [showRepostError, setShowRepostError] = useState<boolean>(false)
  const showRepostAsContentCheck = isContent
  const { isCondensedPortrait } = useWindowSize()

  const [showTooltip, setShowTooltip] = useState(false)
  const [attempting, setAttempting] = useState<boolean>(false)

  const client = useApolloClient()
  const [doPostRepost] = useMutation(RepostDocument, {
    update(cache) {
      const oldCount = cache.readQuery({
        query: CanAddContentDocument,
        variables: { communityId: communityId ?? '' },
      })

      const oldData = oldCount?.canAddContent
      const newCount = (oldData?.count ?? 0) + 1

      const newCountData = {
        canAddContent: {
          ok: newCount !== oldData?.max,
          count: newCount > (oldData?.max ?? 0) ? (oldData?.count ?? 0) : newCount,
          max: oldData?.max ?? 0,
          __typename: 'ContentValidator' as const,
        },
      }

      client.writeQuery({
        query: CanAddContentDocument,
        variables: { communityId: communityId ?? '' },
        data: newCountData,
      })
    },
  })
  const [doEventRepost] = useMutation(RepostEventDocument)

  const makePostRepost = useCallback(async () => {
    setRepostEnabled(false)
    const response = await doPostRepost({
      variables: {
        communityId: communityId ?? '',
        repostId: postId ?? '',
        postType: isContentChecked ? PostType.Content : PostType.Post,
        refreshContentPage: isContentChecked,
      },
    })

    if (response?.data?.repost?.ok) {
      const newRepost = response.data.repost.repost
      const newRepostLink = getPostPath(newRepost?.community, newRepost)
      setRepostLink(newRepostLink)
      onRepost?.(communityId)
    } else {
      setRepostEnabled(true)
      setShowRepostError(true)
    }
  }, [communityId, doPostRepost, isContentChecked, onRepost, postId])

  useQuery(CanAddContentDocument, {
    variables: { communityId },
    skip: !attempting,
    onCompleted: data => {
      if (!data.canAddContent?.ok) {
        setShowContentWarning(true)
      } else {
        makePostRepost().then()
        setShowTooltip(false)
      }
      setAttempting(false)
    },
  })

  const { data: repostCommunity } = useQuery(GetEventCommunityDocument, {
    variables: { id: communityId },
  })

  const attemptRepost = useCallback(
    (e: React.MouseEvent) => {
      const makeEventRepost = async () => {
        setRepostEnabled(false)
        const response = await doEventRepost({
          variables: {
            communityId,
            repostId: eventId ?? '',
            notify: true,
            date: getStartOfCurrentDay(),
          },
        })

        if (response?.data?.repostEvent?.ok) {
          const newRepostLink = getEventPath(
            repostCommunity?.community,
            response?.data?.repostEvent?.repost?.eventId ?? ''
          )
          setRepostLink(newRepostLink)
          onRepost?.(communityId)
        } else {
          setRepostEnabled(false)
          setShowRepostError(true)
        }
      }

      if (repostType == RepostType.POST) {
        if (isContentChecked) {
          setAttempting(true)
        } else {
          makePostRepost().then()
          setShowTooltip(false)
        }
      } else {
        elementClicked(e, 'click-community-event-repost-create', {
          community_id: communityId,
          event_id: eventId,
        })
        makeEventRepost().then()
        setShowTooltip(false)
      }
    },
    [
      communityId,
      doEventRepost,
      eventId,
      isContentChecked,
      makePostRepost,
      onRepost,
      repostCommunity?.community,
      repostType,
    ]
  )

  const modals = (
    <div>
      <Modal show={showContentWarning} onHide={() => setShowContentWarning(false)} className={'delete'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>
            {community.name} already has the maximum number of content posts allowed. Remove a content post from{' '}
            {community.name} in order to repost to its Content page.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowContentWarning(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRepostError} onHide={() => setShowRepostError(false)}>
        <Modal.Header closeButton />
        <Modal.Body>There was an error reposting. Please try again.</Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <Button variant="light" size="sm" onClick={() => setShowRepostError(false)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  )

  // noinspection RequiredAttributes
  return (
    <div className={'repost-selection-row'}>
      <div
        className={`community-photo`}
        style={community.photo ? { backgroundImage: `url(${community.photo})` } : {}}
        role={'img'}
        title={community.name}
      />
      <span className={`community-name${isCondensedPortrait ? ' mobile' : ''}`}>{community.name}</span>
      <div className={`right-aligned${isCondensedPortrait ? ' mobile' : ''}`}>
        {showRepostAsContentCheck && (
          <div className={`show-on-content${isCondensedPortrait ? ' mobile' : ''}`}>
            <input
              type="checkbox"
              id={`content-checkbox${communityId}`}
              name="interest"
              onChange={() => setContentChecked(!isContentChecked)}
              checked={isContentChecked}
              role={'checkbox'}
              disabled={!repostEnabled}
            />
            <label htmlFor={`content-checkbox${communityId}`}>Show on Content page</label>
          </div>
        )}
        {!repostEnabled && (
          <Link to={repostLink} className={'link'}>
            {repostType == RepostType.POST ? 'View Repost' : 'View Event Repost'}
          </Link>
        )}
        <OverlayTrigger
          key="bottom"
          placement="bottom"
          show={showTooltip}
          overlay={
            <Tooltip id="tooltip-bottom">
              This will repost the above {repostType == RepostType.POST ? 'post' : 'event'} to this community
            </Tooltip>
          }
        >
          <Button
            onClick={attemptRepost}
            variant="outline-secondary"
            className={`${repostEnabled ? '' : 'checked'}`}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            role="button"
            disabled={!repostEnabled}
          >
            <span>{repostEnabled ? 'Repost' : 'Reposted'}</span>
          </Button>
        </OverlayTrigger>
      </div>
      {modals}
    </div>
  )
}
