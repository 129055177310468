import { useEffect } from 'react'
import { useShortcuts } from '~/contexts/ShortcutContext'

export const useMToFocusComment = (focusComment: () => void, postId: string) => {
  const { selectedPost, postList } = useShortcuts()
  const isEditor = () =>
    document.activeElement?.getAttribute('contenteditable') ||
    document.activeElement?.tagName === 'INPUT' ||
    document.activeElement?.tagName === 'TEXTAREA' ||
    !!document.querySelector('.fade.modal-backdrop.show')

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'm' && !isEditor() && (postId === selectedPost?.postId || !postList?.length)) {
        e.preventDefault()
        focusComment()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [focusComment, postId, postList?.length, selectedPost?.postId])
}
