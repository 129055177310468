import React, { createRef, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { PostRow } from '~/pages/posts/PostRow'
import { Accordion } from 'react-bootstrap'
import { PostAdd } from '~/pages/posts/PostAdd'
import ToastComponent from '~/common/ToastComponent'
import '@css/pages/posts/PostList.scss'
import '@css/common/AuthoringArea.scss'
import { usePermissions } from '~/pages/posts/PostUtils'
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import { CanAddContentDocument, GetContentSortOrderDocument, SetContentOrderDocument } from '~/api/generated/graphql'
import { useCommunity } from '~/contexts/CommunityContext'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { PostImagePreloader } from '~/common/PostImagePreloader'
import { elementClicked, elementClickedNoEvent } from '~/common/EventLogger'
import { useShortcuts } from '~/contexts/ShortcutContext'
import { AddPersonBoxProvider } from '~/common/addPerson/AddPersonBoxContext'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'
import { UnsavedWarningModal } from '~/common/UnsavedWarningModal'
import { useMutation, useQuery } from '@apollo/client'

type Props = {
  onLoadMore?: () => void
  showHasMore: boolean
  postIds: string[] | undefined
  fromContentPage: boolean
  isFollowed?: boolean
  loading?: boolean
  setExpanded: (value: boolean) => void
  showEditor?: boolean
}

export const PostList = ({
  onLoadMore,
  postIds,
  showHasMore,
  fromContentPage,
  isFollowed,
  loading,
  setExpanded,
  showEditor,
}: Props) => {
  const { communityId, newActivity } = useCommunity()
  const [showPostDeletedToast, setShowPostDeletedToast] = useState(false)
  const [wasDraft, setWasDraft] = useState(false)
  const handleDelete = useCallback((isDraft = false) => {
    setWasDraft(isDraft)
    setShowPostDeletedToast(true)
  }, [])
  const handleClosePostDeletedToast = useCallback(() => {
    setWasDraft(false)
    setShowPostDeletedToast(false)
  }, [])
  const [orderedPosts, setOrderedPosts] = useState<string[]>()
  const { hasLeaderPermissions } = usePermissions()
  const { data: sortOrderData, loading: sortOrderLoading } = useQuery(GetContentSortOrderDocument, {
    variables: { id: communityId ?? '' },
    skip: !communityId,
  })
  const { loading: loadingContentCount, data: contentCountData } = useQuery(CanAddContentDocument, {
    variables: { communityId: communityId ?? '' },
    skip: !(communityId && fromContentPage),
  })
  const { ok: canAddContent, count, max } = (!loadingContentCount && contentCountData?.canAddContent) || {}
  const [setContentOrder] = useMutation(SetContentOrderDocument)
  const { isCondensedPortrait: isMobile } = useWindowSize()
  const [rowsExpanded, setRowsExpanded] = useState<Map<string, boolean>>(new Map<string, boolean>())
  const { setPostList, resetSelectedPostState } = useShortcuts()

  const rowExpanded = useCallback(
    (rowId: string, expanded: boolean) => {
      const rows: Map<string, boolean> = rowsExpanded ?? new Map()
      rows.set(rowId, expanded)
      setRowsExpanded(rows)

      if (expanded) {
        if (setExpanded) {
          setExpanded(true)
        }
      } else {
        if (Array.from(rowsExpanded.values()).includes(true)) {
          if (setExpanded) {
            setExpanded(true)
          }
        } else {
          if (setExpanded) {
            setExpanded(false)
          }
        }
      }
    },
    [rowsExpanded, setExpanded]
  )

  useEffect(() => {
    if (postIds) {
      if (fromContentPage) {
        if (!sortOrderData) return
        if (sortOrderData?.community?.contentOrder) {
          let contentOrder = JSON.parse(sortOrderData?.community?.contentOrder) as string[]
          contentOrder = contentOrder.filter(id => postIds.includes(id)) // add posts based on content order
          postIds.forEach(id => {
            // add all other posts not included in the order and append to end
            if (!contentOrder.includes(id)) {
              contentOrder.push(id)
            }
          })
          setOrderedPosts(contentOrder)
          return
        }
      }
      setOrderedPosts(postIds)
    }
  }, [postIds, sortOrderData, sortOrderLoading, fromContentPage, newActivity])

  const reorderPost = (originalIndex: number, newIndex: number): string[] => {
    const orderedPostsCopy = orderedPosts ?? []
    const [removed] = orderedPostsCopy.splice(originalIndex, 1)
    orderedPostsCopy.splice(newIndex, 0, removed)
    return orderedPostsCopy
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const newOrderedPosts = reorderPost(result.source.index, result.destination.index)
    setOrderedPosts(newOrderedPosts)
    setContentOrder({ variables: { communityId: communityId ?? '', contentOrder: newOrderedPosts } }).then()
    elementClickedNoEvent('click-community-content-reordered', { postId: result.destination.droppableId })
  }

  useEffect(() => {
    if (orderedPosts) {
      setPostList?.(orderedPosts)
    }
  }, [orderedPosts, resetSelectedPostState, setPostList])

  useEffect(() => {
    return () => {
      resetSelectedPostState?.()
    }
  }, [resetSelectedPostState])

  const handleLoadMore = (e: SyntheticEvent) => {
    onLoadMore?.()
    elementClicked(e, 'click-posts-show-more')
  }

  const resetScroll = () => {
    postListRef.current?.parentElement?.scrollIntoView()
  }

  const [newDraftId, setNewDraftId] = useState<string>()
  const addPost = (
    <>
      {!fromContentPage || canAddContent ? (
        <PostAdd
          isContent={fromContentPage}
          resetScroll={resetScroll}
          setNewDraftId={postId => setNewDraftId(postId)}
        />
      ) : (
        hasLeaderPermissions &&
        max && (
          <p>
            Maximum number of content posts reached ({count}/{max})
          </p>
        )
      )}
    </>
  )

  const postListRef = createRef<HTMLDivElement>()
  const [clickedRow, setClickedRow] = useState('')
  const [activeRows, setActiveRows] = useState<string[]>([])
  const [showWarningModal, setShowWarningModal] = useState(false)

  const { checkDraftingComment, resetPostComments } = useDraftingCommentPost()

  const onSelect = (keys: string[]) => {
    // If a row was collapsed, it will not be listed in the keys. We can find which row was collapsed by looking at the difference from the activeRows
    const collapsedRow = activeRows.filter(i => !keys.includes(i))?.[0]
    if (collapsedRow && checkDraftingComment?.(collapsedRow)) {
      setClickedRow(collapsedRow)
      setShowWarningModal(true)
    } else {
      setActiveRows(keys)
    }
  }

  const collapsePost = () => {
    setShowWarningModal(false)
    resetPostComments?.(clickedRow)
    setActiveRows(activeRows.filter(i => i != clickedRow))
  }

  if (!orderedPosts || (fromContentPage && (!contentCountData || loading)))
    return (
      <>
        <div className="no-posts">Loading...</div>
      </>
    )

  return (
    <>
      <AddPersonBoxProvider>{addPost}</AddPersonBoxProvider>
      {postIds && postIds.length > 0 ? (
        <div ref={postListRef} className={`posts-section ${isMobile ? 'mobile' : ''}`}>
          <PostImagePreloader postIds={postIds} />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'droppable'}>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Accordion alwaysOpen={true} flush className={'post-list'} activeKey={activeRows} onSelect={onSelect}>
                    {orderedPosts?.map((postId, index) =>
                      hasLeaderPermissions && fromContentPage ? (
                        <Draggable draggableId={postId} index={index} key={postId}>
                          {provided => (
                            <PostRow
                              postId={postId}
                              eventKey={postId}
                              onDelete={handleDelete}
                              resetScroll={resetScroll}
                              isHomeFeed={false}
                              fromContentPage={fromContentPage}
                              dragHandleProps={provided.dragHandleProps}
                              draggableProps={provided.draggableProps}
                              draggableInnerRef={provided.innerRef}
                              rowExpanded={rowExpanded}
                              showEditor={fromContentPage || showEditor}
                              isNewDraft={postId === newDraftId}
                              showDrag={orderedPosts.length > 1}
                            />
                          )}
                        </Draggable>
                      ) : (
                        <PostRow
                          postId={postId}
                          eventKey={postId}
                          onDelete={handleDelete}
                          resetScroll={resetScroll}
                          isHomeFeed={false}
                          fromContentPage={fromContentPage}
                          key={postId}
                          rowExpanded={rowExpanded}
                          showEditor={fromContentPage || showEditor}
                          isNewDraft={postId === newDraftId}
                        />
                      )
                    )}
                  </Accordion>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {loading ? (
            <div className={'loading show-more-loading'}>Loading...</div>
          ) : (
            showHasMore && (
              <div className={'load-more'}>
                <button className={'btn btn-primary btn-sm'} onClick={handleLoadMore}>
                  <strong>Show more posts</strong>
                </button>
              </div>
            )
          )}
        </div>
      ) : isFollowed ? (
        <div className={'no-posts'}>Follow posts to stay up-to-date on conversations important to you.</div>
      ) : (
        <div className="no-posts">This community has no {fromContentPage ? 'content' : 'posts'} </div>
      )}
      <ToastComponent onClose={handleClosePostDeletedToast} show={showPostDeletedToast} bg={'success'}>
        {`The ${wasDraft ? 'draft' : 'post'} was deleted successfully.`}
      </ToastComponent>
      <UnsavedWarningModal
        showWarningModal={showWarningModal}
        onCancel={() => setShowWarningModal(false)}
        onContinue={collapsePost}
        leavingPost={true}
      />
    </>
  )
}
