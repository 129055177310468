import React, { FocusEvent, SyntheticEvent, useMemo, useRef, useState } from 'react'
import '@css/common/AddPersonBox.scss'
import { AuthorFacet } from '~/pages/search/AdvancedSearchSidebar'
import { FacetResponse } from '~/pages/search/AdvancedSearch'
import { useApolloClient } from '@apollo/client'
import { Suggestion } from '~/common/quill/QuillEditor'
import { FacetSuggestion } from '~/pages/search/FacetSuggestion'
import { Form } from 'react-bootstrap'
import { GetSuggestionUserDocument, GetSuggestionUserQuery } from '~/api/generated/graphql'
import { asUser, getFullName } from '~/utils'

type AddAuthorBoxProps = {
  searchText: string
  placeholder: string
  isCondensed: boolean
  selectedAuthors: AuthorFacet[]
  responseFacets: FacetResponse[] | undefined
  onTextChange: (inputText: string) => void
  onAddAuthor: (authorFacet?: AuthorFacet, e?: SyntheticEvent) => void
}

const AddAuthorBox = ({
  searchText,
  placeholder,
  isCondensed,
  selectedAuthors,
  responseFacets,
  onTextChange,
  onAddAuthor,
}: AddAuthorBoxProps) => {
  const client = useApolloClient()
  const [selectedSuggestion, setSelectedSuggestion] = useState(0)
  const inputRef = useRef<HTMLInputElement>(null)
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)

  const suggestions = useMemo(() => {
    const excludeIds = new Set(selectedAuthors.map(c => c.authorId))
    return (
      responseFacets
        ?.filter(f => !excludeIds.has(f.key))
        .map(f => {
          const user = client.cache.readQuery<GetSuggestionUserQuery>({
            query: GetSuggestionUserDocument,
            variables: { id: f.key },
          })?.user
          return {
            id: user?.userId ?? '',
            user: asUser(user),
            value: getFullName(user),
            link: `/profiles/${user?.userId}`,
            photo: user?.photo ?? null,
            isVeevan: user?.isVeevan,
            count: f.count,
          }
        }) ?? []
    )
  }, [responseFacets, client.cache, selectedAuthors])

  const handleAddAuthorTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value
    onTextChange(search)
  }

  const getSuggestionList = () => {
    return suggestions.map((s, i) => {
      return (
        <div key={s.user?.userId}>
          <FacetSuggestion
            index={i}
            suggestion={s}
            selectedSuggestion={selectedSuggestion}
            selectSuggestion={e => selectSuggestion(s, e)}
          />
        </div>
      )
    })
  }

  const handleInputKeyPresses = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && suggestions.length) {
      selectSuggestion(suggestions[selectedSuggestion], e)
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      setSelectedSuggestion(Math.min(selectedSuggestion + 1, suggestions.length - 1))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setSelectedSuggestion(Math.max(selectedSuggestion - 1, 0))
    }
  }

  const selectSuggestion = (suggestion: Suggestion, e: SyntheticEvent) => {
    onTextChange('')
    const author = {
      authorId: suggestion.id,
      name: `${suggestion.user?.firstName} ${suggestion.user?.lastName}`,
    }
    onAddAuthor(author as AuthorFacet, e)
  }

  const handleBlur = (e: FocusEvent) => {
    // Don't blur if child elements (author list items) are focused
    if (!e.currentTarget?.contains(e.relatedTarget)) {
      setShowSuggestions(false)
    }
  }

  const handleFocus = () => {
    setShowSuggestions(true)
  }

  return (
    <div className={`add-author ${isCondensed ? 'condensed' : ''}`} data-testid={'add-author-facet'}>
      <div className="input-container" onBlur={handleBlur}>
        <Form.Control
          type="text"
          placeholder={placeholder}
          onChange={handleAddAuthorTextChanged}
          value={searchText}
          onKeyDown={handleInputKeyPresses}
          role="input"
          onFocus={handleFocus}
          ref={inputRef}
          data-testid={'add-author-input'}
          name="search"
        />
        {Boolean(suggestions.length) && showSuggestions && (
          <div className="suggestion-list">
            <ul>{getSuggestionList()}</ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddAuthorBox
