import React, { useState } from 'react'
import {
  PageType,
  AddPostToSummitCategoryDocument,
  EditSummitPostTitleDocument,
  GetCategoryPostDocument,
} from '~/api/generated/graphql'
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'
import { Maybe } from '~/api/generated/graphql'
import dragDropIcon from '@web/images/posts/DragDrop.svg'
import { elementClicked } from '~/common/EventLogger'
import { Link } from 'react-router-dom'
import pencilIcon from '@web/images/community/pencil-icon.svg'
import { Button } from 'react-bootstrap'
import PlainTextInput from '~/common/PlainTextInput'
import { getFullName, trimFromDelimiters } from '~/utils'
import { SummitTitleErrorType, useSummitCategoryPosts } from '~/contexts/SummitCategoryPostsContext'
import { calculateFieldLengthSeverity } from '~/pages/posts/PostUtils'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import FormError from '~/common/FormError'
import { useAuth } from '~/auth/Auth'
import { useMutation, useQuery } from '@apollo/client'

type CategoryPostType = {
  postId: string
  communityId: string
  contentTitle: string
  draft: boolean
  summitTitle: string
  hidden: boolean
  createdBy: {
    userId: string
    firstName: string
    lastName: string
    nickName: string
  }
  community: {
    communityId: string
    name: string
  }
}

type CategoryPostProps = {
  postId: string
  releaseName: string
  isEditing: boolean
  draggableProps: DraggableProvidedDraggableProps
  dragHandleProps?: Maybe<DraggableProvidedDragHandleProps>
  draggableInnerRef: (element: HTMLElement | null) => void
  isDragging: boolean
  onRemove: () => void
  isEditingSummitTitle: boolean
  releaseId: string
  isUnderSubcategory: boolean
  pendingSummitPostIds?: string[]
  setPendingSummitPostIds?: React.Dispatch<React.SetStateAction<string[]>>
  categoryId: string
  postOrder: string[]
}

type CategoryPostRowProps = {
  post: CategoryPostType
  releaseName: string
  isEditing: boolean
  draggableProps: DraggableProvidedDraggableProps
  dragHandleProps?: Maybe<DraggableProvidedDragHandleProps>
  draggableInnerRef: (element: HTMLElement | null) => void
  isDragging: boolean
  onRemove: () => void
  isEditingSummitTitle: boolean
  releaseId: string
  isUnderSubcategory: boolean
  pendingSummitPostIds?: string[]
  setPendingSummitPostIds?: React.Dispatch<React.SetStateAction<string[]>>
  categoryId: string
  postOrder: string[]
}

declare global {
  interface Window {
    scrollPosition: number
    lastId: string
  }
}

const CategoryPostRow = ({
  post,
  releaseName,
  isEditing,
  draggableProps,
  draggableInnerRef,
  dragHandleProps,
  isDragging,
  onRemove,
  isEditingSummitTitle,
  releaseId,
  isUnderSubcategory,
  pendingSummitPostIds,
  setPendingSummitPostIds,
  categoryId,
  postOrder,
}: CategoryPostRowProps) => {
  const { pageType, editModePostIds, updateEditModeRows, postsWithErrors, updateAllPendingRows, removePostWithError } =
    useSummitCategoryPosts()
  const [editingSummitTitle, setEditingSummitTitle] = useState(isEditingSummitTitle)
  const postId = post?.postId || ''
  const title = post?.contentTitle || ''
  const summitTitle = post?.summitTitle || title
  const isSummit = pageType === PageType.Summit
  const justAdded = pendingSummitPostIds?.includes(postId)
  const [pendingSummitTitle, setPendingSummitTitle] = useState(
    justAdded ? trimFromDelimiters(summitTitle) : summitTitle
  )
  const titlePhrase = `by ${getFullName(post?.createdBy)} in ${post?.community?.name}`
  const { isCondensed } = useWindowSize()

  const [editSummitTitle] = useMutation(EditSummitPostTitleDocument)
  const { actingRelAdmin, actingSummitAdmin } = useAuth()
  const canSeeDrafts = pageType == PageType.Release ? actingRelAdmin : actingSummitAdmin

  const showError = postsWithErrors?.has(postId)
  const errorMessage =
    editModePostIds?.get(postId) === SummitTitleErrorType.NO_TITLE
      ? 'Required'
      : 'Press Cancel or Save to complete changes'
  const inPendingEditList = editModePostIds?.has(postId)
  const [addPostToSummitCategory] = useMutation(AddPostToSummitCategoryDocument)
  const onSaveSummitTitle = () => {
    const filteredPostOrder = postOrder.filter(p => p === postId || !pendingSummitPostIds?.includes(p))
    if (justAdded) {
      addPostToSummitCategory({
        variables: {
          categoryId: categoryId,
          postId: postId,
          postOrder: filteredPostOrder,
          summitTitle: pendingSummitTitle,
          filter: canSeeDrafts ? {} : { draft: false },
        },
      }).then(resp => {
        if (resp.data?.addPostToSummitCategory?.ok) {
          removePostWithError?.(postId)
          setEditingSummitTitle(false)
          updateEditModeRows?.(postId, true)
          updateAllPendingRows?.(postId, true)
          setPendingSummitPostIds?.(pendingIds => {
            const newIds = [...pendingIds]
            newIds.splice(newIds.indexOf(postId), 1)
            return newIds
          })
        } else {
          removePostWithError?.(postId)
        }
      })
    } else {
      editSummitTitle({ variables: { postId: postId, summitTitle: pendingSummitTitle } }).then(resp => {
        if (resp?.data?.editSummitPostTitle?.ok) {
          removePostWithError?.(postId)
          setEditingSummitTitle(false)
          updateEditModeRows?.(postId, true)
        } else {
          removePostWithError?.(postId)
        }
      })
    }
  }

  const onClickEditSummitTitle = () => {
    setEditingSummitTitle(true)
  }

  const onEditSummitTitle = (s: string) => {
    removePostWithError?.(postId)
    setPendingSummitTitle(s)

    if (inPendingEditList && s === summitTitle) {
      updateEditModeRows?.(postId, true, s ? SummitTitleErrorType.NEW_UNSAVED : SummitTitleErrorType.NO_TITLE)
    } else if (s !== summitTitle) {
      updateEditModeRows?.(postId, false, s ? SummitTitleErrorType.NEW_UNSAVED : SummitTitleErrorType.NO_TITLE)
    }
  }

  const onCancel = () => {
    removePostWithError?.(postId)
    setEditingSummitTitle(false)
    setPendingSummitTitle(summitTitle.trim())
    updateEditModeRows?.(postId, true)
    updateAllPendingRows?.(postId, true)
    if (justAdded) {
      setPendingSummitPostIds?.(pendingIds => {
        const newIds = [...pendingIds]
        newIds.splice(newIds.indexOf(postId), 1)
        return newIds
      })
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    if (isEditing) {
      e.preventDefault()
      return
    }
    elementClicked(e, `click-post-${isSummit ? 'summit' : 'release'}-view`, {
      communityId: post?.communityId,
      postId: postId,
    })
    window.scrollPosition = document.body.children[0].scrollTop
    window.lastId = releaseId
  }

  const MAX_TITLE_LENGTH = 100
  const titleLength = (pendingSummitTitle ? pendingSummitTitle : summitTitle).length
  const titleSeverity = editingSummitTitle ? calculateFieldLengthSeverity(titleLength, MAX_TITLE_LENGTH) : ''

  return isEditing ? (
    <div
      className={`post-row-container${isDragging ? ' dragging' : ''}${isSummit ? ' summit' : ''}${
        isUnderSubcategory ? ' indented' : ''
      }${isCondensed ? ' condensed' : ''}`}
      ref={draggableInnerRef}
      {...draggableProps}
      onClick={handleClick}
    >
      <div className={`post-row ${isEditing ? 'editing' : ''}`}>
        <img
          alt={isEditing ? 'reorder drag handle' : ''}
          src={dragDropIcon}
          {...dragHandleProps}
          className={'drag-handle'}
          data-testid={'dnd-handle'}
        />
        {isSummit &&
          (editingSummitTitle ? (
            <div className={'plain-text-input-container'}>
              <PlainTextInput
                placeholder={'Add a summit title for the post'}
                onChange={onEditSummitTitle}
                value={pendingSummitTitle}
                className={`${isCondensed ? 'condensed' : ''} ${showError ? 'summit-title-error' : ''}`}
              />
              <span
                className={`summit-character-counter ${isCondensed ? 'condensed' : ''} ` + titleSeverity}
                id={'summit-character-counter'}
              >
                {titleLength < 10 ? `0${titleLength}` : titleLength} / {MAX_TITLE_LENGTH}
              </span>
            </div>
          ) : (
            <div className={'summit-title-container'}>
              <span className={`post-title editing`}>{summitTitle}</span>
            </div>
          ))}
        <div className={'content-title-container editing'}>
          {post?.draft && <div className={`draft-indicator editing ${isSummit ? '' : 'on-release'}`}>DRAFT</div>}
          <span
            className={`post-title ${!post?.draft && 'editing'}${post?.hidden ? ' hidden' : ''} ${
              isSummit ? 'post-title-on-summit' : ''
            }`}
          >
            {title}
          </span>
          {isSummit && <span className={'title-phrase'}> {titlePhrase}</span>}
        </div>
        <div className={'category-post-edit-buttons'}>
          {editingSummitTitle ? (
            <>
              <Button variant="light" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onSaveSummitTitle}
                disabled={!pendingSummitTitle || titleLength > MAX_TITLE_LENGTH}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              {pageType === PageType.Summit && (
                <button
                  onClick={onClickEditSummitTitle}
                  className={'category-edit-post'}
                  data-testid={'category-edit-post'}
                >
                  <img alt={'edit'} src={pencilIcon} data-testid={'edit-pencil'} />
                </button>
              )}
              <button onClick={onRemove} data-testid={'category-remove-post'}>
                X
              </button>
            </>
          )}
        </div>
      </div>
      {showError && <FormError message={errorMessage} />}
    </div>
  ) : (
    <div
      className={`post-row-container${isDragging ? ' dragging' : ''}${isUnderSubcategory ? ' indented' : ''}${
        isCondensed ? ' condensed' : ''
      }`}
    >
      <Link
        className={`post-row`}
        ref={draggableInnerRef}
        {...draggableProps}
        onClick={handleClick}
        to={`/communities/${post?.communityId}/content/${post?.postId}`}
        state={{ fromReleaseName: releaseName }}
      >
        <img
          alt={isEditing ? 'reorder drag handle' : ''}
          src={dragDropIcon}
          {...dragHandleProps}
          className={'drag-handle hidden'}
        />
        <div className={'content-title-container'}>
          {post?.draft && <div className={'draft-indicator'}>DRAFT</div>}
          <span className={`post-title${post?.hidden ? ' hidden' : ''}`}>
            {pageType === PageType.Summit ? summitTitle : title}
          </span>
        </div>
        <span className={'btn btn-primary view-link'}>View</span>
      </Link>
    </div>
  )
}

const CategoryPost = ({
  postId,
  releaseName,
  isEditing,
  draggableProps,
  draggableInnerRef,
  dragHandleProps,
  isDragging,
  onRemove,
  isEditingSummitTitle,
  releaseId,
  isUnderSubcategory,
  setPendingSummitPostIds,
  categoryId,
  postOrder,
  pendingSummitPostIds,
}: CategoryPostProps) => {
  const { data, loading } = useQuery(GetCategoryPostDocument, { variables: { id: postId } })
  const post = {
    postId: data?.post?.postId ?? '',
    communityId: data?.post?.communityId ?? '',
    contentTitle: data?.post?.contentTitle ?? '',
    draft: Boolean(data?.post?.draft),
    summitTitle: data?.post?.summitTitle ?? '',
    hidden: Boolean(data?.post?.hidden),
    createdBy: {
      userId: data?.post?.createdBy?.userId ?? '',
      firstName: data?.post?.createdBy?.firstName ?? '',
      lastName: data?.post?.createdBy?.lastName ?? '',
      nickName: data?.post?.createdBy?.nickName ?? '',
    },
    community: {
      communityId: data?.post?.community?.communityId ?? '',
      name: data?.post?.community?.name ?? '',
    },
  } as CategoryPostType

  if (loading && !data) {
    return <></>
  }

  return (
    <CategoryPostRow
      post={post}
      releaseName={releaseName}
      isEditing={isEditing}
      isDragging={isDragging}
      onRemove={onRemove}
      isEditingSummitTitle={isEditingSummitTitle}
      releaseId={releaseId}
      isUnderSubcategory={isUnderSubcategory}
      draggableProps={draggableProps}
      draggableInnerRef={draggableInnerRef}
      dragHandleProps={dragHandleProps}
      setPendingSummitPostIds={setPendingSummitPostIds}
      categoryId={categoryId}
      postOrder={postOrder}
      pendingSummitPostIds={pendingSummitPostIds}
    />
  )
}

export default CategoryPost
