import React, { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react'
import { EventCalendarIconComponent } from '~/common/EventCalendarIconComponent'
import {
  EditEventDocument,
  GetEventCommunityDocument,
  GetEventDocument,
  SetHideEventRepostDocument,
} from '~/api/generated/graphql'
import CommunityEventEdit from '~/pages/community/CommunityEventEdit'
import '@css/pages/community/CommunityEventRow.scss'
import { asDate, asHtmlWithMentions, asString, formatLinks, getStartOfCurrentDay } from '~/utils'
import { Maybe } from '~/api/generated/graphql'
import { Link } from 'react-router-dom'
import TimeAgo from '~/common/TimeAgo'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { KebabToggle } from '~/common/KebabToggle'
import { elementClicked } from '~/common/EventLogger'
import { EventDropdownItems } from '~/pages/community/EventDropdownItems'
import { useNavigate } from 'react-router'
import { EventRepostLink } from '~/common/RepostOverlay'
import { UserLink } from '~/common/UserLink'
import { useAuth } from '~/auth/Auth'
import MentionableText from '~/common/MentionableText'
import ToastComponent from '~/common/ToastComponent'
import { useMutation, useQuery } from '@apollo/client'

type CommunityEventRowProps = {
  eventId?: Maybe<string>
  canViewInfo: boolean
  handleJoinCommunity?: (e: SyntheticEvent) => void
  canEdit: boolean
  communityId: string
  setToast?: (toast: string) => void
  fromRepostPage?: boolean
  isVeevan?: boolean
  canRepost: boolean
  onEditingChange?: (isEditing: boolean, eventId?: string | null) => void
  canDelete: boolean
  scrollToEvent?: string
  scrolled?: boolean
  setScrolled?: (b: boolean) => void
}

const CommunityEventRow = ({
  eventId,
  canViewInfo,
  handleJoinCommunity,
  fromRepostPage,
  communityId,
  setToast,
  canEdit,
  canRepost,
  onEditingChange,
  canDelete,
  isVeevan,
  scrollToEvent,
  scrolled,
  setScrolled,
}: CommunityEventRowProps) => {
  const { authUserId } = useAuth()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { data: eventData } = useQuery(GetEventDocument, { variables: { id: eventId ?? '' }, skip: !eventId })
  const event = eventData?.event
  const repostUserId = fromRepostPage ? authUserId : event?.createdById
  const repostCommunityId = fromRepostPage ? event?.communityId : event?.repost?.communityId
  const { data: repostCommunity } = useQuery(GetEventCommunityDocument, {
    variables: { id: repostCommunityId ?? '' },
    skip: !repostCommunityId,
  })

  const getEventTimeText = (start?: Date, end?: Date): string => {
    if (!start || !end) return ''
    // typescript complains when defining the localeString options object non-inline even if it is valid.
    // Using ts-ignore to ignore these errors since they make the code a little messier to eliminate
    const optionswDate = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit' }
    const isSameDay = start.getDay() === end.getDay() && start.getMonth() === end.getMonth()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const startText = start.toLocaleString([], optionswDate)
    const endText = isSameDay
      ? end.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        end.toLocaleString([], { ...optionswDate, timeZoneName: 'short' })
    return `${startText} - ${endText}`
  }

  const navigate = useNavigate()

  const reposts = event?.reposts?.filter(e => !e?.hidden)
  const hasReposts = !!(reposts && reposts.length > 0)

  const repostedList = useMemo(() => {
    if (reposts?.length == 1) {
      return (
        <span>
          <EventRepostLink eventId={reposts[0]?.eventId} />.
        </span>
      )
    } else if (reposts?.length == 2) {
      return (
        <span>
          <EventRepostLink eventId={reposts[0]?.eventId} /> and <EventRepostLink eventId={reposts[1]?.eventId} />.
        </span>
      )
    } else if (reposts?.length == 3) {
      return (
        <span>
          <EventRepostLink eventId={reposts[0]?.eventId} />, <EventRepostLink eventId={reposts[1]?.eventId} />
          , and <EventRepostLink eventId={reposts[2]?.eventId} />.
        </span>
      )
    } else {
      return (
        <span>
          <EventRepostLink eventId={reposts?.[0]?.eventId} />, <EventRepostLink eventId={reposts?.[1]?.eventId} />, and{' '}
          <Link to={`./${eventId}/repost`}>{reposts?.length ? reposts?.length - 2 : 0} other</Link> communities.
        </span>
      )
    }
  }, [reposts, eventId])

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false)
  const handleClickDelete = () => setShowDeleteDialog(true)
  const handleCancelDelete = () => setShowDeleteDialog(false)
  const handleClickEdit = (e: React.MouseEvent) => {
    elementClicked(e, 'click-community-event-edit', {
      community_id: communityId,
      event_id: event?.eventId,
    })
    setIsEditing(true)
    onEditingChange?.(true, eventId)
  }

  const singleEventPath = `/communities/${communityId}/events`
  const handleClickRepost = (e: React.MouseEvent) => {
    elementClicked(e, 'click-community-event-repost', {
      community_id: communityId,
      event_id: event?.eventId,
    })
    navigate(`${singleEventPath}/${event?.eventId}/repost`)
  }

  const [toastMessage, setToastMessage] = useState<string | null>(null)
  const handleClickCopy = (e: React.MouseEvent) => {
    const permalink = `${document.location.origin}${singleEventPath}?e=${event?.eventId}`
    navigator.clipboard.writeText(permalink).then(() => setToastMessage('Link to event copied to clipboard.'))
    elementClicked(e, 'click-event-copy-link', {
      event_id: event?.eventId,
    })
  }

  const eventRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollToEvent === eventId && eventRef && !scrolled) {
      const y = (eventRef.current?.getBoundingClientRect()?.top ?? 0) - 60 // account for app header height
      document.body.children[0].scrollTo({ top: y, behavior: 'smooth' })
      setScrolled?.(true)
    }
  }, [scrollToEvent, eventRef, scrolled, setScrolled, eventId])

  const [editEvent] = useMutation(EditEventDocument)
  const [hideRepost] = useMutation(SetHideEventRepostDocument)
  const handleConfirmDelete = async (e: SyntheticEvent) => {
    setButtonsDisabled(true)
    if (event?.repost?.eventId) {
      await hideRepost({ variables: { eventId: event?.eventId || '', value: true, date: getStartOfCurrentDay() } })
    } else {
      await editEvent({
        variables: { hidden: true, eventId: event?.eventId || '', date: getStartOfCurrentDay(), notify: false },
      })
    }
    setButtonsDisabled(false)
    setToast?.('Event deleted')
    elementClicked(e, 'click-community-event-delete', { community_id: communityId, event_id: event?.eventId })
    setShowDeleteDialog(false)
  }

  const handleClose = () => {
    setIsEditing(false)
    onEditingChange?.(false, eventId)
  }

  return (
    <>
      {isEditing ? (
        <CommunityEventEdit communityId={communityId} onClose={handleClose} event={event} setToast={setToast} />
      ) : (
        <div className={`event-row ${event?.hidden ? 'deleted' : ''}`} ref={eventRef}>
          <EventCalendarIconComponent eventStart={event?.eventStart} />
          <div className="event-info">
            {canViewInfo && (
              <div className="event-time">{getEventTimeText(asDate(event?.eventStart), asDate(event?.eventEnd))}</div>
            )}
            <div className="title">{event?.title}</div>
            {isVeevan && (event?.repost?.eventId || fromRepostPage) && (
              <div className={'repost-message'}>
                <div className={'repost-image'} />
                <ProfilePhoto userId={repostUserId} />
                <div>
                  <UserLink userId={repostUserId} />
                  reposted from the
                  <Link
                    to={`/communities/${repostCommunity?.community?.communityId}/events`}
                  >{` ${repostCommunity?.community?.name} `}</Link>
                  community <TimeAgo time={event?.createdTime} />
                </div>
              </div>
            )}
            {isVeevan && hasReposts && !fromRepostPage && (
              <span className={'reposted-list'}>
                <i>This event has been reposted in {repostedList}</i>
              </span>
            )}
            {canViewInfo && (
              <>
                <div className="location">{formatLinks(event?.location)}</div>
                <div className="description quill-editor-elements">
                  {/*Event descriptions don't include @ mentions but are still created from the quill editor. */}
                  {/*We want to treat links the same as we do in the post stories. */}
                  <MentionableText value={asHtmlWithMentions(asString(event?.description))} />
                </div>
              </>
            )}
            {!canViewInfo && (
              <div>
                <span className="join-community" onClick={handleJoinCommunity}>
                  {'Join this community'}
                </span>{' '}
                to see full event details.
              </div>
            )}
          </div>
          {!fromRepostPage && (
            <div className={`post-context-menu-area`}>
              <Dropdown align={'end'}>
                <Dropdown.Toggle as={KebabToggle} />
                <EventDropdownItems
                  eventId={''}
                  onDelete={handleClickDelete}
                  onClickEdit={handleClickEdit}
                  onClickRepost={handleClickRepost}
                  onClickCopy={handleClickCopy}
                  canEdit={!event?.repost?.eventId && canEdit}
                  canRepost={!event?.repost?.eventId && canRepost}
                  canDelete={canDelete || event?.createdById === authUserId}
                  isRepost={Boolean(event?.repost?.eventId)}
                />
              </Dropdown>
            </div>
          )}
          <Modal show={showDeleteDialog} onHide={handleCancelDelete} className={'delete'}>
            <Modal.Header closeButton />
            <Modal.Body>
              <p className={'message'}>
                Are you sure you want to delete this {event?.repost?.eventId ? 'repost' : 'event'}?
              </p>
              {event?.repost?.eventId && (
                <p className={'sub-message'}>
                  Deleting this repost will not affect the original event or other reposts
                </p>
              )}
              {hasReposts && (
                <p className={'notation warning'}>
                  Deleting this event will also delete {reposts?.length} event reposts.{' '}
                  <Link to={`/communities/${communityId}/events/${event?.eventId}/repost`}>View reposts</Link>
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" size="sm" onClick={handleCancelDelete}>
                Cancel
              </Button>
              <Button variant="primary" size="sm" onClick={handleConfirmDelete} disabled={buttonsDisabled}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <ToastComponent onClose={() => setToastMessage(null)} show={!!toastMessage}>
        {toastMessage ?? ''}
      </ToastComponent>
    </>
  )
}

export default CommunityEventRow
