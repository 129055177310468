import Quill from 'quill'
import Delta, { Op } from 'quill-delta'

// Workaround for bugged Google Keyboard interaction with Quill: https://github.com/quilljs/quill/issues/3240#issuecomment-1326042513
export class SupportGBoard {
  private quill: Quill

  constructor(quill: Quill) {
    this.quill = quill
    quill.on('editor-change', this.handle.bind(this))
  }

  handle(eventName: string, delta: Delta) {
    if (eventName === 'text-change') {
      const ops = delta.ops
      if (!ops) return

      const oldSelection = this.quill.getSelection()
      const oldPos = oldSelection?.index
      const oldSelectionLength = oldSelection?.length

      let retain: Op['retain'] = undefined
      let insert: Op['insert'] = undefined
      for (let j = 0; j < ops.length; j++) {
        const keys = Object.keys(ops[j])
        if (keys.includes('retain') && retain === undefined) {
          retain = ops[j]['retain']
        }
        if (keys.includes('insert')) {
          insert = ops[j]['insert']
        }
      }

      if (retain === undefined || insert === undefined || insert != '\n' || (oldSelectionLength ?? 0) > 0) {
        return
      }

      setTimeout(() => {
        try {
          const newPos = this.quill.getSelection(true).index
          if (newPos === oldPos) {
            console.log('Change selection bad pos')
            this.quill.setSelection(this.quill.getSelection(true).index + 1, 0)
          }
        } catch (err) {
          this.quill.focus()
        }
      }, 30)
    }
  }
}
