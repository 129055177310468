import usePlacesAutocomplete from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react'
import useKeyPress, { KeyPressKeys } from '~/common/hooks/useKeyPress'
import '@css/pages/profile/PlacesAutocomplete.scss'

import { Form } from 'react-bootstrap'

export const PlacesAutocomplete = ({
  city,
  setCity,
  setState,
  setCountry,
  disabled,
}: {
  city?: string
  setCity: (val: string) => void
  setState?: (val: string) => void
  setCountry?: (val: string) => void
  disabled?: boolean
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue: city,
    requestOptions: {
      types: ['(cities)'],
      language: 'en',
    },
    debounce: 300,
  })
  const ref = useOnclickOutside(() => {
    // When user clicks outside the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const [cursor, setCursor] = useState<number>()
  const downPress = useKeyPress(KeyPressKeys.downArrow)
  const upPress = useKeyPress(KeyPressKeys.upArrow)
  const enterPress = useKeyPress(KeyPressKeys.enter)

  useEffect(() => {
    if (data.length && downPress) {
      setCursor(prevState => ((prevState ?? data.length) < data.length - 1 ? (prevState ?? 0) + 1 : 0))
    }
  }, [downPress, data.length])

  useEffect(() => {
    if (data.length && upPress) {
      setCursor(prevState => ((prevState ?? 0) > 0 ? (prevState ?? 0) - 1 : data.length - 1))
    }
  }, [upPress, data.length])

  const handleInput: ChangeEventHandler<HTMLInputElement> = e => {
    // Update the keyword of the input element
    setCity(e.target.value)
    setValue(e.target.value)
  }

  const handleSelect = useCallback(
    (selection: google.maps.places.AutocompletePrediction) => () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      const request = {
        placeId: selection.place_id,
        fields: ['adr_address', 'address_component'],
      }

      const service = new google.maps.places.PlacesService(document.createElement('div'))
      service.getDetails(request, r => {
        r?.address_components?.forEach(c => {
          if (c.types.includes('country') && setCountry) {
            setCountry(c.long_name)
          }
          if (c.types.includes('locality') && setCity) {
            setCity(c.long_name)
            setValue(c.long_name, false)
          }
          if (c.types.includes('administrative_area_level_1') && setState) {
            setState(c.long_name)
          }
        })
      })
      clearSuggestions()
      setCursor(undefined)
    },
    [clearSuggestions, setCity, setCountry, setState, setValue]
  )

  useEffect(() => {
    if (enterPress && cursor !== undefined) {
      const selection = data.at(cursor)
      if (selection) handleSelect(selection)()
    }
  }, [enterPress, cursor, data, handleSelect])

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li
          key={place_id}
          className={`dropdown-item ${index === cursor ? 'hovered' : ''}`}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })

  return (
    <div ref={ref}>
      <Form.Control
        value={value}
        onChange={handleInput}
        maxLength={255}
        disabled={!ready || disabled}
        placeholder=""
        role="form-city"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <ul className={'dropdown-menu'} style={{ display: 'block' }}>
          {renderSuggestions()}
        </ul>
      )}
    </div>
  )
}
