import React, { useCallback } from 'react'
import { PopOverPlay } from '~/pages/posts/MediaComponent'
import { Maybe } from '~/api/generated/graphql'
import { updateWistiaLink, getWistiaId } from '~/utils'

type VideoScanPopoverProps = {
  mediaUrl: Maybe<string>
  playTime?: Maybe<number>
}

const VideoScanPopover = ({ mediaUrl, playTime }: VideoScanPopoverProps) => {
  const wistiaUrl = updateWistiaLink(mediaUrl)
  const wistiaId = getWistiaId(wistiaUrl)

  const pushWistia = useCallback(() => {
    const a = {
      id: `${wistiaId}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onReady: function (video: any) {
        video.bind('popovershow', function () {
          window.addEventListener('popstate', () => {
            video.popover.hide()
          })
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Wistia.api(wistiaId).time(playTime)
      },
    }
    window._wq = window._wq || []
    window._wq.push(a)
  }, [wistiaId, playTime])

  if (!wistiaId || playTime === null || playTime === undefined) return <div data-testid={'not-playing'}></div>

  return <PopOverPlay wistiaId={wistiaId} pushWistia={pushWistia} />
}

export default VideoScanPopover
