import React from 'react'

// Custom hook, preserves values in sessionStorage until refresh
// Inspiration: https://www.joshwcomeau.com/snippets/react-hooks/use-sticky-state/
export const useStickyState = (defaultValue: string, key: string) => {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.sessionStorage.getItem(key)
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
  })
  React.useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])
  return [value, setValue]
}

export const removeSessionStorageItem = (key: string) => {
  sessionStorage.removeItem(key)
}
