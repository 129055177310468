import { Button, Modal } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import '@css/pages/posts/PostConvertModal.scss'
import {
  HtmlWithMentions,
  Post,
  PostType,
  Maybe,
  CanAddContentDocument,
  ConvertPostDocument,
  GetPostConvertDocument,
} from '~/api/generated/graphql'
import {
  calculateFieldLengthSeverity,
  removeCacheContent,
  updateCacheContent,
  updateCachePosts,
} from '~/pages/posts/PostUtils'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import { asHtmlWithMentions, asString, cleanupHTML, htmlToText } from '~/utils'
import { useCommunity } from '~/contexts/CommunityContext'
import ToastComponent from '~/common/ToastComponent'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useAuth } from '~/auth/Auth'

type PostConvertModalProps = {
  show: boolean
  onConvert: (succeeded: boolean, wasContent: boolean) => void
  postId: string
  wasContent: boolean
  existingContentTitle?: string | null
  existingPostTitle?: string | null
}

const PostConvertModal = ({
  show,
  onConvert,
  postId,
  wasContent,
  existingContentTitle,
  existingPostTitle,
}: PostConvertModalProps) => {
  const { communityId } = useCommunity()
  const { authUserId, actingRelAdmin } = useAuth()
  const [contentTitle, setContentTitle] = useState<string>(existingContentTitle ?? '')
  const [contentTitleLength, setContentTitleLength] = useState<number>(0)
  const [postTitle, setPostTitle] = useState(asHtmlWithMentions(existingPostTitle))
  const [postTitleLength, setPostTitleLength] = useState<number>(0)
  const [toastMessage, setToastMessage] = useState<string | null>(null)
  const [isConverting, setIsConverting] = useState(false)
  const [hasMaxContent, setHasMaxContent] = useState(false)

  const canConvert =
    postTitleLength > 0 &&
    postTitleLength <= 100 &&
    contentTitleLength <= 100 &&
    (wasContent || (contentTitleLength > 0 && !hasMaxContent))

  const { data: postData, loading: postLoading } = useQuery(GetPostConvertDocument, {
    variables: { id: postId ?? '' },
    skip: !show || !postId,
  })
  const post = postData?.post

  useQuery(CanAddContentDocument, {
    variables: { communityId: communityId ?? '' },
    skip: !show || !communityId,
    onCompleted: data => {
      if (!data.canAddContent?.ok) {
        setHasMaxContent(true)
      }
    },
  })

  const client = useApolloClient()
  const [convertPost] = useMutation(ConvertPostDocument, {
    update(cache, { data }) {
      if (data?.convertPost?.post?.postType === PostType.Content) {
        updateCacheContent(cache, client, communityId, authUserId, data?.convertPost?.post as Post)
      } else {
        removeCacheContent(cache, client, communityId, authUserId, data?.convertPost?.post as Post)
        updateCachePosts(cache, client, authUserId, data?.convertPost?.post as Post)
      }
    },
  })
  const doConvert = async () => {
    setIsConverting(true)
    try {
      const response = await convertPost({
        variables: {
          postId: postId ?? '',
          newType: wasContent ? PostType.Post : PostType.Content,
          filter: actingRelAdmin ? {} : { draft: false },
          title: cleanupHTML(asString(postTitle)),
          contentTitle: wasContent ? '' : htmlToText(contentTitle),
        },
      })
      if (response.data?.convertPost?.ok) {
        close(true)
      } else {
        setToastMessage(
          response.data?.convertPost?.error?.message ??
            'There was an error converting this post, please try again later'
        )
        close(false)
      }
    } finally {
      setIsConverting(false)
    }
  }

  const close = (succeeded: boolean) => {
    setContentTitle(existingContentTitle ?? '')
    setPostTitle(asHtmlWithMentions(existingPostTitle))
    onConvert(succeeded, wasContent)
  }

  useEffect(() => {
    if (post?.isRepost) {
      setContentTitle(post?.repost?.contentTitle ?? '')
    }
  }, [post])

  if (postLoading) return <></>

  return (
    <>
      <Modal className={'post-convert-modal'} show={show} onHide={() => close(false)}>
        <Modal.Header closeButton>
          Convert {wasContent ? 'Content' : 'Regular'} post to {wasContent ? 'Regular' : 'Content'} post{' '}
          {post?.isRepost ? '(Repost)' : ''}
        </Modal.Header>
        <Modal.Body>
          <>
            {hasMaxContent && !wasContent && (
              <span className={'warning'}>
                <i>Cannot convert post, the maximum number of content posts in this community has been reached</i>
              </span>
            )}
            {post?.categoryId && (
              <span className={'warning'}>
                <i>This content is included in a release menu, and will be removed from it if converted</i>
              </span>
            )}
            <h3>Content Title</h3>
            {wasContent ? (
              <i>This title will be removed from the post (it currently appears on the content page)</i>
            ) : (
              <i>This title will appear on the content page</i>
            )}
            <div className={'input-container content-title'} data-testid={'content-title-input'}>
              {!wasContent && !post?.isRepost && (
                <span className={`character-counter ${calculateFieldLengthSeverity(contentTitleLength, 100)}`}>
                  {contentTitleLength < 10 ? `0${contentTitleLength}` : contentTitleLength} / {100}
                </span>
              )}
              <QuillEditor
                className={`content-title-input ${wasContent || post?.isRepost ? 'disabled' : ''}`}
                toolbar={QuillToolbar.None}
                placeholder={''}
                initialHtml={contentTitle}
                setHtml={html => setContentTitle(asString(html))}
                setTextLength={setContentTitleLength}
                allowMentions={false}
                communityId={communityId || undefined}
                readOnly={wasContent || post?.isRepost}
                preventLineBreak={true}
              />
            </div>
            <h3>Post Title</h3>
            <i>This title is shown in the feed</i>
            <div className={'input-container'}>
              {!post?.isRepost && (
                <span className={`character-counter ${calculateFieldLengthSeverity(postTitleLength, 100)}`}>
                  {postTitleLength < 10 ? `0${postTitleLength}` : postTitleLength} / {100}
                </span>
              )}
              <QuillEditor<Maybe<HtmlWithMentions>>
                className={`post-title-input ${post?.isRepost ? 'disabled' : ''}`}
                toolbar={QuillToolbar.None}
                placeholder={''}
                initialHtml={postTitle}
                setHtml={setPostTitle}
                setTextLength={setPostTitleLength}
                allowMentions={true}
                communityId={communityId || undefined}
                readOnly={post?.isRepost}
                preventLineBreak={true}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button variant="light" onClick={() => close(false)}>
              Cancel
            </Button>
            <Button variant="primary" disabled={!canConvert || isConverting} onClick={doConvert}>
              Convert Post
            </Button>
          </>
        </Modal.Footer>
      </Modal>
      <ToastComponent bg="danger" onClose={() => setToastMessage(null)} show={!!toastMessage}>
        {toastMessage ?? ''}
      </ToastComponent>
    </>
  )
}

export default PostConvertModal
