import React, { EventHandler, SyntheticEvent, useEffect, useRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import { EmployeeBadgeComponent } from '~/common/EmployeeBadgeComponent'
import Counts from './Counts'
import MentionableText from '~/common/MentionableText'
import { useNavigate } from 'react-router'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import '@css/pages/posts/PostRow.scss'
import {
  HtmlWithMentions,
  MediaType,
  PostType,
  Maybe,
  GetHeaderAuthorDocument,
  GetHeaderLastCommenterDocument,
  GetPostHeaderRepostCommunityDocument,
} from '~/api/generated/graphql'
import { contentIconSrc, getFullName, getPostPath, toTimeAgo } from '~/utils'
import { useAuth } from '~/auth/Auth'
import { PostDropdownItems } from '~/pages/posts/PostDropdownItems'
import { CommentDropdownItems } from '~/pages/posts/CommentDropdownItems'
import { Link } from 'react-router-dom'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import TimeAgo from '~/common/TimeAgo'
import { KebabToggle } from '~/common/KebabToggle'
import { UserLink } from '~/common/UserLink'
import { useShortcuts } from '~/contexts/ShortcutContext'
import { useQuery } from '@apollo/client'

type Entry = {
  createdTime: Date
  authorId: string
  postId?: string
  communityId?: string
  commentId?: string
  veevanLikeCount: number
  veevanViewCount: number
  veevanCommentCount?: number
  isRepost?: boolean
  isDraft?: boolean
  lastComment?: {
    commenterId: string
    time: Date
  }
  repost?: {
    postId: string
    newAuthorId: string
    communityId: string
  }
  postType?: PostType
}

type Props = {
  entry: Entry
  onDelete?: (id: string) => void
  onClickEdit?: () => void
  title?: Maybe<HtmlWithMentions>
  views: number
  likes: number
  last_activity: JSX.Element
  comment_count?: number
  onClick?: EventHandler<SyntheticEvent>
  expandTip?: string
  isCommPost?: boolean
  hidden: boolean
  fromContentPage: boolean
  displayAsContent?: boolean
  isComment: boolean
  contentTitle: string
  mediaType: MediaType
  isExpanded?: boolean
  isHomeFeed?: boolean
  isSinglePost?: boolean
  isRepostPage?: boolean
  isVeevanDiscussion?: boolean
  followed?: boolean
  showEditor?: boolean
  shouldExpandDraft?: boolean
  companyId?: string
}

export default ({
  entry,
  onDelete,
  onClickEdit,
  onClick,
  title,
  views,
  likes,
  comment_count,
  last_activity,
  expandTip,
  isCommPost,
  hidden,
  fromContentPage,
  displayAsContent,
  isComment,
  contentTitle,
  mediaType,
  isExpanded,
  isHomeFeed,
  isSinglePost,
  isRepostPage,
  isVeevanDiscussion,
  followed,
  showEditor,
  shouldExpandDraft,
  companyId,
}: Props) => {
  const { breakpoint, isCondensedPortrait: isPortrait } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.md
  const navigate = useNavigate()
  const { isVeevan, authUserId } = useAuth()
  const { data: authorData } = useQuery(GetHeaderAuthorDocument, { variables: { id: entry.authorId } })
  const author = authorData?.user
  const lastComment = entry.lastComment
  const { data: lastCommenterData } = useQuery(GetHeaderLastCommenterDocument, {
    variables: { id: lastComment?.commenterId ?? '' },
    skip: !lastComment?.commenterId,
  })
  const lastCommenter = lastCommenterData?.user

  const { data: repostCommunityData } = useQuery(GetPostHeaderRepostCommunityDocument, {
    variables: { id: entry.repost?.communityId ?? '' },
    skip: !entry.repost?.communityId,
  })
  const repostCommunity = repostCommunityData?.community

  const { data: repostAuthorData } = useQuery(GetHeaderAuthorDocument, {
    variables: { id: entry?.repost?.newAuthorId ?? '' },
    skip: !entry?.repost?.newAuthorId,
  })

  const { data: previewCommunityData } = useQuery(GetPostHeaderRepostCommunityDocument, {
    variables: { id: entry.communityId ?? '' },
    skip: !entry.communityId,
  })

  const previewCommunity = previewCommunityData?.community

  const displayAsContentPost = displayAsContent != undefined ? displayAsContent : fromContentPage
  const repostCls = isRepostPage ? ' repost' : ''
  const commPost = isCommPost ? ' comm-post' : ''
  const contentPost = displayAsContentPost ? ' content' : ''
  const homeFeed = isHomeFeed ? 'home-feed' : ''
  const comment = isComment ? 'comment' : ''
  const collapsed = !isExpanded ? 'collapsed' : ''
  const singlePost = isSinglePost ? 'single-post' : ''
  const mobilePortrait = isPortrait ? 'mobile' : 'medium'
  const unexpandedContent = displayAsContentPost && !isExpanded
  const expandedContent = displayAsContentPost && isExpanded
  const clamped = !isHomeFeed && !isExpanded && isCondensed ? 'clamped' : ''

  const contentLinkPath = fromContentPage || (isHomeFeed && entry.postType == PostType.Content)
  const postListPath = `/${companyId ? 'companies' : 'communities'}/${companyId ?? entry.communityId}/${
    contentLinkPath ? 'content' : 'posts'
  }`

  const repostPath = getPostPath(repostCommunity, { postId: entry.repost?.postId ?? '' }, fromContentPage)
  const previewPath = getPostPath(previewCommunity, { postId: entry.postId ?? '' }, fromContentPage)
  const expandableRef = useRef<HTMLDivElement>(null)
  const { selectedPost, expandCurrentPost } = useShortcuts()

  const getRepostMessage = () => {
    return (
      <div className={'repost-message'}>
        <div className={'repost-image'} />
        {!isRepostPage ? (
          <>
            <ProfilePhoto userId={entry?.repost?.newAuthorId} />
            <div>
              <UserLink userId={entry?.repost?.newAuthorId} />
              reposted from the
              <Link to={repostPath}>{` ${repostCommunity?.name} `}</Link>
              community <TimeAgo time={entry.createdTime} />
            </div>
          </>
        ) : (
          <>
            <ProfilePhoto userId={authUserId} />
            <div>
              <UserLink userId={authUserId} />
              reposted from the
              <Link to={previewPath}>{` ${previewCommunity?.name} `}</Link>
              community <TimeAgo time={entry.createdTime} />
            </div>
          </>
        )}
      </div>
    )
  }

  const getDraftIndicator = () => {
    return <div className={'draft-indicator'}>DRAFT</div>
  }

  const titleComponent = unexpandedContent ? (
    <div className={'title-column'} data-testid={`post-expand-${title?.htmlWithMentions}`}>
      <div className={`title-wrapper${followed ? ' bookmark' : ''}`} data-testid={followed ? 'bookmark' : ''}>
        {entry.isDraft && getDraftIndicator()}
        <div className={`${followed ? 'bookmark' : ''}`} />
        <div className={'file-title'}>{contentTitle}</div>
      </div>
      {(!!entry.isRepost || isRepostPage) && (!isPortrait || isSinglePost) && getRepostMessage()}
    </div>
  ) : (
    <div className={'title-column'} data-testid={`post-expand-${title?.htmlWithMentions}`}>
      <div className={`title-wrapper${followed ? ' bookmark' : ''}`} data-testid={followed ? ' bookmark' : ''}>
        {entry.isDraft && getDraftIndicator()}
        <div className={`${followed ? 'bookmark' : ''}`} />
        {isPortrait ? (
          <MentionableText
            expandableRef={expandableRef}
            value={title}
            postHeader={false}
            className={`${repostCls} ${clamped}`}
          />
        ) : (
          <Link
            to={`${getPostPath(
              { communityId: entry.communityId, companyId: companyId },
              { postId: entry.postId ?? '' },
              contentLinkPath
            )}`}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <MentionableText
              expandableRef={expandableRef}
              value={title}
              postHeader={false}
              className={`${repostCls} ${clamped}`}
            />
          </Link>
        )}
      </div>
      {(!!entry.isRepost || isRepostPage) && (!isPortrait || isSinglePost) && getRepostMessage()}
    </div>
  )

  const badgeComponent = unexpandedContent ? (
    <img alt="" src={contentIconSrc(mediaType)} className={'content-icon'} />
  ) : (
    <EmployeeBadgeComponent userId={entry.authorId} />
  )

  const headerRef = useClickOnEnter<HTMLDivElement>()

  useEffect(() => {
    if (!isExpanded && selectedPost?.postId === entry.postId && !selectedPost?.expanded) {
      headerRef?.current?.click()
      expandCurrentPost?.()
      headerRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    } else if (selectedPost?.postId === entry.postId && !selectedPost?.expanded) {
      expandCurrentPost?.()
      headerRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    }
  }, [selectedPost, entry.postId, expandCurrentPost, isExpanded, headerRef])

  useEffect(() => {
    // Expand draft that was just created
    if (shouldExpandDraft) {
      headerRef?.current?.click()
    }
  }, [shouldExpandDraft, headerRef])

  const handle_last_activity_click = () => {
    if (isExpanded && !author?.hidden) navigate(`/profiles/${entry.authorId}`)
    else return
  }

  const showDropdownToggle = !isRepostPage

  const dropdownItems = isComment ? (
    <CommentDropdownItems
      commentId={entry.commentId ?? ''}
      onDelete={onDelete}
      onClickEdit={onClickEdit}
      isVeevaDiscussion={isVeevanDiscussion}
    />
  ) : (
    <PostDropdownItems
      postId={entry.postId ?? ''}
      onDelete={onDelete}
      onClickEdit={onClickEdit}
      isCommPost={isCommPost}
      showEditor={showEditor}
      postListPath={postListPath}
      fromContentPage={contentLinkPath}
      isSinglePost={isSinglePost}
    />
  )

  const mobile_last_activity = (
    <>
      <div className={'mobile-last-activity'} onClick={handle_last_activity_click}>
        <div
          className={'profile-photo'}
          style={
            lastComment && !isExpanded
              ? lastCommenter?.photo && !lastCommenter?.hidden
                ? { backgroundImage: `url(${lastCommenter?.photo}` }
                : {}
              : entry.isRepost && !isExpanded
                ? repostAuthorData?.user?.photo && !repostAuthorData?.user?.hidden
                  ? { backgroundImage: `url(${repostAuthorData?.user?.photo})` }
                  : {}
                : author?.photo && !author?.hidden
                  ? { backgroundImage: `url(${author.photo}` }
                  : {}
          }
        />
        <div className={`activity-time ${isExpanded ? 'expanded' : ''}`}>
          {lastComment && lastComment.time && !isExpanded
            ? `${getFullName(lastCommenter)} (${lastCommenter?.company?.name}) commented ${toTimeAgo(lastComment.time)}`
            : entry.isRepost && !isExpanded
              ? `${repostAuthorData?.user?.firstName} ${repostAuthorData?.user?.lastName} (${
                  repostAuthorData?.user?.company?.name
                }) reposted ${toTimeAgo(entry.createdTime)}`
              : `${author?.firstName} ${author?.lastName}${
                  author?.hidden && author?.company?.name == 'Veeva' ? ' (Inactive)' : ''
                }${isExpanded ? `, ${author?.title}` : ''} (${author?.company?.name}) posted`}
        </div>
      </div>
      {!!entry.isRepost && isExpanded && getRepostMessage()}
    </>
  )

  const mobileLayout = () => (
    <div
      className={`post-header condensed ${contentPost} ${commPost} ${homeFeed} ${collapsed} ${singlePost} ${
        hidden ? 'hidden' : ''
      } ${mobilePortrait}`}
    >
      <div
        className={`post-info-area${author?.hidden && isComment ? ' inactive-comment' : ''}`}
        onClick={displayAsContentPost && isExpanded ? undefined : onClick}
        tabIndex={0}
        ref={headerRef}
      >
        {expandedContent && (
          <div className={`expanded-content-container ${singlePost}`} onClick={onClick}>
            <div className={'file-title-expanded'}>{contentTitle}</div>
            {showDropdownToggle && (
              <Dropdown align={'end'} onClick={e => e.stopPropagation()}>
                <Dropdown.Toggle as={KebabToggle} />
                {dropdownItems}
              </Dropdown>
            )}
          </div>
        )}
        <div
          ref={expandableRef}
          className={`post-info-top ${mobilePortrait ? 'mobile' : isSinglePost && 'medium-single-post'} ${
            isSinglePost ? 'single-post' : ''
          }`}
          title={expandTip}
        >
          {isPortrait && entry.postId && !isSinglePost ? (
            <div className={`post-subject`}>{titleComponent}</div>
          ) : (
            <div
              className={`post-badge-area ${contentPost} ${expandedContent ? 'expanded' : ''}${
                isComment ? ' comment' : ''
              }`}
            >
              {badgeComponent}
            </div>
          )}
          <div className={'post-metrics condensed'}>
            {singlePost &&
              (lastComment?.time ? (
                <div>{toTimeAgo(lastComment.time)}</div>
              ) : (
                <div>{toTimeAgo(entry.createdTime)}</div>
              ))}
            {!isVeevanDiscussion && (
              <Counts
                isComment={comment_count === undefined}
                likes={likes}
                views={views}
                comments={comment_count}
                veevanLikes={entry.veevanLikeCount}
                veevanViews={entry.veevanViewCount}
                veevanComments={isComment ? 0 : entry.veevanCommentCount}
                isVeevan={isVeevan}
              />
            )}
          </div>
        </div>
        {(!isPortrait || singlePost) && <div className={`post-subject`}>{titleComponent}</div>}
        {!singlePost &&
          !isComment &&
          (isPortrait && entry.postId ? mobile_last_activity : <div className={'post-meta-data'}>{last_activity}</div>)}
      </div>
      {!expandedContent && (
        <div className={`post-context-menu-area ${mobilePortrait}`}>
          {!hidden && showDropdownToggle && (
            <Dropdown align={'end'} onClick={e => e.stopPropagation()}>
              <Dropdown.Toggle as={KebabToggle} />
              {dropdownItems}
            </Dropdown>
          )}
        </div>
      )}
    </div>
  )

  const standardLayout = () => (
    <>
      <div
        className={`post-header ${contentPost} ${commPost} ${homeFeed} ${comment} ${collapsed} ${singlePost} ${
          hidden ? 'hidden' : ''
        }`}
      >
        <div
          className={`post-info-area${author?.hidden && isComment ? ' inactive-comment' : ''}`}
          onClick={fromContentPage && isExpanded ? undefined : onClick}
          tabIndex={0}
          ref={headerRef}
        >
          {expandedContent && (
            <div className={`expanded-content-container ${singlePost}`} onClick={onClick}>
              <div className={'file-title-expanded'}>{contentTitle}</div>
              <div className={'post-context-menu-area'}>
                {!hidden && !isHomeFeed && showDropdownToggle && (
                  <Dropdown align={'end'} onClick={e => e.stopPropagation()}>
                    <Dropdown.Toggle as={KebabToggle} />
                    {dropdownItems}
                  </Dropdown>
                )}
              </div>
            </div>
          )}
          <div ref={expandableRef} className={'post-info-content'} title={expandTip}>
            <div className={`post-badge-area ${contentPost} ${expandedContent ? ' expanded' : ''}`}>
              {badgeComponent}
            </div>
            {title && <div className={`post-subject title-container`}>{titleComponent}</div>}
            <div className={'post-metrics'}>
              {!isVeevanDiscussion && (
                <Counts
                  isComment={comment_count === undefined}
                  likes={likes}
                  views={views}
                  comments={comment_count}
                  veevanLikes={entry.veevanLikeCount}
                  veevanViews={entry.veevanViewCount}
                  veevanComments={isComment ? 0 : entry.veevanCommentCount}
                  isVeevan={isVeevan}
                />
              )}
            </div>
            <div className={'post-meta-data'}>{last_activity}</div>
          </div>
        </div>
        {!expandedContent && showDropdownToggle && (
          <div className={'post-context-menu-area'}>
            {!hidden && (
              <Dropdown align={'end'}>
                <Dropdown.Toggle as={KebabToggle} />
                {dropdownItems}
              </Dropdown>
            )}
          </div>
        )}
      </div>
    </>
  )

  if (isCondensed) return mobileLayout()
  else return standardLayout()
}
