import { getNormalizedTokens } from '~/utils'
import { GetSuggestionCompanyDocument, Company, GetSuggestionCompanyQuery } from '~/api/generated/graphql'
import { Suggestion } from '~/common/quill/QuillEditor'
import { ApolloCache } from '@apollo/client'

const NUM_SUGGESTIONS = 7

const CompanySuggestionService = {
  getSuggestions: (
    search: string,
    cache: ApolloCache<object>,
    onlyIncludeIds?: Set<string>,
    excludeIds?: Set<string>,
    showExcluded?: boolean
  ): Suggestion[] => {
    const prefixCache = window.prefixCache
    const names = getNormalizedTokens(search)
    const resultDict = new Map<string, number>()
    names.forEach(tok => {
      if (prefixCache?.has(tok)) {
        prefixCache?.get(tok)?.forEach((match: string) => {
          resultDict.set(match, (resultDict.get(match) || 0) + 1)
        })
      }
    })
    const filteredResults = Array.from(resultDict.keys())
      .filter(k => resultDict.get(k) === names.length)
      .filter(k => k.startsWith('Company'))
    const result: GetSuggestionCompanyQuery['company'][] = []
    filteredResults.map(k => {
      const companyId = k.split(':')[1]
      result.push(
        cache.readQuery<GetSuggestionCompanyQuery>({
          query: GetSuggestionCompanyDocument,
          variables: { id: companyId },
        })?.company
      )
    })

    let matchedCompanies: Suggestion[] = result
      ?.filter(c => c)
      .map((c: Company) => {
        return {
          id: c.companyId ?? '',
          community: {
            companyId: c.companyId ?? '',
            name: c.name,
          },
          value: c.name,
          link: `/${c.companyId ? 'companies' : 'communities'}}/${c.companyId}`,
          photo: c.homepage?.photo ?? null,
        }
      })

    if (onlyIncludeIds) {
      matchedCompanies = matchedCompanies.filter(c => onlyIncludeIds.has(c?.community?.companyId || ''))
    }

    if (excludeIds) {
      const matchedCompaniesWithoutExcluded = matchedCompanies.filter(
        c => !excludeIds.has(c?.community?.companyId || '')
      )
      if (matchedCompaniesWithoutExcluded.length >= NUM_SUGGESTIONS || !showExcluded) {
        matchedCompanies = matchedCompaniesWithoutExcluded
      }
    }

    return matchedCompanies.slice(0, NUM_SUGGESTIONS)
  },
}

export default CompanySuggestionService
