import React, { useRef, useState } from 'react'
import '@css/pages/profile/ProfileHeader.scss'
import { Button, Modal, Spinner } from 'react-bootstrap'
import ReactCrop, { centerCrop, convertToPixelCrop, Crop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const confirmUploadPhoto = (
  canvas: React.RefObject<HTMLCanvasElement>,
  imgRef: React.RefObject<HTMLImageElement>,
  crop: Crop | undefined,
  handleBlobUpload: (blob: Blob, type: string, name: string) => void,
  onClose: () => void,
  file?: File
) => {
  if (!canvas.current) return
  const context = canvas.current.getContext('2d')
  const image = imgRef.current
  if (!image || !crop || !context) {
    return
  }
  const pixelCrop = convertToPixelCrop(crop, image.width, image.height)
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const height = pixelCrop.height * scaleY
  const width = pixelCrop.width * scaleX
  canvas.current.height = height
  canvas.current.width = width
  const cropX = pixelCrop.x * scaleX
  const cropY = pixelCrop.y * scaleY
  context.drawImage(image, cropX, cropY, width, height, 0, 0, width, height)
  if (file) {
    canvas?.current?.toBlob(b => {
      if (b) {
        handleBlobUpload(b, file.type, file.name)
      }
    }, file.type)
    onClose()
  }
}

export const ModalCropper = ({
  file,
  handleBlobUpload,
  onClose,
  isCommunity,
  loading,
}: {
  file?: File
  handleBlobUpload: (blob: Blob, type: string, name: string) => void
  onClose: () => void
  isCommunity: boolean
  loading?: boolean
}) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const [cropImgSrc, setCropCropImgSrc] = useState('')
  const [crop, setCrop] = useState<Crop>()
  const canvas = useRef<HTMLCanvasElement>(null)
  const hasDrawnCroppingCanvas = useRef<boolean>(false)
  const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const reader = new FileReader()
  reader.addEventListener('load', () => {
    if (!hasDrawnCroppingCanvas.current) setCropCropImgSrc(reader?.result?.toString() || '')
  })
  if (file) {
    reader.readAsDataURL(file)
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
    // draw image in square canvas with whitespace around it to make cropping easier for non-square images
    if (cropImgSrc && canvas.current && !hasDrawnCroppingCanvas.current && isCommunity) {
      const ctx = canvas.current.getContext('2d')
      const image = new Image()
      image.src = cropImgSrc
      const size = Math.max(height, width) * 1.1
      canvas.current.height = size
      canvas.current.width = size
      if (ctx) {
        ctx.fillStyle = '#fff'
        ctx.fillRect(0, 0, size, size)
        ctx.drawImage(image, size / 2 - width / 2, size / 2 - height / 2, width, height)
        canvas.current.toBlob(blob => {
          if (blob) {
            const url = URL.createObjectURL(blob)
            setCropCropImgSrc(url)
            hasDrawnCroppingCanvas.current = true
          }
        })
      }
    }
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" className={'cropPhoto'}>
      <Modal.Header>Crop Photo</Modal.Header>
      {loading ? (
        <div className={'cropper-spinner'}>
          <Spinner animation={'border'} role={'status'} style={{ position: 'relative', display: 'inline-block' }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Modal.Body>
          <div>
            <canvas ref={canvas} style={{ display: 'none' }} />
            <ReactCrop crop={crop} circularCrop={true} onChange={pixelCrop => setCrop(pixelCrop)} aspect={1}>
              <img ref={imgRef} alt="Crop me" src={cropImgSrc} onLoad={onImageLoad} style={{ maxWidth: '100%' }} />
            </ReactCrop>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button variant="light" size="sm" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() => confirmUploadPhoto(canvas, imgRef, crop, handleBlobUpload, onClose, file)}
          disabled={loading}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
