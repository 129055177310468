import React, { useEffect, useState } from 'react'
import { MediaComponent } from '~/pages/posts/MediaComponent'
import { Button, ProgressBar, Spinner } from 'react-bootstrap'
import { FileUploader } from 'react-drag-drop-files'
import { useMediaUpload } from '~/contexts/MediaUploadContext'
import { MediaType, CreateMediaDocument } from '~/api/generated/graphql'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { elementClicked } from '~/common/EventLogger'
import { ImageUploadService } from '~/common/quill/ImageUploadService'
import VideoURLUpload from '~/pages/posts/VideoURLUpload'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { useAuth } from '~/auth/Auth'
import ToastComponent from '~/common/ToastComponent'
import { useMutation } from '@apollo/client'

const docTypes = ['DOCX', 'CSV', 'TXT']
const imgTypes = ['BMP', 'GIF', 'JPEG', 'JPG', 'PNG', 'TIF', 'TIFF', 'AI', 'EPS', 'SVG', 'WEBP', 'JFIF']
const pdfTypes = ['PDF']
const presentationTypes = ['PPT', 'PPTX', 'POTX']
const spreadsheetTypes = ['XLS', 'XLSM', 'XLSX', 'XLT', 'XLTM', 'NUMBERS']
const videoTypes = ['AVI', 'M4V', 'MOV', 'MP4', 'MPEG', 'MPG', 'QT', 'WMV']
const vpkTypes = ['VPK']
const zipTypes = ['ZIP']

export const fileTypes = [
  ...docTypes,
  ...imgTypes,
  ...pdfTypes,
  ...presentationTypes,
  ...spreadsheetTypes,
  ...videoTypes,
  ...vpkTypes,
  ...zipTypes,
]

type AddMediaTargetProps = {
  post_id?: string
  comment_id?: string
  directURLUpload?: boolean
  uploadURL?: string
  uploadMediaType?: MediaType
  clearUpload?: () => void
  setMadeChanges?: () => void
}

export function AddMediaTarget({
  post_id,
  comment_id,
  directURLUpload,
  uploadURL,
  uploadMediaType,
  clearUpload,
  setMadeChanges,
}: AddMediaTargetProps) {
  const { mediaUrl, mediaType, removeMedia, timeleft, progress, uploading, mediaName } = useMediaUpload()
  const uploadRef = useClickOnEnter<HTMLDivElement>()

  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.md

  const progressWidget = <>{timeleft > 0 && <ProgressBar now={progress} label={`${Math.round(progress)}%`} />}</>
  return (mediaUrl === '' || uploading) && !directURLUpload ? (
    <div className={`upload-area${uploading ? ' uploading' : ''}`} tabIndex={0} ref={uploadRef}>
      <div className={'upload-drop-area'}>
        {!uploading && <h5>+ Add Photo / Video / File</h5>}
        {!uploading && !isCondensed && <p>or drag and drop here</p>}
        {uploading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
      {progressWidget}
    </div>
  ) : (
    <div className={'upload-area'} tabIndex={0} ref={uploadRef}>
      <div className={'media-holder'}>
        <div className={'media-item'}>
          <MediaComponent
            url={directURLUpload ? uploadURL : mediaUrl}
            media_type={directURLUpload ? uploadMediaType : mediaType}
            post_id={post_id}
            comment_id={comment_id}
            uploading={true}
          />
          <Button
            tabIndex={0}
            className="media-remove"
            onClickCapture={e => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              e.preventDefault()
              e.nativeEvent.preventDefault()
              removeMedia()
              clearUpload?.()
              setMadeChanges?.()
            }}
          ></Button>
          {mediaType != MediaType.Video && mediaType != MediaType.Image && (
            <p>
              <a download={mediaName} href={directURLUpload ? uploadURL : mediaUrl}>
                {mediaName}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export const UploadZone = ({
  post_id,
  comment_id,
  directUrlUpload,
  setDirectUrlUpload,
  setMadeChanges,
}: {
  post_id?: string
  comment_id?: string
  directUrlUpload: string | null
  setDirectUrlUpload: (upload: string | null) => void
  setMadeChanges?: () => void
}) => {
  const { uploadMedia, mediaUrl, formFile } = useMediaUpload()
  const { isVeevan } = useAuth()

  return (
    <div
      className={'upload-zone'}
      onClick={e => elementClicked(e, 'click-post-file-upload-attempt', { postId: post_id })}
    >
      {mediaUrl ? (
        <AddMediaTarget post_id={post_id} comment_id={comment_id} setMadeChanges={setMadeChanges}></AddMediaTarget>
      ) : (
        <>
          <FileUploader
            handleChange={(file: File) => {
              uploadMedia(file)
              setMadeChanges?.()
            }}
            name="file"
            types={fileTypes}
            // https://www.npmjs.com/package/react-drag-drop-files
            // uploading the same file twice doesn't trigger handlechange unless stored file is nulled out.
            fileOrFiles={formFile}
          >
            <AddMediaTarget post_id={post_id} comment_id={comment_id} setMadeChanges={setMadeChanges} />
          </FileUploader>
          {isVeevan && (
            <VideoURLUpload
              className={'link-insert'}
              initialURL={directUrlUpload}
              setVideoURL={upload => {
                setDirectUrlUpload(upload)
                setMadeChanges?.()
              }}
              isMainTarget={true}
            />
          )}
        </>
      )}
    </div>
  )
}

type AddMediaTargetInlineProps = {
  uploading: boolean
  progress: number
  mediaUrl: string
  directURLUpload?: string | null
  post_id?: string
  comment_id?: string
  mediaType: MediaType
  onDelete: () => void
  showDelete: boolean
  mediaName?: string
}

const AddMediaTargetInline = ({
  uploading,
  progress,
  mediaUrl,
  directURLUpload,
  post_id,
  comment_id,
  mediaType,
  onDelete,
  showDelete,
  mediaName,
}: AddMediaTargetInlineProps) => {
  const progressWidget = <>{uploading && <ProgressBar now={progress} label={`${Math.round(progress)}%`} />}</>
  const uploadRef = useClickOnEnter<HTMLDivElement>()
  const [forceShowDelete, setForceShowDelete] = useState<boolean>(false)

  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.md

  return (mediaUrl === '' || uploading) && !directURLUpload ? (
    <div className={`upload-area${uploading ? ' uploading' : ''}`} tabIndex={0} ref={uploadRef}>
      <div className={'upload-drop-area'}>
        {!uploading && <h5>+ Add Photo / Video / File</h5>}
        {!uploading && !isCondensed && <p> or drag and drop here</p>}
        {uploading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
      {progressWidget}
    </div>
  ) : (
    <div className={'upload-area'} tabIndex={0} ref={uploadRef} onClick={() => setForceShowDelete(show => !show)}>
      <div className={'media-holder'}>
        <div className={'media-item'}>
          <MediaComponent
            url={directURLUpload ? directURLUpload : mediaUrl}
            media_type={directURLUpload ? MediaType.Video : mediaType}
            post_id={post_id}
            comment_id={comment_id}
            uploading={true}
          />
          {(showDelete || forceShowDelete) && (
            <Button tabIndex={0} className="media-remove" onClick={() => onDelete()}></Button>
          )}
          {mediaType != MediaType.Video && mediaType != MediaType.Image && (
            <p>
              <a download={mediaName} href={directURLUpload ? directURLUpload : mediaUrl}>
                {mediaName}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export const UploadZoneInline = ({
  post_id,
  comment_id,
  setUploadId,
  setVideoUrl,
  setMediaType,
  setMediaName,
  directUrlUpload,
  setDirectUrlUpload,
  onClear,
  onDelete,
  showDelete,
  mediaUrl,
  mediaType,
  mediaName,
  file,
}: {
  post_id?: string
  comment_id?: string
  setUploadId: (type?: MediaType, uploadId?: string, filename?: string, mediaUrl?: string) => void
  setVideoUrl: (videoUrl?: string, type?: MediaType) => void
  setMediaType: (type?: MediaType) => void
  setMediaName: (mediaName?: string) => void
  directUrlUpload: string | null
  setDirectUrlUpload: (vid: string | null) => void
  onClear: () => void
  onDelete: () => void
  showDelete: boolean
  mediaUrl?: string
  mediaType?: MediaType
  mediaName?: string
  file?: File
}) => {
  const [createMedia] = useMutation(CreateMediaDocument)
  const [progress, setProgress] = useState<number>(0)
  const [uploading, setUploading] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>()
  const [showToast, setShowToast] = useState(false)
  const [currentFile, setCurrentFile] = useState(file)

  useEffect(() => {
    if (file) {
      setCurrentFile(file)
      ImageUploadService.upload(file, setProgress, setUploading, createMedia).then(resp => {
        if (resp.errorMessage) {
          setShowToast(true)
          setToastMessage(resp.errorMessage)
          setUploading(false)
          setProgress(0)
          setCurrentFile(undefined)
          return
        }
        setMediaType(resp.type)
        setMediaName(file.name)
        if (resp.videoUrl) {
          setVideoUrl(resp.videoUrl, MediaType.Video)
        } else {
          setUploadId(resp.type, resp.uploadId, resp.filename, resp.url)
        }
      })
    }
    // we do not want to pass all the deps here, because setVideoUrl and setUploadId depend on the editor data
    // and that would cause this to try to upload any time that data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <>
      <div
        className={'upload-zone'}
        onClick={e => elementClicked(e, 'click-post-file-upload-attempt', { postId: post_id })}
      >
        {mediaUrl ? (
          <AddMediaTargetInline
            progress={progress}
            mediaType={mediaType ?? MediaType.Other}
            uploading={uploading}
            mediaUrl={mediaUrl}
            post_id={post_id}
            comment_id={comment_id}
            directURLUpload={directUrlUpload}
            onDelete={() => {
              onClear()
              setUploadId(undefined, undefined, undefined, undefined)
              setDirectUrlUpload(null)
            }}
            showDelete={showDelete}
            mediaName={mediaName}
          />
        ) : (
          <>
            <FileUploader
              handleChange={(file: File) => {
                setCurrentFile(file)
                ImageUploadService.upload(file, setProgress, setUploading, createMedia).then(resp => {
                  if (resp.errorMessage) {
                    setShowToast(true)
                    setToastMessage(resp.errorMessage)
                    setUploading(false)
                    setProgress(0)
                    setCurrentFile(undefined)
                    return
                  }
                  setMediaType(resp.type)
                  setMediaName(file.name)
                  if (resp.videoUrl) {
                    setVideoUrl(resp.videoUrl, MediaType.Video)
                  } else {
                    setUploadId(resp.type, resp.uploadId, resp.filename, resp.url)
                  }
                })
              }}
              // https://www.npmjs.com/package/react-drag-drop-files
              // uploading the same file twice doesn't trigger handlechange unless stored file is nulled out.
              fileOrFiles={currentFile}
              name="file"
              types={fileTypes}
            >
              <AddMediaTargetInline
                progress={progress}
                mediaUrl={mediaUrl ?? ''}
                mediaType={mediaType ?? MediaType.Other}
                uploading={uploading}
                directURLUpload={directUrlUpload}
                post_id={post_id}
                comment_id={comment_id}
                onDelete={onClear}
                showDelete={showDelete}
                mediaName={mediaName}
              />
            </FileUploader>
            <VideoURLUpload
              className={'link-insert'}
              initialURL={directUrlUpload}
              setVideoURL={upload => {
                setDirectUrlUpload(upload)
                setVideoUrl(upload ?? undefined)
                setMediaType(MediaType.Video)
              }}
              onDelete={onDelete}
            />
            <ToastComponent bg={'danger'} show={showToast ?? false} onClose={() => setShowToast?.(false)}>
              {toastMessage ? toastMessage : ''}
            </ToastComponent>
          </>
        )}
      </div>
    </>
  )
}
